import React from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
// import GenerativeAIDevelopment from '../../assets/images/GenerativeAIDevelopment.png';
import landinggenerativeaiimages from '../../assets/images/landinggenerativeaiimages.png';
import GenerativeAICustomDevelopment from '../../assets/images/GenerativeAICustomDevelopment.png';
import GenerativeAIConsulting from '../../assets/images/GenerativeAIConsulting.png';
// import AIAuditing from '../../assets/images/AIAuditing.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
import GenerativeAI from '../../assets/images/GenerativeAI.jpg'
const GenerativeAi = () => {


    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div
                className="w-full h-[860px] mx-auto mb-[60px] p-6 bg-cover bg-center flex items-center justify-center"
                style={{ backgroundImage: `url(${GenerativeAI})` }}
            >
                <div className="grid lg:grid-cols-2 grid-cols-1 relative items-center justify-center gap-8 mt-20 rounded-2xl p-[50px]">
                    <div className="text-center lg:text-left space-y-6 text-white">
                        <h1 className="text-3xl lg:text-5xl font-bold">
                            <span className="text-[#0652dd]">Generative AI</span> Development Company
                        </h1>
                        <p className="text-base lg:text-lg leading-relaxed">
                            Increase day-to-day efficiency in your organization and generate
                            data-driven growth by partnering with our top Generative AI
                            developers.
                        </p>
                        <ul className="mt-4 space-y-2 text-base md:text-lg">
                            <li>✅ AI/ML Consulting</li>
                            <li>✅ AI/ML Engineering</li>
                            <li>✅ Generative AI</li>
                            <li>✅ MLOps</li>
                        </ul>
                        <button className="transition-all duration-300 rounded-lg bg-[#0652dd] text-white font-semibold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[220px] mt-10 h-[50px] shadow-lg">
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                </div>
            </div>
            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>


            <div className="w-full max-w-7xl h-auto bg-white mx-auto mt-[30px] p-5">
                <div className="grid xl:grid-cols-2 sm:grid-cols-1 justify-center items-center gap-8 md:gap-[10rem] pt-[35px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[24px] md:text-[30px] font-bold">
                            Experience the Impact of  <span className="text-[#0652dd]">Generative AI </span>
                            for Rapid Transformation
                        </h1>
                        <p className="text-[#242424] text-[16px] md:text-[18px] pt-[15px] leading-[26px] md:leading-[29px]">
                            BitFront is a leading <b>Generative AI Development Company in India</b>,
                            specializing in cutting-edge services designed for a range of innovative
                            applications. We simplify access to this technology, offering tailored
                            solutions aligned with your specific goals. Whether you aim to <b>streamline
                                operations, foster creativity</b>, or <b>enhance customer experiences</b>, our
                            experienced team uses advanced AI algorithms to help achieve your vision
                            for the future. We streamline operations with <b>smart AI solutions</b>, like
                            <b>ChatGPT</b>, Midjourney, DALL-E, and Stable Diffusion. Our experts integrate
                            these models into your business seamlessly. Our software development
                            expertise facilitates efficiency through Code Automation, ensuring th
                            bug detection.
                        </p>
                        <p className="text-[#242424] text-[16px] md:text-[18px] pt-[15px] leading-[26px] md:leading-[29px]">
                            BitFront offers a wide range of generative AI development services from
                            development to post-deployment for various use cases like video, audio,
                            image generation, etc. The OpenAI Developers are dedicated to pushing the
                            boundaries of AI, delivering industry-specific solutions that fully unlock the
                            potential of Generative AI for your projects.
                        </p>
                    </div>
                    <img alt='landinggenerativeaiimages' className="w-full md:w-[480px] h-auto md:h-[450px] mt-8 md:mt-0" src={landinggenerativeaiimages} />
                </div>
            </div>


            <div className="w-full bg-white mt-10">
                <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-16 ">
                    <div className="text-center lg:text-left max-w-2xl">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Our
                            <span className="text-[#0652dd]"> Generative AI</span> Development Services</h1>
                        <p className="text-base md:text-lg mt-4">
                            Our Generative AI development services prioritize user-driven innovation to
                            surpass project expectations and drive growth. We use extensive expertise
                            across various AI technologies such as deep learning, machine learning,
                            computer vision, reinforcement learning, and natural language processing
                            to create tailored generative AI models and solutions for specific domains.
                        </p>
                        <p className="text-base md:text-lg mt-4">
                            Utilizing extensive knowledge across various AI technologies such as deep
                            learning, machine learning, computer vision, reinforcement learning, and
                            natural language processing, we specialize in creating customized
                            generative AI models and solutions specific to your domain.
                        </p>
                        <button className=" w-full mt-[20px] md:w-[200px] h-[48px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                            <b>GET ESTIMATE</b>
                        </button>
                    </div>
                    <div className="flex flex-col gap-6 mt-10 lg:mt-0">
                        {/* Card 1 */}
                        <div className="w-full max-w-lg bg-white rounded-[30px] p-6 border border-gray-200 shadow-lg hover:shadow-xl transition-all duration-300">
                            <div className="flex items-center gap-4">
                                <img alt='GenerativeAI' className="w-[60px] h-[60px] rounded-full" src={GenerativeAICustomDevelopment} />
                                <h4 className="text-xl font-semibold text-gray-800">Generative AI Custom Development</h4>
                            </div>
                            <p className="text-base text-gray-600 mt-4">
                                By using models like GPT and DALL.E, our engineers design custom language models and implement ML algorithms to
                                create outcome-centric solutions for your business.
                            </p>
                        </div>

                        {/* Card 2 */}
                        <div className="w-full max-w-lg bg-white rounded-[30px] p-6 border border-gray-200 shadow-lg hover:shadow-xl transition-all duration-300">
                            <div className="flex items-center gap-4">
                                <img alt='GenerativeAI' className="w-[60px] h-[60px] rounded-full" src={GenerativeAIConsulting} />
                                <h4 className="text-xl font-semibold text-gray-800">Generative AI Consulting</h4>
                            </div>
                            <p className="text-base text-gray-600 mt-4">
                                Our Generative AI Consultants help identify business opportunities, strategize AI integration, custom model
                                development, help set KPIs, calculate ROI, and create a risk-proof development plan for Generative AI Projects.
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Generative AI</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                Our skilled Generative AI developers work closely with your goals to create a user-friendly solution.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>


            <h1 className="xl:text-[48px] lg:text-[30px] md:text-[20px] text-[16px] mb-5 text-center mt-15 font-bold mt-9">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
            <p className="text-lg text-center">
                Our GenAI development services include development, customization, and integration solutions for several leading sectors like:
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>

            <div className="max-w-7xl w-full h-auto bg-white pt-[40px] mx-auto mt-[80px] p-5">
                <h1 className="text-[#0652dd] text-[28px] md:text-[40px] font-bold text-center">Why Choose Us?</h1>
                <p className="text-[18px] md:text-[21px] text-[#292929] text-center mt-4">
                    Our certified and experienced specialists build revenue-driven and customer-centric e-commerce solutions.
                </p>
                {/* <ul className="list-none text-[16px] md:text-[18px] mt-6 space-y-4"> */}
                <li className="text-[18px] pt-[10px]">Affordable Solutions for Improved Budgeting, Risk Control, and Project Planning.</li>
                <li className="text-[18px] pt-[10px]">70+ Experienced e-commerce specialists for Building a Successful Product.</li>
                <li className="text-[18px] pt-[10px]">E-commerce Development Experience That Ensures Quality-centric Deliveries.</li>
                <li className="text-[18px] pt-[10px]">Custom Shopify Development Services to Meet Specific Needs.</li>
                <li className="text-[18px] pt-[10px]">Personalized Shopify Development to Improve Operational Efficiency and Customer Experience.</li>
                <li className="text-[18px] pt-[10px]">Future-proof Technology Utilized for Shopify App Development.</li>
                <li className="text-[18px] pt-[10px]">Agile Development Team to Meet Project Deadlines Faster.</li>
                {/* </ul> */}
                <div className="flex justify-center">
                    <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                        TALK TO OUR EXPERT
                    </button>
                </div>
            </div>


            <div className="bg-blue-700 w-3/4 h-auto mx-auto text-white text-center p-5 rounded-lg mt-10">
                <h3 className="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p className="text-lg pt-4">
                    For a simple Generative AI app with minimal features, it could cost between $40,000 to $100,000. But for a more advanced
                    app with lots of features and high-quality design, it might range from $100,000 to $400,000 or even more.</p>
                <div>
                    <button onClick={handleClick} className="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>
            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GenerativeAi
