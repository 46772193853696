import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import OTTPlatform from '../../assets/images/OTTPlatform.png';
import LeadingOTTAppDevelopmentCompanyIndia from '../../assets/images/LeadingOTTAppDevelopmentCompanyIndia.png';
import CustomOTTAppDevelopment from '../../assets/images/CustomOTTAppDevelopment.png';
import OTTUIUXDesign from '../../assets/images/OTTUIUXDesign.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const OTTAppDevelopment = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };

    const [faqs, setFaqs] = useState([
        {
            question: "Custom-Based Solutions",
            answer:
                "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
            open: false
        },
        {
            question: "Industrial Experts",
            answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
            open: false
        },
        {
            question: "Seamless Communication",
            answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
            open: false
        },
        {
            question: "Latest Technology",
            answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
            open: false
        },

    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    const benefits = [
        "Global quality standards.",
        "Monthly/yearly hiring.",
        "Flexible office hours depend on the timezone.",
        "Daily/weekly/monthly reporting via email.",
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">OTT Platform</span><br />Development</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            BitFront specializes in creating and advancing video and audio streaming
                            applications featuring an integrated in-app payment system. If you're a
                            content creator looking to make your content through your OTT App platform,
                            don't hesitate to get in touch with us.
                        </p>
                        <ul className="mt-4 space-y-2 text-base md:text-lg text-gray-700">
                            <li>✅ Data Transparency</li>
                            <li>✅ Cost friendly</li>
                            <li>✅ OTT live streaming</li>
                            <li>✅ Device flexibility</li>
                            <li>✅ High-quality video and audio</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                    <img alt='OTT Platform' className="w-full md:w-[500px] h-auto md:h-[484px] mt-8 md:mt-0 mx-auto " src={OTTPlatform} />
                </div>
            </div>


            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>

            <div className="w-full max-w-7xl h-auto bg-white mx-auto p-5">
                <div className="flex flex-col-reverse md:flex-row items-center justify-center gap-8 md:gap-[5rem] pt-[35px]">
                    {/* Text Content */}
                    <div className="text-center md:text-left flex-1">
                        <h1 className="text-[22px] sm:text-[26px] md:text-[30px] font-bold leading-tight">
                            Leading <span className="text-[#0652dd]">OTT App</span> Development Company in India
                        </h1>
                        <p className="text-[#242424] text-[14px] sm:text-[16px] md:text-[18px] pt-4 leading-[24px] md:leading-[29px]">
                            Have you been running your business for a while but haven't gone online yet? Want to take your business
                            to the next level of success? Need to create a website from scratch or update your current one? No matter
                            what industry you're in, our skilled team at BitFront can build a website that goes beyond your expectations
                            in terms of performance and usability. We offer a single destination for various web development services,
                            delivering fully personalized and adaptable websites - providing world-class solutions for our valued clients.
                        </p>
                        <p className="text-[#242424] text-[14px] sm:text-[16px] md:text-[18px] pt-4 leading-[24px] md:leading-[29px]">
                            We're a top <b><u>web design and development company in India</u></b>. We can help define your brand and increase
                            demand for your products or services by focusing on your customers and using data. We know how important
                            it is to have a great website nowadays, so we include things like <b>SEO, PPC,</b> and <b>content marketing</b>
                            to get outstanding results. As your website design and development company, let us guide you to the right
                            website platform selection.
                        </p>
                    </div>

                    {/* Image Section */}
                    <div className="flex-1 flex justify-center">
                        <img
                            alt="Leading OTT App"
                            className="w-full max-w-[400px] sm:max-w-[500px] md:max-w-[566px] h-auto"
                            src={LeadingOTTAppDevelopmentCompanyIndia}
                        />
                    </div>
                </div>
            </div>

            <div className="w-full bg-white mt-10">
                <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-16 ">
                    <div className="text-center lg:text-left max-w-2xl">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">
                            <span className="text-[#0652dd]">OTT App</span> Development Services</h1>
                        <p className="text-base md:text-lg mt-4">
                            Step into the future of tailored video software solutions with our next-gen OTT development services.
                            We provide future ready OTT platform development by using our expertise and experience with live streaming solutions.
                        </p>
                        <p className="text-base md:text-lg mt-4">
                            We create a next-gen OTT platform for brands to help them provide improved
                            streaming experience to your targeted audience globally. Our developers have
                            expertise in OTT platform development like Smart TV apps, mobile apps, and
                            websites, live streaming apps, and more.
                        </p>
                        <button className=" w-full mt-[20px] md:w-[250px] h-[48px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                            <b>TALK TO OUR DEVELOPERS</b>
                        </button>
                    </div>
                    <div className="flex flex-col gap-6 mt-10 lg:mt-0">
                        {[
                            {
                                title: "Custom OTT App Development",
                                description:
                                    "As a leading OTT software development service company, we design and develop robust custom video streaming platforms that give your business complete control over the user account, interface, and content.",
                                imgSrc: CustomOTTAppDevelopment,
                                imgAlt: "CustomOTTAppDevelopment",
                            },
                            {
                                title: "OTT UI & UX Design",
                                description:
                                    "Our OTT platform's UX/UI design prioritizes great usability, user experience, and visually captivating elements to engage users effectively. Utilize our frontend expertise for building high-end professional-looking user interfaces for various devices and screens.",
                                imgSrc: OTTUIUXDesign,
                                imgAlt: "OTTUIUXDesign",
                            },
                        ].map((service, index) => (
                            <div
                                key={index}
                                className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border shadow-md"
                            >
                                <div className="flex items-center gap-3">
                                    <img
                                        alt={service.imgAlt}
                                        className="w-9 h-9 object-contain"
                                        src={service.imgSrc}
                                    />
                                    <h4 className="text-lg md:text-xl font-bold">{service.title}</h4>
                                </div>
                                <p className="text-base mt-3 pl-2 text-gray-700">{service.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <h1 className="text-[22px] sm:text-[26px] md:text-[30px] lg:text-[36px] xl:text-[40px] text-center font-bold mt-9 mb-5">
                Our <span className="text-[#0652dd]">Multi-industry</span> Experience
            </h1>

            {/* Description */}
            <p className="text-sm sm:text-base md:text-lg text-center leading-relaxed max-w-3xl mx-auto">
                Every type of industry, whether old-fashioned or modern, has the chance to become a digital leader with a strong website.
                At BitFront, we assist businesses in reaching their full potential by creating a strong online presence for them.
            </p>

            {/* Categories Grid */}
            <div className="flex flex-wrap justify-center items-center gap-7 mt-6">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-lg transition-all duration-200 w-full sm:w-[280px] md:w-[320px] h-[250px] bg-white rounded-2xl border border-gray-200 flex flex-col items-center justify-center p-5"
                    >
                        <img alt={category.alt} src={category.src} className="w-14 h-14 mb-4" />
                        <h4 className="text-center text-lg font-semibold">{category.title}</h4>
                    </div>
                ))}
            </div>
            <div className="bg-blue-700 w-[90%] sm:w-3/4 h-auto mx-auto text-white text-center p-6 rounded-lg mt-10">
                {/* Title */}
                <h3 className="text-2xl sm:text-3xl font-bold">
                    How much does it cost to develop a web application?
                </h3>

                {/* Description */}
                <p className="text-base sm:text-lg pt-4 leading-relaxed">
                    OTT platform development costs (like Netflix or Disney Plus) vary depending on features, location, devices, and technology.
                    Expect a range of $30,000 to $150,000.
                </p>

                {/* Contact Button */}
                <div className="mt-5">
                    <button
                        onClick={handleClick}
                        className="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-full font-semibold transition duration-300 hover:bg-gray-100"
                    >
                        Contact Us
                    </button>
                </div>
            </div>

            <section className="mt-12 px-4">
                {/* Heading */}
                <h1 className="text-center text-3xl sm:text-4xl font-bold">
                    <span className="text-[#0652dd]">Why</span> Choose BitFront?
                </h1>

                <div className="flex flex-wrap justify-center gap-10 mt-11">
                    {/* Left Content */}
                    <div className="max-w-lg">
                        <h2 className="text-2xl sm:text-3xl font-bold">
                            <span className="text-[#0652dd]">Hire the Team</span> That Understands How
                            <span className="text-[#0652dd]"> Digital Success Works</span>
                        </h2>

                        <p className="text-lg mt-4 leading-relaxed">
                            With a proven track record in React Native app development, <br />
                            we're the perfect fit for your project. Here's why:
                        </p>

                        {/* Benefit List */}
                        <ul className="mt-5 space-y-4">
                            {benefits.map((benefit, index) => (
                                <li key={index} className="flex items-center gap-3 text-lg">
                                    <span className="bg-blue-300 rounded-full w-9 h-8 flex items-center justify-center text-blue-600 p-2">
                                        ✔
                                    </span>
                                    {benefit}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Right Content (FAQs) */}
                    <div className="max-w-lg w-full">
                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OTTAppDevelopment
