// import Header from "../layout/Header"
import Header from './Header'
import BIAI from '../../assets/images/BIAI.png';
import { useState } from "react";
import AI from "../servicestab/AI";
import { FaAngleRight } from "react-icons/fa";
import ML from "../servicestab/ML";
import CV from "../servicestab/CV";
import NLPD from "../servicestab/NLPD";
import LLMD from "../servicestab/LLMD";
import ADI from "../servicestab/ADi";
import OC from "../servicestab/OC";
import GAI from "../servicestab/GAI";
import AIS from "../servicestab/AIS";
import DL from "../servicestab/DL";
import healths from '../../assets/images/healths.svg';
import fintechs from '../../assets/images/fintechs.svg';
import autotechs from '../../assets/images/autotechs.svg';
import edtechs from '../../assets/images/edtechs.svg';
import traveltechs from '../../assets/images/traveltechs.svg';
import esporticon from '../../assets/images/esporticon.svg';
import foodicon from '../../assets/images/foodicon.svg';
import logisticicon from '../../assets/images/logisticicon.svg';
import entertainmentsicon from '../../assets/images/entertainmentsicon.svg';
import realestatesicon from '../../assets/images/realestatesicon.svg';
import artificialprocessimage from '../../assets/images/artificialprocessimage.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { GoCheck } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Footerq from "../layout/Footerq";
import FAQsw from '../layout/FAQsw';
import './BitfrontAi.css';
import koovsicon from '../../assets/images/koovsicon.svg';
import koovsweb from '../../assets/images/koovsweb.png';
import revazaweb from '../../assets/images/revazaweb.png';
import revezaicon from '../../assets/images/revezaicon.svg';
import easecareicon from '../../assets/images/easecareicon.svg';
import easecareweb from '../../assets/images/easecareweb.png';
import rightangleweb from '../../assets/images/rightangleweb.png';
import rightangleicon from '../../assets/images/rightangleicon.svg';
import artificialtechnologynewimg from '../../assets/images/artificialtechnologynewimg.png';
import artificialpartnermin from '../../assets/images/artificialpartnermin.png';
import Modal from './Modal';
const BitfrontAI = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { label: 'Artificial Intelligence ', },
    { label: ' Machine Learning ', },
    { label: 'Computer Vision ', },
    { label: 'NLP Development ', },
    { label: 'LLM Development ', },
    { label: 'Adaptive AI ', },
    { label: 'AI Chatbot ', },
    { label: 'Generative AI ', },
    { label: 'Deep Learning', },
    { label: 'AI as a Service', },

  ];
  const [isOpen, setIsOpen] = useState(true);
  const [isOpens, setIsOpens] = useState(false);
  const [isOpent, setIsOpent] = useState(false);
  const [isOpenf, setIsOpensf] = useState(false);
  const [isOpenfi, setIsOpensfi] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
    setIsOpens(false);
    setIsOpensfi(false);
    setIsOpent(false);
    setIsOpensf(false);
  };

  const toggleCardopen = () => {
    setIsOpens(!isOpens);
    setIsOpen(false);
    setIsOpent(false);
    setIsOpensf(false);
    setIsOpensfi(false);
  };

  const toggleCardopent = () => {
    setIsOpent(!isOpent);
    setIsOpen(false);
    setIsOpens(false);
    setIsOpensf(false);
    setIsOpensfi(false);
  };

  const toggleCardopenf = () => {
    setIsOpensf(!isOpenf);
    setIsOpen(false);
    setIsOpens(false);
    setIsOpent(false);
    setIsOpensfi(false);
  };

  const toggleCardopenfi = () => {
    setIsOpensfi(!isOpenfi);
    setIsOpen(false);
    setIsOpens(false);
    setIsOpent(false);
    setIsOpensf(false);
  };
  const handleTabChange = (index) => {
    console.log(index, "indexindexindex");
    setActiveTab(index);
  };

  const [faqs, setFaqs] = useState([
    {
      question: "What is Artificial Intelligence?",
      answer: "Artificial Intelligence (AI) is a part of computer science focused on making systems that can think and solve problems like humans. They learn from lots of information, get better over time, and use what they've learned to do things more efficiently in the future.",
      open: false
    },
    {
      question: "How long does it take to build an AI-based product?",
      answer: "The timeframe for developing an AI-based product varies based on its complexity. A highly complex solution with extensive features may take 10 to 18 months, while a simpler solution with fewer features could be completed in 3 to 6 months, depending on custom requirements.",
      open: false
    },
    {
      question: "What programming languages are commonly used in AI software development?",
      answer: "Python is the most popular and widely used programming language for AI software. Its extensive libraries like TensorFlow, PyTorch, and sci-kit-learn make it convenient for tasks such as data manipulation, machine learning, and deep learning. Other languages like Java, C++, and R are also used, depending on the specific requirements of the project.",
      open: false
    },
    {
      question: "How can you track the progress of your AI development project while collaborating with Bit Front?",
      answer: "We are a dedicated AI application development service company that follows agile project management practices. We ensure regular updates on AI software development progress for all stakeholders. For AI-based mobile or web apps, we offer login access to track real-time progress.",
      open: false
    },
    {
      question: "Can artificial intelligence be used to replace human workers?",
      answer: "AI can be used to solve tasks that humans currently do. However, it is not yet possible to develop AI systems that can replicate all the capabilities of human beings. As such, AI will likely supplement human workers instead of replacing them.",
      open: false
    },
    {
      question: "What does an AI engineer do?",
      answer: "AI engineers design, build and implement AI software solutions. Their job is to research new technologies, integrate them into existing systems, and test the results. They also monitor the performance of AI systems in production environments.",
      open: false
    },
    {
      question: "How do Data Science and AI differ?",
      answer: "Data science is the field of gaining insights and knowledge from data. AI is the application of computer systems that perform tasks commonly associated with intelligent behavior. A data scientist will use AI techniques as part of their analysis.",
      open: false
    },
    {
      question: "Do you sign NDA for my AI application development?",
      answer: "Yes, as a reputed AI application development company at Bit Front we sign an NDA to protect the AI project data of our client.",
      open: false
    },

  ]);
  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faqq, i) => {
        if (i === index) {
          return {
            ...faqq,
            open: !faqq.open,
          };
        } else {
          return {
            ...faqq,
            open: false,
          };
        }
      })
    );
  };
  const industries = [
    {
      icon: healths,
      title: 'Healthcare',
      description: 'Use AI-powered analytics for precise diagnoses, reliable predictions, and personalized treatment.',
      bgColor: '#536AFF', // Background color for the icon box
    },
    {
      icon: fintechs,
      title: 'FinTech',
      description: 'Tackle important finance tasks like predicting loan risks and automating financial analysis.',
      bgColor: '#7D11D3',
    },
    {
      icon: fintechs,
      title: 'ECommerce',
      description: 'Transform your retail business with digital solutions for a seamless, personalized shopping experience.',
      bgColor: '#536AFF',
    },
    {
      icon: autotechs,
      title: 'AutoTech-EV',
      description: 'Locate nearby EV charging stations, initiate charging sessions, and seamlessly make payments using AI solutions.',
      bgColor: '#7D11D3',
    },
    {
      icon: edtechs,
      title: 'EdTech-ELearning',
      description: 'Introduce a new platform and approach to learning with personalized experiences using AI solutions.',
      bgColor: '#536AFF',
    },
    {
      icon: traveltechs,
      title: 'TravelTech',
      description: 'Ensure a hassle-free travel experience for your customers using AI solutions.',
      bgColor: '#7D11D3',
    },
    {
      icon: esporticon,
      title: 'Esport-Gaming',
      description: 'Enhance gaming and sports experiences with interactive, immersive, and personalized digital solutions.',
      bgColor: '#536AFF',
    },
    {
      icon: foodicon,
      title: 'Food & Restaurant',
      description: 'Optimize your restaurant business with digital solutions for seamless ordering, payment, and delivery.',
      bgColor: '#7D11D3',
    },
    {
      icon: logisticicon,
      title: 'Logistics',
      description: 'Gain real-time insights and data, track precise locations, and operate efficiently while monitoring.',
      bgColor: '#536AFF',
    },
    {
      icon: entertainmentsicon,
      title: 'Entertainment',
      description: 'Innovative solutions to improve operations and engagement in the entertainment industry.',
      bgColor: '#7D11D3',
    },
    {
      icon: realestatesicon,
      title: 'Real Estate',
      description: 'Simplified AI solutions for managing real estate and property transactions.',
      bgColor: '#536AFF',
    },
  ];

  const [isOpena, setIsOpena] = useState(false);
  const openModal = () => setIsOpena(true);
  const closeModal = () => setIsOpena(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <div>
      <Header />
      <div>
        <div className="max-w-7xl h-auto rounded-2xl mx-auto mt-10 px-4 md:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 md:gap-20 p-6 md:p-8">
            <div>
              <h1 className="text-3xl md:text-4xl font-semibold leading-snug">
                It’s time for change, Solving What Matters with AI
              </h1>
              <p className="text-lg md:text-base mt-4 mb-6">
                BitFront AI development services help businesses improve their decision-making and automate business operations and workflows.
              </p>
              <button
                onClick={openModal}
                className="bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-full w-full md:w-52 h-14 flex items-center justify-center text-lg font-semibold text-white">
                <div className="bg-black rounded-full text-lg px-4 py-3 md:w-[202px] w-full md:h-[50px] flex items-center justify-center text-white">
                  Talk to Experts
                </div>
              </button>
            </div>
            <img alt="BitFront AI logo" src={BIAI} className="w-full h-auto object-contain" />
          </div>
        </div>
        {isOpena && (
          <div
            onClick={closeModal}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={`bg-white rounded-lg p-6 w-full max-w-4xl lg:flex transition-transform duration-300 transform ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
                }`}
            >
              <div
                className="w-full lg:w-1/2 p-6 text-white relative bg-cover bg-center"
                style={{ backgroundImage: "url('/images2/left-background.png')" }}
              >
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                <div className="relative z-10">
                  <h2 id="modal-title" className="text-3xl font-bold">
                    You have a Vision, we are here to help you <span className="text-green-500">Achieve it!</span>
                  </h2>
                  <p className="mt-4">Book a free 1:1 call with our expert</p>
                  <p className="mt-6 text-red-400 text-sm">
                    ** We will ensure that your data is not used for spamming.
                  </p>
                </div>
              </div>

              <div className="w-full lg:w-1/2 p-6 relative">
                <button
                  onClick={closeModal}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl font-bold"
                  aria-label="Close"
                >
                  &times;
                </button>

                <h3 className="text-xl font-semibold text-gray-800">Speak to our experts</h3>
                <p className="text-gray-600 mb-6">Please fill the form below</p>

                <form>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      placeholder="Full Name*"
                      className="border rounded-lg text-[#000000] p-2 w-full"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Email*"
                      className="border rounded-lg text-[#000000] p-2 w-full"
                      required
                    />
                    <input
                      type="text"
                      placeholder="Contact Number*"
                      className="border rounded-lg text-[#000000] p-2 w-full"
                      required
                    />
                    <input
                      type="text"
                      placeholder="Your Country*"
                      className="border rounded-lg text-[#000000] p-2 w-full"
                      required
                    />
                  </div>
                  <textarea
                    placeholder="Message*"
                    className="border rounded-lg text-[#000000] p-2 w-full mt-4"
                    rows="3"
                    required
                  ></textarea>

                  <div className="mt-4 flex items-center">
                    <input type="checkbox" id="nda" className="mr-2" />
                    <label htmlFor="nda" className="text-sm text-gray-600">Send me a copy of NDA</label>
                  </div>

                  <button
                    type="submit"
                    className="mt-6 w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 focus:bg-blue-700 transition-colors duration-300"
                  >
                    SEND MESSAGE
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-full p-5 border-y border-[#a3d2f3] h-auto md:h-[400px]">
          <div className="container mx-auto pt-5 pb-5">
            <h1 className="text-lg md:text-xl w-auto mb-1 leading-6 font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
              Our Services
            </h1>
            <h1 className="text-2xl md:text-3xl mb-3 font-medium">
              Artificial Intelligence Development Services We Offer
            </h1>
            <p className="text-base md:text-lg mb-6">
              Upgrade your business with BitFront's AI Development services. We provide customized solutions to improve customer experience, streamline
              supply chain operations, and manage inventory effectively. Our strategic use of advanced technologies such as facial recognition, fraud
              detection, and biometric authentication is aimed at boosting growth and achieving outstanding outcomes.
            </p>
            <p className="text-base md:text-lg">
              Our AI solutions use big data to achieve real business results. Our experts turn raw data into useful insights, making operations more
              efficient and decisions smarter. Check out our AI services to make your ideas come to life.
            </p>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] p-5 md:p-10 w-full">
          <div className="container mx-auto mb-2 mt-0 h-auto px-5 xl:px-0">
            <h1 className="text-lg md:text-xl w-auto mb-1 leading-6 font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
              Our Services
            </h1>
            <h1 className="text-2xl md:text-3xl mb-6 font-medium">
              Seamless solutions for every tech brand
            </h1>
            <div className="flex flex-col lg:flex-row gap-5 md:gap-10">
              <div className="block sm:hidden">
                <select
                  onChange={(e) => handleTabChange(Number(e.target.value))}
                  className="w-full p-3 border border-gray-300 rounded-md text-white bg-gradient-to-r from-[#26123C] to-[#040206] transition duration-200 ease-in-out"
                >
                  {tabs.map((tab, index) => (
                    <option
                      className="text-white bg-[#26123C]"
                      value={index}
                      key={index}
                    >
                      {tab.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full lg:w-1/3 hidden sm:block">
                {tabs.map((tab, index) => (
                  <div
                    onClick={() => handleTabChange(index)}
                    key={index}
                    className={`hover:font-semibold hover:bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text hover:text-transparent py-3 px-5 cursor-pointer ${activeTab === index
                      ? 'bg-[#2490ef0d] bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent font-semibold'
                      : 'border-l-4 border-transparent font-normal'
                      } flex items-center justify-between`}
                  >
                    <div className="leading-8">
                      <h1 className="text-base md:text-xl">{tab.label}</h1>
                    </div>
                    <FaAngleRight className="text-lg md:text-xl" />
                  </div>
                ))}
              </div>
              <div className="w-full lg:w-2/3">
                {activeTab === 0 && <AI />}
                {activeTab === 1 && <ML />}
                {activeTab === 2 && <CV />}
                {activeTab === 3 && <NLPD />}
                {activeTab === 4 && <LLMD />}
                {activeTab === 5 && <ADI />}
                {activeTab === 6 && <OC />}
                {activeTab === 7 && <GAI />}
                {activeTab === 8 && <DL />}
                {activeTab === 9 && <AIS />}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] h-auto p-5 border-b border-[#a3d2f3]">
          <div className="m-auto container pt-5 pb-5">
            <div className="mb-5 items-center grid grid-cols-1 md:grid-cols-2">
              <div>
                <h1 className="text-[22px] w-[143px] mb-2 leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                  Industries
                </h1>
                <h1 className="text-[28px] md:text-[32px] mb-3 font-medium">
                  Industries That Our AI Development Services Excel In
                </h1>
                <p className="text-[16px] md:text-[18px] mb-5">
                  Our AI developers excel in seamlessly integrating top-tier artificial intelligence services into your business offerings, processes, and growth strategies, irrespective of your industry.
                </p>
              </div>
              <button
                onClick={openModal}
                className="ml-0 md:ml-10 mt-4 md:mt-0 w-full md:w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                <div className="bg-[#000] rounded-full text-[18px] p-[10px] w-full h-[50px] m-auto text-[#fff]">
                  Talk to Experts
                </div>
              </button>
            </div>

            <div className="mb-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {industries.map((industry, index) => (
                <div key={index} className="p-6 bg-[#1F0A32] rounded-[6px] h-auto xl:h-[315px]">
                  <div className={`mb-4 w-[81px] rounded-[12px] p-2 h-[78px]`} style={{ backgroundColor: industry.bgColor }}>
                    <img alt={industry.title.toLowerCase()} src={industry.icon} />
                  </div>
                  <div className="mb-2 flex items-center">
                    <h1 className="text-[20px] md:text-[25px] font-normal">{industry.title}</h1>
                    <FaArrowRightLong className="ml-2 text-[20px] md:text-[25px]" />
                  </div>
                  <p className="text-[16px] md:text-[18px]">{industry.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>


        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] p-5 border-b border-[#a3d2f3]">
          <div className="container mx-auto pt-5 pb-5">
            <h1 className="text-2xl mb-2 leading-6 font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
              Portfolio
            </h1>
            <p className="text-4xl font-medium mb-6">
              Our AI Development Services Success Stories
            </p>
            <div>
              <div className="sticky top-[10rem] grid xl:grid-cols-2 grid-cols-1 justify-center">
                <div className=" w-full md:w-1/2 lg:w-[660px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-l-lg p-8">
                  <img alt="img" className="w-[120px] h-[54px]" src={koovsicon} />
                  <h1 className="text-2xl text-[#9b65c3] font-medium mb-2">Our Feature Work</h1>
                  <p className="text-xl mb-6 font-medium leading-7 max-w-[530px]">
                    Retail Inventory Management Solution Featuring Virtual Assistant Driven by Chatbot Integration
                  </p>
                  <p className="text-base leading-6 font-normal max-w-[630px] text-[#dadada] mb-6">
                    Incorporating chatbots and virtual assistants involved developing AI models capable of understanding and responding to a variety of inventory-related queries.
                  </p>
                  <div className="flex mb-6 items-center">
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Android</span>
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">IOS</span>
                    <span className="text-base leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Web</span>
                  </div>
                  <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-full text-lg">
                    <div className="bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-full text-lg p-2 w-full text-center text-white">Visit Portfolio</div>
                  </button>
                </div>
                <div className="w-full md:w-1/2 lg:w-[660px] bg-black rounded-r-lg p-8">
                  <img alt="img" className="w-full h-auto object-cover" src={koovsweb} />
                </div>
              </div>
              <div className="sticky top-[10rem] grid xl:grid-cols-2 grid-cols-1 justify-center">
                <div className="w-full md:w-1/2 lg:w-[660px] bg-black rounded-l-lg p-8">
                  <img alt="img" className="w-full h-auto object-cover" src={revazaweb} />
                </div>
                <div className="w-full md:w-1/2 lg:w-[660px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-r-lg p-8">
                  <img alt="img" className="w-[120px] h-[54px]" src={revezaicon} />
                  <h1 className="text-2xl text-[#9b65c3] font-medium mb-2">Our Feature Work</h1>
                  <p className="text-xl mb-6 font-medium leading-7 max-w-[530px]">
                    Reveza - AI Transforming the Customer Journey
                  </p>
                  <p className="text-base leading-6 font-normal max-w-[630px] text-[#dadada] mb-6">
                    Reveza empowers brand's journey with a cutting-edge platform, seamlessly integrating AI to capture customer sentiment from pre to post purchase by unleashing the power of reviews, real-time responsive engagement, and also instant gratification reward model to transform brand's customer experience fostering unwavering loyalty.
                  </p>
                  <div className="flex mb-6 items-center">
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Android</span>
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">IOS</span>
                    <span className="text-base leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Web</span>
                  </div>
                  <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-full text-lg">
                    <div className="bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-full text-lg p-2 w-full text-center text-white">Visit Portfolio</div>
                  </button>
                </div>
              </div>
              <div className="sticky top-[10rem] grid xl:grid-cols-2 grid-cols-1 justify-center">
                <div className="w-full md:w-1/2 lg:w-[660px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-l-lg p-8">
                  <img alt="img" className="w-[120px] h-[54px]" src={easecareicon} />
                  <h1 className="text-2xl text-[#9b65c3] font-medium mb-2">Our Feature Work</h1>
                  <p className="text-xl mb-6 font-medium leading-7 max-w-[530px]">
                    Personalized Health Predictions Driven by Predictive Analytics, NLP, and ML
                  </p>
                  <p className="text-base leading-6 font-normal max-w-[630px] text-[#dadada] mb-6">
                    We developed a Predictive Analytics system using Natural Language Processing and Machine Learning to forecast health trends and risks. Our system analyzes longitudinal health data, learning and adapting to provide personalized health predictions.
                  </p>
                  <div className="flex mb-6 items-center">
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Android</span>
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">IOS</span>
                    <span className="text-base leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Web</span>
                  </div>
                  <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-full text-lg">
                    <div className="bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-full text-lg p-2 w-full text-center text-white">Visit Portfolio</div>
                  </button>
                </div>
                <div className="w-full md:w-1/2 lg:w-[660px] bg-black rounded-r-lg p-8">
                  <img alt="img" className="w-full h-auto object-cover" src={easecareweb} />
                </div>
              </div>
              <div className="sticky top-[10rem] grid xl:grid-cols-2 grid-cols-1 justify-center">
                <div className="w-full md:w-1/2 lg:w-[660px] bg-black rounded-l-lg p-8">
                  <img alt="img" className="w-full h-auto object-cover" src={rightangleweb} />
                </div>
                <div className="w-full md:w-1/2 lg:w-[660px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-r-lg p-8">
                  <img alt="img" className="w-[120px] h-[54px]" src={rightangleicon} />
                  <h1 className="text-2xl text-[#9b65c3] font-medium mb-2">Our Feature Work</h1>
                  <p className="text-xl mb-6 font-medium leading-7 max-w-[530px]">
                    AI Tutor For Personalized Learning Experience
                  </p>
                  <p className="text-base leading-6 font-normal max-w-[630px] text-[#dadada] mb-6">
                    This AI application offers personalized learning plans for students, using Machine Learning to adapt to their needs and learning styles. It engages students by providing tailored resources and feedback based on performance metrics.
                  </p>
                  <div className="flex mb-6 items-center">
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Android</span>
                    <span className="text-base mr-4 leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">IOS</span>
                    <span className="text-base leading-6 w-auto p-2 text-center rounded-full bg-[#470E7E] font-normal">Web</span>
                  </div>
                  <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-full text-lg">
                    <div className="bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-full text-lg p-2 w-full text-center text-white">Visit Portfolio</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] p-5 border-b border-[#a3d2f3]">
          <div className="container mx-auto pt-5 pb-5">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
              <div>
                <h2 className="text-2xl mb-2 leading-6 font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                  Process
                </h2>
                <h1 className="text-4xl mb-4 font-medium">AI-Driven Software Development Process</h1>
                <p className="text-lg text-[#DADADA] mb-6">
                  Check out the steps it takes us to make your project a success
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Discovery
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Project Setup
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Development
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Testing
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Live Release
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Support
                  </li>
                  <li className="flex items-center mb-6">
                    <span className="mr-3 w-8 h-1 bg-[#bf63c6]"></span>Maintenance
                  </li>
                </ul>
                <button
                  onClick={openModal}
                  className="xl:w-[208px] p-[1px] my-[30px] xl:h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-3xl text-lg">
                  <div className=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">
                    Talk to Experts
                  </div>
                </button>
              </div>
              <img className="w-full h-auto" alt="AI Process" src={artificialprocessimage} />
            </div>
          </div>
        </div>


        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-full p-5 border-b border-[#a3d2f3]">
          <div className="container mx-auto pt-5 pb-5">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <h1 className="text-[22px] mb-2 leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                  Technology
                </h1>
                <p className="text-[32px] font-medium mb-6">
                  Alliances and Partnerships
                </p>
                <p className="text-[18px] mb-6">
                  We work closely with various partners and platforms to achieve flexible and efficient results while ensuring high quality and excellence.
                </p>
                <p className="text-[18px] mb-6">
                  We continually exceed expectations by delivering exceptional work that not only meets but exceeds custom requirements. As a leading provider of AI development services, we collaborate with clients worldwide to develop and expand their digital products using high-quality technologies such as artificial intelligence, cloud computing, data science, analytics, AR/VR, IoT, blockchain, and the metaverse.
                </p>
                <div className="flex flex-col md:flex-row items-center mb-6">
                  <div className="w-full md:w-[211px] h-[200px] p-2 border-r-2 border-[#127E9B] md:ml-2">
                    <h1 className="text-[85px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                      13+
                    </h1>
                    <span className="text-[18px]">AI Solutions</span>
                  </div>
                  <div className="w-full md:w-[211px] h-[200px] p-2 border-r-2 border-[#127E9B] md:ml-2">
                    <h1 className="text-[85px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                      10+
                    </h1>
                    <span className="text-[18px]">AI Engineers</span>
                  </div>
                  <div className="w-full md:w-[211px] h-[200px] p-2">
                    <h1 className="text-[85px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                      6+
                    </h1>
                    <span className="text-[18px]">Work Experience</span>
                  </div>
                </div>
              </div>

              <div>
                <img alt='Alliances and Partnerships' src='images2/AlliancesandPartnerships.png' />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] p-5 border-b border-[#a3d2f3]">
          <div className="container mx-auto pt-5 pb-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h1 className="text-[22px] mb-2 leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent">
                  Technology
                </h1>
                <h1 className="text-[32px] mb-4 font-medium">
                  Our AI Development Technology Stack
                </h1>
                <button
                  onClick={openModal}
                  className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-3xl text-lg">
                  <div className=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">
                    Talk to Experts
                  </div>
                </button>
              </div>
              <div className="flex justify-center md:ml-4">
                <img
                  alt="AI Development Technology"
                  className="w-full md:w-[776px] h-auto max-w-full"
                  src={artificialtechnologynewimg}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-screen p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
          <div className=" m-auto container pt-5 pb-5">
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div>
                <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                  Partners</h1>
                <h1 className="text-[32px] mb-[15px] font-medium ">AI Models We Have Expertise In</h1>
                <p className="text-[18px]">
                  For over 6+ years, our AI services have enhanced business processes, helping organizations achieve rapid
                  growth with the latest AI tools and technologies. Watch out these amazing latest AI Models -
                </p>
              </div>
              <img alt="img" className="xl:w-[746px] w-full h-auto xl:h-[471px] ml-[20px]" src={artificialpartnermin} />
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] p-5 border-b border-[#a3d2f3]">
          <div className="mt-[40px] flex flex-wrap justify-center">
            <div className="w-full md:w-[550px] h-auto mb-5 md:mb-0">
              <h1 className="text-[32px] font-medium leading-[32px] mb-[25px]">Why choose us?</h1>
              <p className="text-[18px] leading-[33px] font-normal">
                Being one of the dedicated AI services companies, we use our deep domain expertise to push the boundaries
                of what's achievable for your business. Our AI app development services are designed to deliver tangible results
                and unlock the full potential of artificial intelligence.
              </p>
            </div>
            <div className="">
              {[
                {
                  title: "Data-Centric Approach",
                  content: "We use proven and advanced big data technologies to convert the raw data into actionable insights.",
                  isOpen: isOpen,
                  toggle: toggleCard,
                },
                {
                  title: "Outcome-Driven",
                  content: "Our experienced team of Data Scientists and Customer Success Managers drive ROI by a customer-centric approach and help you achieve your business goals.",
                  isOpen: isOpens,
                  toggle: toggleCardopen,
                },
                {
                  title: "Transparent & Secure",
                  content: "We are governed by ISO 27001 to safeguard your data with complete transparency through data security privacy and comply with all data compliance requirements.",
                  isOpen: isOpent,
                  toggle: toggleCardopent,
                },
                {
                  title: "Proven proficiency",
                  content: "Our AI specialists are well-versed in IT trends, artificial intelligence algorithms, and deep learning architectures.",
                  isOpen: isOpenf,
                  toggle: toggleCardopenf,
                },
                {
                  title: "Technology Agnostic",
                  content: "As a technology-agnostic company, we maintain expertise in multiple technologies and help you choose the right technology for your business needs.",
                  isOpen: isOpenfi,
                  toggle: toggleCardopenfi,
                },
              ].map((card, index) => (
                <div
                  key={index}
                  className={`mb-[30px] p-4 rounded-lg border-2 ${card.isOpen ? 'bg-black border-gradient-to-r from-[#08dff8] to-[#b811e5]' : 'bg-black border-transparent'} w-full lg:w-[780px] h-auto`}
                  onClick={card.toggle}
                >
                  <div className="flex items-center">
                    <div className="text-[#fff] text-[20px] w-[32px] h-[32px] rounded-full p-[6px] bg-[#4ab145] mr-2">
                      <GoCheck />
                    </div>
                    <h2 className="text-white font-bold">{card.title}</h2>
                  </div>
                  {card.isOpen && (
                    <p className="text-white mt-2">
                      {card.content}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-full p-[40px] border-b-[0.1px] h-auto border-[#a3d2f3]">
          <div className="xl:w-[1296px] w-full border-transparent p-[3px] rounded-[20px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-border h-auto xl:h-[255px] m-auto">
            <div className="text-center xl:w-[1290px] w-full h-auto xl:h-[249px] p-[20px] rounded-[20px] m-auto bg-gradient-to-r from-[#26123C] to-[#040206]">
              <h1 className="text-[32px] font-medium mb-[25px]">
                Let’s discuss your AI/ML project and transform your business with our expert AI development services.
              </h1>
              <button
                onClick={() => navigate("/contact-us")}
                className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]"
                aria-label="Contact Us Button"
              >
                <div className="bg-[#000] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">
                  Contact Us
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-full p-[20px] border-b border-[#a3d2f3]">
          <h1 className="text-[32px] font-medium w-full max-w-[1200px] m-auto mb-[25px]">FAQs</h1>
          <div>
            <div className="App text-[#fff]">
              <div className="faqs">
                {faqs.map((faqq, index) => (
                  <FAQsw faqq={faqq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footerq />
      </div>
      {/* {isModalOpen ? (
        <Modal onClose={() => setIsModalOpen(false)} />
      ) : (
        <Modal onClose={() => setIsModalOpen(false)} />
      )} */}
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </div>
  )
}

export default BitfrontAI
