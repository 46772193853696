import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import Customized from '../../assets/images/Customized.png';
import Savetime from '../../assets/images/Savetime.png';
import ReduceCosts from '../../assets/images/ReduceCosts.png';
import Lowrisk from '../../assets/images/Lowrisk.png';
import Customization from '../../assets/images/Customization.png';
import Costeffective from '../../assets/images/Costeffective.png';
import EnhancedEfficiency from '../../assets/images/EnhancedEfficiency.png';
import NoRisks from '../../assets/images/NoRisks.png';
import Adaptability from '../../assets/images/Adaptability.png';
import FastImplementation from '../../assets/images/FastImplementation.png';
import Maintenance from '../../assets/images/Maintenance.png';
import EHREMRSoftwareDevelopment from '../../assets/images/EHREMRSoftwareDevelopment.png';
import telemedicineappdevelopment from '../../assets/images/telemedicineappdevelopment.png';
import Sportsfitness from '../../assets/images/Sportsfitness.png';
import jobportalwebsite from '../../assets/images/jobportalwebsite.png';
import TaxiBooking from '../../assets/images/TaxiBooking.png';
import portfoliobnr from '../../assets/images/portfoliobnr.png';
import ChatAppFeatures from '../../assets/images/ChatAppFeatures.png';
import Eweb from '../../assets/images/Eweb.png';
import LMSIntegrations from '../../assets/images/LMSIntegrations.png';
import panel from '../../assets/images/panel.png';
import MobileSolutions from '../../assets/images/MobileSolutions.png';
import ProtoverifyApp from '../../assets/images/ProtoverifyApp.png';
import buildfire from '../../assets/images/buildfire.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const CustomizableApp = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is a customised app?",
            answer:
                "Custom app development is designing software applications for users in an organization to fulfill specific business needs. Designing and developing your custom app focuses on specific needs instead of using more conventional and standard software.",
            open: false
        },
        {
            question: "Can I create my app?",
            answer: "If you're a programmer or you can code, you can create mobile applications independently, using online resources to upskill yourself. If you can't code, you can use a no-code app maker or an app development company like BitFront.",
            open: false
        },
        {
            question:
                "What are the benefits of using customizable app solutions?",
            answer: "Increased efficiency and productivity, improved user experience, cost savings, better adaptability to changing needs, and competitive advantage.",
            open: false
        },
        {
            question:
                "What are some examples of customizable app solutions?",
            answer: "Learning Management Systems (LMS), Job Portals/Hiring Applications, EHR/EMR Software, Practice Management Software, Fitness Apps, Telemedicine Apps, Payroll Management Software, and many more.",
            open: false
        },
        {
            question:
                "What level of customization can I expect?",
            answer: "It's different for each solution provider and plan. Some offer basic adjustments like branding and workflows, while others allow extensive modifications to features and functionality.",
            open: false
        },
        {
            question:
                "How easy is it to customize the app?",
            answer: "The ease of customization generally depends on the solution. Some offer user-friendly interfaces and drag-and-drop features, while others require technical expertise.",
            open: false
        },
        {
            question:
                "What should I consider when choosing a level of customization?",
            answer: "Your technical skills, budget, specific needs, and future growth potential.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const services = [
        {
            title: "EHR/EMR",
            heading: "Customizable EHR/EMR Software",
            description: "We design custom EHR/EMR Software solutions with features for scheduling appointments, managing patient-doctor communication, and more. Our portals also allow healthcare providers to classify diseases accurately in various health records using the latest and most detailed information.",
            features: ["Personalized care", "Reduced medication errors", "Streamlined clinical decision-making"],
            buttonColor: "bg-[#16ba7f]",
            image: EHREMRSoftwareDevelopment,
            alt: "EHREMRSoftwareDevelopment"
        },
        {
            title: "Telemedicine",
            heading: "Customizable Telemedicine App",
            description: "If you want to build a telemedicine app for your business with live streaming and an online payment gateway, we have a ready-made Telemedicine App designed and developed for customers to book doctors and take consultations online.",
            features: ["100% Customization", "Boost Efficiency by 60%", "Reduce Costs by 70%"],
            buttonColor: "bg-[#6191fe]",
            image: telemedicineappdevelopment,
            alt: "telemedicineappdevelopment"
        },
        {
            title: "Fitness App",
            heading: "Customizable Fitness App/Fitness Tracking App",
            description: "BitFront offers customized fitness app development solutions, building cutting-edge fitness apps that offer seamless experiences and assurance, ensuring users stay engaged and motivated for sustained participation.",
            features: ["Seamless Delivery", "Customized Fitness Plans", "Cost-efficient"],
            buttonColor: "bg-[#16ba7f]",
            image: Sportsfitness,
            alt: "Sportsfitness"
        },
        {
            title: "Job Portal",
            heading: "Customizable Job Portal / Hiring Application",
            description: "We simplify recruitment by providing a high-end & customized job portal solution integrating various recruitment processes and automation functionalities. The platform is built to be customizable for any workflow that the hiring team wants to implement.",
            features: [
                "Better Hiring Process",
                "Enhanced employer branding",
                "Better Data-Driven Decisions",
            ],
            buttonColor: "bg-[#6191fe]",
            image: jobportalwebsite,
            alt: "jobportalwebsite"
        },
        {
            title: "Taxi Booking",
            heading: "Customizable Bike & Taxi Booking App",
            description: "Take a look at this ride-booking app, similar to Uber, Rapido & Ola, where users can easily book or schedule rides. It also has options for delivery requests and tracking. You can change it to fit your needs and it comes with lots of nice-looking design elements.",
            features: ["100% Customization", "Boost Efficiency by 60%", "Reduce Costs by 70%"],
            buttonColor: "bg-[#16ba7f]",
            image: TaxiBooking,
             alt: "TaxiBooking"
        },
        {
            title: "Food Delivery",
            heading: "Customizable Food and Grocery Delivery Application",
            description:"A great starter kit for delivery apps, easy to use, grow, and keep up. It uses Flutter for the front end of Mobile and Node.js, Express.js, TypeScript, GraphQL, and PostgreSQL for the back end. You can change it to fit any delivery or service app you want.",
            features: [
                "Grocery Purchase Flow for Users",
                "Order Checking for Vendors",
                "Product Search and Vendor Selection",
            ],
            buttonColor: "bg-[#6191fe]",
            image: portfoliobnr,
            alt: "portfoliobnr"
        },
        {
            title: "Messaging App",
            heading: "Customizable Chat and Messaging App",
            description:
                "A chat application for web and mobile that allows users to communicate in real-time through text, voice, or video. It can be customized to accommodate all your modern chat app requirements.",
            features: ["100% Customized", "Multi-chat Support", "Mobile App Portability"],
            buttonColor: "bg-[#16ba7f]",
            image: ChatAppFeatures,
            alt: "chat-app",
        },
        {
            title: "E-commerce",
            heading: "Customizable E-commerce Portal & Mobile App",
            description:
                "If you’re looking to build an e-commerce application designed to perform all the essential functions, BitFront has all the necessary features to save you hundreds of development hours.",
            features: ["100% Customization", "Save time up to 50%", "Save money up to 60%"],
            buttonColor: "bg-[#6191fe]",
            image: Eweb,
            alt: "e-commerce",
        },
        {
            title: "E-Learning",
            heading: "Customizable Learning Management System (LMS)",
            description:
                "Our customizable Learning Management System provides organizations with flexibility, scalability, enhanced engagement, advanced analytics, integration capabilities, compliance, cost-effectiveness, rapid deployment, and competitive advantage, making it a valuable tool for employee development and organizational growth.",
            features: ["Interactive Learning Tools", "Personalized Learning Paths", "Branded Interface"],
            buttonColor: "bg-[#16ba7f]",
            image: LMSIntegrations,
            alt: "LMS Integrations",
        },
        {
            title: "HRM",
            heading: "Customizable HR Management System",
            description:
                "Our All-in-One HR system helps businesses manage their employees better. It makes handling HR tasks easier, like hiring, managing, training, and keeping staff happy. Get our affordable HR system to make managing your workforce easy.",
            features: ["100% Customized Solutions", "Scalability", "Increased Efficiency"],
            buttonColor: "bg-[#6191fe]",
            image: panel,
            alt: "HR Management Panel",
        },
        {
            title: "Payroll Management",
            heading: "Customizable Payroll Management Software",
            description:
                "We make customized, full-service payroll software that handles paying employees, calculating taxes, managing staff, entering pay data, and other tasks. Our software helps with calculations, payments, and filings, making everything faster and simpler for you.",
            features: ["Faster processing", "Increased Accuracy and Efficiency", "Scalability"],
            buttonColor: "bg-[#16ba7f]",
            image: MobileSolutions,
            alt: "Mobile Solutions",
        },
        {
            title: "Background Verification",
            heading: "Customizable Employee Background Verification App",
            description:
                "Our ready-made Employee Background Verification Application provides companies with pre-employment screening solutions to verify the information provided by job applicants. It makes it easier for organizations to screen new hires and volunteers. The app collects and organizes data from checks on employment, education, credit history, and criminal background.",
            features: ["Help Protect Workplace Safety", "Reduce Your Liability", "Hiring Top Candidates"],
            buttonColor: "bg-[#6191fe]",
            image: ProtoverifyApp,
            alt: "Protoverify App",
        },
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-12 justify-center p-6">
                    <div className="text-center md:text-left">
                        <h1 className="text-2xl md:text-4xl font-bold">
                            Customized App <span className="text-[#0652dd]">Solutions for Businesses</span>
                        </h1>
                        <p className="text-base md:text-lg mt-4">
                            Custom business software applications are a way to take your idea and bring it to life, working with our
                            team of expert developers to design a solution that fits your needs without compromising on features or
                            functionality.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-4 space-y-2 text-gray-700">
                            <li>✅ Project planning</li>
                            <li>✅ Choosing tech stack</li>
                            <li>✅ UI/UX</li>
                            <li>✅ Programming</li>
                        </ul>
                        <div className="mt-8 flex flex-wrap justify-center md:justify-start gap-8">
                            <div className="text-center">
                                <img alt="Customized" className="w-10 h-10 bg-[#ccfde4] rounded-full mx-auto" src={Customized} />
                                <h1 className="text-base mt-2">100% Customized</h1>
                            </div>
                            <div className="text-center">
                                <img alt="Save time" className="w-10 h-10 bg-[#ccfde4] rounded-full mx-auto" src={Savetime} />
                                <h1 className="text-base mt-2">Save time up to 70%</h1>
                            </div>
                            <div className="text-center">
                                <img alt="Reduce Costs" className="w-10 h-10 bg-[#ccfde4] rounded-full mx-auto" src={ReduceCosts} />
                                <h1 className="text-base mt-2">Reduce Costs by 50%</h1>
                            </div>
                            <div className="text-center">
                                <img alt="Low-risk" className="w-10 h-10 bg-[#ccfde4] rounded-full mx-auto" src={Lowrisk} />
                                <h1 className="text-base mt-2">Low-risk factor</h1>
                            </div>
                        </div>
                        <button className="transition-all duration-200 rounded-full bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[170px] mt-10 h-10">
                            LET'S TALK
                        </button>
                    </div>
                    <img
                        alt="buildfire"
                        className="w-full h-auto max-w-lg mx-auto"
                        src={buildfire}
                    />
                </div>
            </div>

            <div className="max-w-7xl mx-auto p-6">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-12 justify-center items-start">
                    <div className="text-center lg:text-left">
                        <h1 className="text-2xl lg:text-3xl font-semibold mb-6">
                            Why choose our Customizable <span className="text-[#0652DD]">Software/App Solutions?</span>
                        </h1>
                        <img
                            alt="zorbis"
                            className="w-full h-auto max-w-md mx-auto lg:mx-0"
                            src="https://www.zorbis.com/images/app-development.jpg"
                        />
                    </div>
                    <div className="space-y-6">
                        {[
                            { img: Customization, text: "100% Customization" },
                            { img: Costeffective, text: "Cost-effective" },
                            { img: EnhancedEfficiency, text: "Enhanced Efficiency" },
                            { img: NoRisks, text: "No Risks" },
                            { img: Adaptability, text: "Adaptability" },
                            { img: FastImplementation, text: "Fast Implementation" },
                            { img: Maintenance, text: "Maintenance" },
                        ].map((item, index) => (
                            <div
                                key={index}
                                className="p-4 flex items-center border border-[#0652dd] rounded-lg w-full max-w-md mx-auto lg:mx-0"
                            >
                                <img alt={item.text} className="w-12 h-12" src={item.img} />
                                <h1 className="ml-4 text-lg font-semibold">{item.text}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* {cards} */}

            <div className="max-w-7xl mx-auto mb-10 p-6">
                {services.map((service, index) => (
                    <div key={index} className="mb-10 p-6 border rounded-lg">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-12 items-center">
                            <div>
                                <div className="inline-block bg-[#d1f3e7] px-4 py-2 rounded-lg mb-4">
                                    <h1 className="text-lg font-medium">{service.title}</h1>
                                </div>
                                <h1 className="text-2xl lg:text-3xl font-semibold mb-4">{service.heading}</h1>
                                <p className="text-lg font-medium text-[#272727] mb-6">{service.description}</p>
                                {service.features.map((feature, i) => (
                                    <div key={i} className="flex items-center mb-4">
                                        <img
                                            alt="check"
                                            src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg"
                                            className="w-5 h-5"
                                        />
                                        <p className="text-lg font-medium ml-3">{feature}</p>
                                    </div>
                                ))}
                                <button className={`${service.buttonColor} text-sm font-semibold text-white rounded-full py-2 px-6`}>
                                    LET'S DISCUSS
                                </button>
                            </div>
                            <img
                                alt={service.alt}
                                className="w-full h-auto max-w-md mx-auto lg:mx-0"
                                src={service.image}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <h1 className="text-2xl text-center font-semibold mb-8">
                How do we <span className="text-[#0652dd]">apply Customized App Solutions</span>
            </h1>
            <div className="flex flex-col md:flex-row justify-center gap-12">
                <div className="flex flex-col gap-6">
                    {[
                        {
                            number: "01",
                            title: "Consulting",
                            description: "Choosing the BEST solution for YOUR business"
                        },
                        {
                            number: "02",
                            title: "Estimate",
                            description: "Counting cost and time efforts"
                        },
                        {
                            number: "03",
                            title: "Development",
                            description: "Project development based on modules"
                        },
                        {
                            number: "04",
                            title: "Equipment",
                            description: "Assistance with choosing and integrating equipment"
                        }
                    ].map((item) => (
                        <div key={item.number} className="flex items-center">
                            <div className="text-center border border-[#000] p-2 rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-10 h-10 flex items-center justify-center">
                                <h1 className="font-semibold">{item.number}</h1>
                            </div>
                            <div className="ml-4">
                                <h1 className="text-lg font-semibold">{item.title}</h1>
                                <p className="text-base">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col gap-6">
                    {[
                        {
                            number: "05",
                            title: "Support",
                            description: "Further technical and business support"
                        },
                        {
                            number: "06",
                            title: "Launch",
                            description: "Product launch"
                        },
                        {
                            number: "07",
                            title: "Education",
                            description: "Staff training on how to use the system"
                        }
                    ].map((item) => (
                        <div key={item.number} className="flex items-center">
                            <div className="text-center border border-[#000] p-2 rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-10 h-10 flex items-center justify-center">
                                <h1 className="font-semibold">{item.number}</h1>
                            </div>
                            <div className="ml-4">
                                <h1 className="text-lg font-semibold">{item.title}</h1>
                                <p className="text-base">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="App">
                <h1 className="text-[35px] xl:w-[700px] text-center m-auto font-bold">FAQs</h1>
                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CustomizableApp
