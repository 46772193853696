import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Footer from "../Footer";
import Arvr from '../../assets/images/Arvr.png';
import VirtualReality from '../../assets/images/VirtualReality.png';
import Custom from '../../assets/images/Custom.png';
import ExperienceDesign from '../../assets/images/ExperienceDesign.png';
import Hardware from '../../assets/images/Hardware.png';
// import Multiple from '../../assets/images/Multiple.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import FAQ from '../../components/FAQ/FAQ';
import ScrollButton from '../../components/scrollb/ScrollButton';
const ARVRappdevelopment = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };
    const [faqs, setFaqs] = useState([
        {
            question: "Custom-Based Solutions",
            answer:
                "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
            open: false
        },
        {
            question: "Industrial Experts",
            answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
            open: false
        },
        {
            question: "Seamless Communication",
            answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
            open: false
        },
        {
            question: "Latest Technology",
            answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
            open: false
        },

    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl mx-auto p-5 bg-white rounded-2xl">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center px-6 md:px-12 py-10">
                    {/* Left Content */}
                    <div className="text-center lg:text-left">
                        <h1 className="text-3xl md:text-5xl font-bold leading-tight">
                            <span className="text-[#0652dd]">AR VR App</span> Development Services
                        </h1>
                        <p className="text-base md:text-lg mt-4 text-gray-700">
                            Integrate our cutting-edge AR/VR app development services and re-energize
                            your business with an immersive customer experience.
                        </p>
                        <ul className="mt-4 space-y-2 text-gray-700">
                            <li>✅ In-store Engagement</li>
                            <li>✅ Remote Assistance</li>
                            <li>✅ Employee Training</li>
                            <li>✅ Virtual Tours</li>
                            <li>✅ Digital Health</li>
                        </ul>
                        <button className="mt-6 md:mt-8 w-full md:w-[200px] h-[45px] transition-all duration-200 rounded-full bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-2 border-[#0652dd]">
                            TALK TO OUR EXPERT
                        </button>
                    </div>

                    <img
                        src={Arvr}
                        alt="AR VR App"
                        className="w-full max-w-xs md:max-w-md lg:max-w-lg h-auto "
                    />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>

            <div className="w-full max-w-7xl mx-auto p-5 bg-white">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 md:gap-20 items-center py-8">
                    {/* Left Content */}
                    <div className="text-center md:text-left">
                        <h1 className="text-2xl md:text-3xl font-bold leading-tight">
                            Leading <span className="text-[#0652dd]">AR VR App</span> Development Company in India
                        </h1>
                        <p className="text-[#242424] text-base md:text-lg pt-4 leading-relaxed">
                            BitFront, a renowned <b>AR/VR app development company</b>, helps businesses and organizations
                            capitalize on the potential of virtual reality to captivate customers and boost revenue.
                            As consumer expectations continue to rise, there's a growing need for innovative engagement
                            methods, and we offer comprehensive support in this area.
                        </p>
                        <p className="text-[#242424] text-base md:text-lg pt-4 leading-relaxed">
                            At the forefront of AR/VR development, we are renowned for introducing
                            agile, user-centric, and measurable product development principles, ensuring
                            our clients' businesses grow as per their expectations. Through this
                            approach, we not only deliver outstanding virtual reality app development
                            services that impress and engage your target audience but also drive your
                            core business objectives forward.
                        </p>
                    </div>

                    {/* Right Image */}
                    <div className="flex justify-center">
                        <img
                            src={VirtualReality}
                            alt="Virtual Reality"
                            className="w-full max-w-md md:max-w-lg h-auto"
                        />
                    </div>
                </div>
            </div>


            <div className="w-full bg-white mt-10">
                <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-16 ">
                    <div className="text-center lg:text-left max-w-2xl">
                        <h1 className="text-3xl md:text-4xl lg:text-[45px] font-bold">Our
                            <span className="text-[#0652dd]"> AR/VR App</span> Development Services</h1>
                        <p className="text-base md:text-lg mt-4">
                            We are a renowned AR/VR app development company that combines
                            extensive technical expertise with creativity to produce immersive
                            applications. At the heart of our expertise lies empowering technological
                            solutions and crafting immersive, interactive app experiences of the highest quality..
                        </p>
                        <p className="text-base md:text-lg mt-4">
                            Our main goal is to keep pushing the limits of what's possible in the virtual
                            world, giving our clients a clear edge over their competition. As one of the
                            premier AR/VR Development Companies in India, we lead the way in
                            creating customized solutions that drive disruptive innovations.
                        </p>
                        <button className=" w-full mt-[20px] md:w-[250px] h-[48px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                            <b>TALK TO OUR DEVELOPERS</b>
                        </button>
                    </div>
                    <div className="flex flex-col gap-6 mt-10 lg:mt-0">
                        <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                            <div className="flex items-center gap-2">
                                <img alt='Custom' className="w-[40px] h-[40px]" src={Custom} />
                                <h4 className="text-lg md:text-xl font-bold">Custom AR/VR Development</h4>
                            </div>
                            <p className="text-base pl-2">
                                Our AR/VR development services build amazing AR/VR apps with
                                video transmission, image recognition, and 3D rendering that
                                extend real-world scenes and provide an interactive user
                                experience.
                            </p>
                        </div>
                        <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                            <div className="flex items-center gap-2">
                                <img alt='ExperienceDesign' className="w-[40px] h-[40px]" src={ExperienceDesign} />
                                <h4 className="text-lg md:text-xl font-bold">AR/VR Experience Design</h4>
                            </div>
                            <p className="text-base pl-2">
                                We design an immersive experience combining UX, sound, and animated
                                graphics principles with rich content to ensure a dynamic appearance
                                and attract users.
                            </p>
                        </div>
                        <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                            <div className="flex items-center gap-2">
                                <img alt='Hardware' className="w-[40px] h-[40px]" src={Hardware} />
                                <h4 className="text-lg md:text-xl font-bold">Hardware-level Development</h4>
                            </div>
                            <p className="text-base pl-2">
                                Our AR/VR app development solutions are backed by various server-side solutions,
                                accelerometers, cameras, and GPS to embed our solutions into different hardware setups.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-12 px-4 md:px-[30px] py-8 md:py-[30px] w-full max-w-screen-xl bg-white mx-auto">
                <div className="w-full bg-[#fafafa] p-5 rounded-lg">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start font-semibold">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center text-xl">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 bg-white border border-gray-200 rounded-lg p-6 shadow-sm hover:shadow-lg transform hover:scale-105 transition-transform duration-200">
                                <p className="text-lg flex gap-2 items-center font-medium">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center text-xl">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-gray-700 text-base pt-4 leading-relaxed">
                                    "{review.reviewText}"
                                </p>
                                <p className="text-gray-500 text-sm font-medium mt-3">
                                    — {review.author}
                                </p>
                                <p className="text-green-600 text-sm font-semibold">{review.verified}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-center mt-9 mb-5">
                Our <span className="text-[#0652dd]">Multi-industry</span> Experience
            </h1>
            <p className="text-base md:text-lg text-center leading-relaxed max-w-3xl mx-auto px-4">
                Every type of industry, whether old-fashioned or modern, has the chance to become a digital leader with a strong website.
                At BitFront, we assist businesses in reaching their full potential by creating a strong online presence for them.
            </p>

            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>


            <h1 className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mt-10">
                Our <span className="text-[#0652dd]">AR VR App</span> Development Process
            </h1>
            <p className="text-base md:text-lg text-center mt-4 leading-relaxed max-w-3xl mx-auto px-4">
                The process we follow to create seamless AR/VR products is the same one we have used to develop countless other
                successful products over the years. We use an agile methodology to build error-free digital products faster while
                ensuring they meet your business needs.
            </p>

            <div className="bg-blue-700 w-3/4 h-auto mx-auto text-white text-center p-5 rounded-lg mt-10">
                <h3 className="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p className="text-lg pt-4">
                    On average, website development costs can vary from $10,000 to $150,000 based on the type of
                    website, design, and web developer costs.</p>
                <div>
                    <button onClick={handleClick} className="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>


            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center gap-9 mt-11 ">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg mb-[20px] ">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>

                    <h5 className="text-lg mb-[20px] flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Global quality standards.</h5>

                    <h5 className="text-lg mb-[20px] flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Monthly/yearly hiring.</h5>

                    <h5 className="text-lg mb-[20px] flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Flexible office hours depend on the timezone.</h5>

                    <h5 className="text-lg flex items-center gap-2  ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Daily/weekly/monthly reporting via email.</h5>
                </div>
                <div>

                    <div className="App">

                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap justify-center items-center mt-8 gap-6 p-6">
                {[
                    {
                        title: "AR/VR Experience 1",
                        imgSrc: "images2/ARVR3.jpeg",
                        desc: "Explore immersive AR/VR development that transforms your digital experience.",
                    },
                    {
                        title: "AR/VR Experience 2",
                        imgSrc: "images2/ARVR.jpeg",
                        desc: "Engage with cutting-edge AR/VR solutions for advanced digital interaction.",
                    },
                    {
                        title: "AR/VR Experience 3",
                        imgSrc: "images2/arvr1.jpg",
                        desc: "Take your business to the next level with immersive AR/VR development.",
                    },
                ].map((item, index) => (
                    <div
                        key={index}
                        className="w-80 h-96 bg-white rounded-xl shadow-2xl overflow-hidden transform transition-all hover:scale-105 duration-300 hover:shadow-3xl"
                    >
                        <img src={item.imgSrc} alt="AR/VR" className="w-full h-64 object-cover" />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold text-center text-gray-800">{item.title}</h3>
                            <p className="text-sm text-gray-600 mt-2 text-center">{item.desc}</p>
                        </div>
                    </div>
                ))}
            </div>

            <Footer />
        </div>
    )
}

export default ARVRappdevelopment
