import React, { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaThreads } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import Header from "../../components/layout/Header";
import ReCAPTCHA from "react-google-recaptcha";
import Contactusfooter from "./Contactusfooter";
import Contactusfqas from "./Contactusfqas";

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    selectedServices: [],
  });
  const recaptchaRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleServiceSelect = (service) => {
    setFormData((prev) => ({
      ...prev,
      selectedServices: prev.selectedServices.includes(service)
        ? prev.selectedServices.filter((item) => item !== service)
        : [...prev.selectedServices, service],
    }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      valid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
      valid = false;
    }
    if (!formData.description.trim()) {
      newErrors.description = "Project description is required.";
      valid = false;
    }
    if (!captchaValue) {
      toast.error("Please complete the reCAPTCHA.");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form Data:", formData); // Logs form data to the console
      toast.success("Form submitted successfully!");
      setFormData({ name: "", email: "", description: "", selectedServices: [] });
      setCaptchaValue(null);
    }
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    } else {
      toast.error("Please fill in all required fields.");
    }
  };


  return (
    <>
      <Header />
      <div className="lg:p-12 max-w-7xl mx-auto ">
        <div className="grid grid-cols-1 md:grid-cols-2 mt-10 justify-center items-start">
          <div className="space-y-6">
            <h2 className="text-[30px] sm:text-[36px] md:text-[60px] font-bold leading-[80px] text-gray-900">
              We’d Love To Collaborate!
            </h2>
            <div className="grid grid-cols-1 lg:gap-[12rem]">
              <div className="grid grid-cols-1 gap-[5rem] ">
                <p className="text-xl text-gray-600">Let us know what you’re after.</p>
                <div className="space-y-12">
                  <div className="flex items-center space-x-4">
                    <span className="text-2xl w-[50px] h-[50px] border p-3 rounded-full">
                      <FiPhoneCall />
                    </span>
                    <span className="text-lg font-medium text-gray-800">
                      <p className="text-[20px] text-[#202020] mb-1">Phone</p>
                      <p className="text-[#808080]">+91 88662 22169</p>
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-2xl w-[50px] h-[50px] border p-3 rounded-full">
                      <IoMailOutline />
                    </span>
                    <span className="text-lg font-medium text-gray-800">
                      <p className="text-[20px] text-[#202020] mb-1">Email</p>
                      <a href="mailto:info@bitfrontinfotech.com" className="text-[#808080]">
                        info@bitfrontinfotech.com
                      </a>
                    </span>
                  </div>

                </div>
              </div>

              {/* Social Icons */}
              <div className="flex mt-[80px] space-x-6 gap-[25px] text-gray-600 text-2xl">
                <button className="hover:text-blue-600">
                  <a href="https://www.linkedin.com/company/bit-front-tech/?originalSubdomain=in">
                    <FaLinkedinIn />
                  </a>
                </button>
                <button className="hover:text-pink-600">
                  <a href="https://www.instagram.com/bitfrontinfotech/">
                    <FaInstagram />
                  </a>
                </button>
                <button className="hover:text-blue-800">
                  <a href="https://www.facebook.com/bitfrontinfotech/">
                    <FaFacebookF />
                  </a>
                </button>
                <button className="hover:text-blue-400">
                  <a href="https://www.threads.net/@bitfront_infotech">
                    <FaThreads />
                  </a>
                </button>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div>
            <form onSubmit={handleSubmit} className="lg:space-y-6 lg:mt-0 mt-10 rounded-lg">
              <div className="space-y-4">
                {["name", "email", "description"].map((field) => (
                  <div key={field}>
                    <label htmlFor={field} className="block font-semibold text-[20px] text-gray-700 capitalize">
                      {field.replace(/([A-Z])/g, " $1")}
                    </label>
                    {field === "description" ? (
                      <textarea
                        id={field}
                        name={field}
                        placeholder="Enter here"
                        className={`w-full p-3 border-b focus:outline-none ${errors[field] ? "border-red-500" : "border-gray-300 focus:border-indigo-500"
                          }`}
                        value={formData[field]}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <input
                        type={field === "email" ? "email" : "text"}
                        id={field}
                        name={field}
                        placeholder="Enter here"
                        className={`w-full p-3 border-b focus:outline-none ${errors[field] ? "border-red-500" : "border-gray-300 focus:border-indigo-500"
                          }`}
                        value={formData[field]}
                        onChange={handleInputChange}
                      />
                    )}
                    {errors[field] && <span className="text-red-500 text-sm">{errors[field]}</span>}
                  </div>
                ))}
              </div>

              {/* Service Selection */}
              <div>
                <p className="font-semibold text-[20px] pb-2 text-gray-700">Select Services</p>
                <div className="flex flex-wrap gap-3">
                  {[
                    "Website Design",
                    "Marketing Design",
                    "Custom Website Design",
                    "Landing Page Design",
                    "UI/UX Design",
                    "Website Redesign",
                    "Other Services",
                  ].map((service) => (
                    <button
                      type="button"
                      key={service}
                      onClick={() => handleServiceSelect(service)}
                      className={`px-4 py-2 border rounded-full transition-all duration-200 ${formData.selectedServices.includes(service)
                        ? "bg-black text-white"
                        : "bg-gray-100 hover:bg-gray-200"
                        }`}
                    >
                      {service}
                    </button>
                  ))}
                </div>
              </div>

              {/* reCAPTCHA */}
              <div className="p-4 flex justify-start">
                <ReCAPTCHA
                  ref={recaptchaRef} // Attach ref to ReCAPTCHA
                  sitekey="6Lc6YO0qAAAAAOTpP9Ddgo_Ifsg_IUqkXHZlDMVH"
                  onChange={setCaptchaValue}
                />
              </div>

              <button type="submit" className="w-[200px] bg-[#202020] text-white py-3 rounded-[20px] text-lg font-semibold">
                Submit
              </button>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
      <Contactusfqas />
      <Contactusfooter />
    </>
  );
};

export default Contactus;
