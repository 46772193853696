import React from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import Architecture from '../../assets/images/Architecture.png'
import ArchitectConsultant from '../../assets/images/ArchitectConsultant.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import architizer from '../../assets/images/architizer.jpg'
import ScrollButton from '../../components/scrollb/ScrollButton';
const ArchitectureService = () => {
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const FeatureItem = ({ iconPath, text }) => (
        <h5 className="mb-[20px] text-lg flex items-center gap-2">
            <svg
                stroke="currentColor"
                fill="currentColor"
                viewBox="0 0 1024 1024"
                className="bg-blue-300 rounded-full w-9 h-8 text-blue-600 pt-3 text-center"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d={iconPath}></path>
            </svg>
            {text}
        </h5>
    );
    const features = [
        { iconPath: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z", text: "Agile Methodology" },
        { iconPath: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z", text: "Dedicated Project Manager" },
        { iconPath: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z", text: "Personalized Consulting" },
        { iconPath: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z", text: "Accurate Results" },
        { iconPath: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z", text: "Comprehensive Solutions" },
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="container mx-auto my-5 relative flex justify-center">
                <img
                    alt="Architecture"
                    className="rounded-[25px] w-full max-w-[1300px] h-[470px] object-cover"
                    src={Architecture}
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <h2 className="text-white xl:text-[40px] lg:text-[30px] md:text-[20px] text-[18px] font-semibold">
                        Architecture as a Service - AaaS
                    </h2>
                    <p className="font-medium text-white mx-auto xl:w-[619px]">
                        We specialize in software architecture consulting and development services customized to help businesses achieve their software development objectives.
                    </p>
                    <div className="flex justify-center mt-10">
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] h-[42px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>
            <div className="container mx-auto px-4 mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                    <div className="text-center md:text-left">
                        <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight">
                            Enterprise <span className="text-[#0652dd]">Architecture as a Service</span> | Software Architect Consultant
                        </h1>
                        <img
                            alt="Architect Consultant"
                            className="w-full max-w-md md:max-w-lg lg:max-w-xl mx-auto md:mx-0 mt-6"
                            src={ArchitectConsultant}
                        />
                    </div>
                    <div>
                        <p className="mb-6 text-base md:text-lg leading-relaxed font-medium">
                            BitFront, your Architecture as a Service - Solution Architects Consultant, will assess your needs and provide
                            a comprehensive evaluation of your architecture, offering the most suitable framework to align with your
                            business goals. In software development, architecture refers to the fundamental structures developers use when
                            designing software systems.
                        </p>
                        <p className="mb-6 text-base md:text-lg leading-relaxed font-medium">
                            In this type of design approach, services are independent of one another. This can make them easier to update
                            and change without interfering with the functionality of other services. Our team of experts comes with years
                            of experience in information technology, where they work together to assess the different domains of
                            architecture to shape solutions and define architecture. Develop future-ready software solution architecture to
                            guide custom software implementation and integration with multiple systems and maximize scalability and
                            efficiency.
                        </p>
                        <p className="text-base md:text-lg leading-relaxed font-medium">
                            As a <b>software architecture consultant</b>, I can bring your startup idea to life and improve your
                            enterprise's technology. At BitFront, we offer a full range of software architecture consulting services
                            covering <b>design architecture solutions from scratch</b>, as well as assessments and audits of your current
                            architecture.
                        </p>
                    </div>
                </div>
            </div>


            <div className="container mx-auto px-4 mt-10">
                <h1 className="text-2xl md:text-3xl text-center font-semibold leading-tight">
                    Our Software <span className="text-[#0652dd]">Architecture Consulting</span> Services
                </h1>
                <p className="max-w-4xl mx-auto text-center text-base md:text-lg font-medium mt-4">
                    Our software development services help combine frameworks, language, design, and feature requirements to create strong tech assets.
                    These assets are built solidly right from the start. Experience service offerings like:
                </p>

                <img
                    alt="triveniimg"
                    className="w-full max-w-5xl mx-auto my-6 h-auto md:h-[400px] lg:h-[500px] object-cover rounded-lg shadow-lg"
                    src={architizer}
                />

                <h2 className="text-lg md:text-xl lg:text-2xl max-w-4xl mx-auto text-justify font-semibold leading-relaxed">
                    Generate ideas and improve designs, analysis, and adaptability with absolute consulting services. These services evaluate
                    and then enhance existing systems to meet current needs.
                </h2>
            </div>



            <h1 className="text-[32px] font-semibold text-center mt-15 mb-[20px] ">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>

            <p className="text-lg text-center xl:w-[1300px] m-auto ">
                At BitFront, we specialize in providing software architecture consulting services in India to a diverse range of industries.
                With a deep understanding of each industry’s unique needs, we help our clients achieve their business goals through innovative
                and reliable software architecture consulting solutions.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>
            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Software Architecture</span> Consulting Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 xl:w-[1240px]">
                Our Software Architecture Consulting services guide you through the entire engineering process, from concept to post-implementation support.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[207px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">What is the cost of Software Architect Consulting Services?</h1>
                <p className="text-[18px] text-[#fff]">
                    Software architect consulting rates can range from $300 to $1000 (per hour) & more, depending on the size and complexity
                    of your software system. If you need enterprise software with multiple features and rich functionality, then the cost of
                    architecture consulting will automatically rise as lots of components need to be incorporated. Thus, depending on the
                    project scale and complexity, the rates will change.
                </p>
            </div>

            <section className="h-auto border-[#c4d9ff] border-b-[2px] border-t-[2px] m-auto mt-[40px]">
                <h1 className="text-center text-4xl mt-12 font-bold">
                    <span className="text-[#0652dd]">Why</span> Choose BitFront?
                </h1>
                <div className="flex flex-wrap justify-center gap-9 mt-11">
                    <div>
                        <h1 className="text-3xl font-bold">
                            <span className="text-[#0652dd]">Hire the Team</span> That Understands How<br />
                            <span className="text-[#0652dd]">Digital Success Works</span>
                        </h1>
                        <p className="mb-[40px] text-lg xl:w-[700px]">
                            At BitFront, we provide modern software solutions tailored to your business and customer requirements.
                            Our expertise ensures smooth operation and flexibility in transforming existing software. We guarantee
                            seamless system performance through all changes.
                        </p>
                    </div>
                    <div>
                        {features.map((feature, index) => (
                            <FeatureItem key={index} iconPath={feature.iconPath} text={feature.text} />
                        ))}
                    </div>
                </div>
            </section>
            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ArchitectureService
