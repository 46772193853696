import React, { useState } from 'react';
import Header from '../../components/layout/Header';
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import Retail from '../../assets/images/Retail.png';
import BusinessManagement from '../../assets/images/BusinessManagement.png';
import CustomeCommerce from '../../assets/images/CustomeCommerce.png';
import Marketplace from '../../assets/images/Marketplace.png';
import csc from '../../assets/images/csc.png';
import multistoreicon from '../../assets/images/multistoreicon.png';
import OmnichannelIntegration from '../../assets/images/OmnichannelIntegration.png';
import DigitalMarketingTools from '../../assets/images/DigitalMarketingTools.png';
import OnlineOrderingDelivering from '../../assets/images/OnlineOrderingDelivering.png';
import MobileWalletDevelopment from '../../assets/images/MobileWalletDevelopment.png';
import OnlineRetailStore from '../../assets/images/OnlineRetailStore.png';
import LoyaltyRewardsApps from '../../assets/images/LoyaltyRewardsApps.png';
import AnalyticsandReporting from '../../assets/images/AnalyticsandReporting.png';
import ShopifyDevelopment from '../../assets/images/ShopifyDevelopment.svg';
import OnlineMarketplaces from '../../assets/images/OnlineMarketplaces.png';
import WebPortals from '../../assets/images/WebPortals.png';
import ProgressiveWebApps from '../../assets/images/ProgressiveWebApps.png';
import DedicatedAppDevelopers from '../../assets/images/DedicatedAppDevelopers.png'
import FullySecuredSolution from '../../assets/images/FullySecuredSolution.png';
import CosteffectiveSolutions from '../../assets/images/CosteffectiveSolutions.png';
import FlexibleEngagementModels from '../../assets/images/FlexibleEngagementModels.png';
import ScrollButton from '../../components/scrollb/ScrollButton';

const Ecommerce = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "Why choose BitFront for mobile app development services?",
            answer:
                "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
            open: false
        },
        {
            question: "What is the cost of mobile app development?",
            answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
            open: false
        },
        {
            question:
                "How to choose a mobile app development platform?",
            answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
            open: false
        },
        {
            question:
                "How much time does custom mobile app development take?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "What solutions are provided by your mobile app development services?",
            answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
            open: false
        },
        {
            question:
                "What is the difference between cross-platform app development and native mobile app development?",
            answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
            open: false
        },
        {
            question:
                "Will I get post-mobile app development support?",
            answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };


    const ServiceCard = ({ icon, title, description }) => (
        <div className="mt-5 w-full max-w-[440px] h-auto xl:h-[240px] border-[2px] rounded-[10px]">
            <div className="p-[30px] flex gap-[1rem]">
                <img alt={title} className="w-[40px] h-[40px]" src={icon} />
                <div>
                    <h1 className="text-[20px] font-semibold">{title}</h1>
                    <p className="text-[18px] font-medium">{description}</p>
                </div>
            </div>
        </div>
    );
    const services = [
        {
            icon: multistoreicon,
            title: "Online Multistore",
            description: "Expand your online shop with our multi-store solution. Effortlessly manage multiple languages and product lists.",
        },
        {
            icon: OmnichannelIntegration,
            title: "Omni-channel Integration",
            description: "Omni-channel integration links online stores, physical retail sites, and mobile apps.",
        },
        {
            icon: DigitalMarketingTools,
            title: "Digital Marketing Tools",
            description: "We promote products and services online through email marketing, social media marketing, SEO, and PPC advertising.",
        },
        {
            icon: OnlineOrderingDelivering,
            title: "Online Ordering/Delivering",
            description: "Manage inventory, shipping, multi-currency payments, and order tracking seamlessly across various delivery channels.",
        },
        {
            icon: MobileWalletDevelopment,
            title: "Mobile Wallet Development",
            description: "Our e-commerce app developers integrate secure wallets to track account balances, transactions, expenses, and portfolios.",
        },
        {
            icon: OnlineRetailStore,
            title: "Online Retail Store",
            description: "Reach your target audience with an online retail store & provide a seamless shopping experience.",
        },
        {
            icon: LoyaltyRewardsApps,
            title: "Loyalty & Rewards Apps",
            description: "We prioritize client success, crafting custom loyalty & reward apps for top brands.",
        },
        {
            icon: AnalyticsandReporting,
            title: "Analytics and Reporting",
            description: "Software offering insights into sales, customer behavior, and key metrics, guiding data-driven decisions for retailers.",
        },
        {
            icon: ShopifyDevelopment,
            title: "Shopify Development",
            description: "Utilize Shopify for building digital stores with immersive templates, endless customization, and high performance.",
        },
        {
            icon: OnlineMarketplaces,
            title: "Online Marketplaces",
            description: "We can create online marketplaces for selling products and services, with options for different ways sellers and buyers interact.",
        },
        {
            icon: WebPortals,
            title: "Web Portals",
            description: "Our web portals for retail businesses assist in vendor management, customer communication, brand strengthening, and showcasing product lists.",
        },
        {
            icon: ProgressiveWebApps,
            title: "PWA (Progressive Web Apps)",
            description: "We recommend Progressive Web Apps (PWAs) for a single app serving both web and mobile users, optimized for different screen sizes.",
        },
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Retail & ECommerce</span> App Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            BitFront's dedicated developers excel in providing retail businesses with the
                            futuristic retail & eCommerce app development services. Our focus is on increasing
                            retail conversions and improving market reaches.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-4 space-y-2 text-gray-700">
                        <li>✅ Agile Development</li>
                        <li>✅ 100% NDA Protected</li>
                        <li>✅ Powerful Integrations</li>
                        <li>✅ 100% Scalable solutions</li>
                        <li>✅ User-centric Design</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='Retail' className="w-full md:w-[526px] h-auto md:h-[556px] mt-8 md:mt-0 mx-auto" src={Retail} />
                </div>
            </div>
            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center gap-0 sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#43b37b] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='BusinessManagement' className="w-[30px] h-[30px]" src={BusinessManagement} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Retail Business Management Software Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Develop a comprehensive ERP system for your enterprise-grade retail business to manage daily operations effortlessly. Handle everything
                        from stock management and sales inquiries to payment transactions and customer support.
                    </p>
                </div>
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#4366B3] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='CustomeCommerce' className="w-[30px] h-[30px]" src={CustomeCommerce} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Custom eCommerce App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Elevate your shopping journey with a captivating mobile app. BitFront provides comprehensive eCommerce app development solutions tailored
                        to various stores and platforms. Let's collaborate to craft richly featured mobile apps for your enterprise.
                    </p>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center gap-0 sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b38c43] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Marketplace' className="w-[30px] h-[30px]" src={Marketplace} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Marketplace & E-Commerce Solutions</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We offer creative marketplace and e-commerce solutions to boost your customer retention. With our mobile and software development services, we help you
                        reach your target market and stay visible. Our platform connects customers, suppliers, and manufacturers.
                    </p>
                </div>
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#ac43b3] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='csc' className="w-[30px] h-[30px]" src={csc} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">C2C eCommerce App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We create an online platform where you can buy and sell items. Our app has features like bargaining, multilingual
                        support, and online payments. It also lets customers sell their own stuff to other customers.
                    </p>
                </div>
            </div>
            <h1 className=" mt-[40px] xl:text-[35px] lg:text-[25px] md:text-[20px] text-[16px] font-semibold text-center"><span className="text-[#0652dd]">Retail & e-Commerce</span> Solutions</h1>
            <p className=" m-auto font-medium text-center w-full xl:w-[1350px]">
                In addition to providing high-quality retail & eCommerce development services, we assist businesses of all sizes in enhancing customer
                satisfaction and making a mark in the competitive market. Explore our retail & eCommerce app development solutions.
            </p>
            <div className="flex flex-wrap justify-center gap-[1rem]">
                {services.map((service, index) => (
                    <ServiceCard key={index} {...service} />
                ))}
            </div>

            <h1 className="mt-10 text-center text-[38px] font-semibold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?
            </h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>
                <div>
                    <div className="flex flex-wrap xl:gap-[5rem]">
                        <div>
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt='Dedicated App Developers' className="w-[30px] h-[30px]" src={DedicatedAppDevelopers} />
                                <h1 className="text-[18px] font-medium transition duration-200 ease-in-out hover:text-[#0652dd]">Dedicated App Developers</h1>
                            </div>
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt='Fully Secured Solution' className="w-[30px] h-[30px]" src={FullySecuredSolution} />
                                <h1 className="text-[18px] font-medium transition duration-200 ease-in-out hover:text-[#0652dd]">Fully Secured Solution</h1>
                            </div>
                        </div>
                        <div>
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt='Cost-effective Solutions' className="w-[30px] h-[30px]" src={CosteffectiveSolutions} />
                                <h1 className="text-[18px] font-medium transition duration-200 ease-in-out hover:text-[#0652dd]">Cost-effective Solutions</h1>
                            </div>
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt='Flexible Engagement Models' className="w-[30px] h-[30px]" src={FlexibleEngagementModels} />
                                <h1 className="text-[18px] font-medium transition duration-200 ease-in-out hover:text-[#0652dd]">Flexible Engagement Models</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] xl:w-[1320px] w-full xl:h-[147px] h-auto border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">NEED SOFTWARE DEVELOPMENT FOR FINANCIAL SERVICES?</h1>
                <p className="text-center text-[19px] font-medium text-[#fff]">BitFront will help in creating a secure and efficient solution, optimizing business logic for new revenue streams.</p>
            </div>

            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] text-center font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Ecommerce
