import { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

const faqs = [
    {
        question: "What are the services that Bitfront Infotech provides?",
        answer: "Bitfront Infotech provides the following services: User Research, Usability Testing, UX Design, UI Design, MVP Prototyping and Branding.",
    },
    {
        question: "What domains does Bitfront Infotech have expertise in?",
        answer: "Bitfront Infotech has worked in various domains like Fintech, Healthcare, Edutech, IoT, ERP's, SaaS, Wellness, Gaming, Agritech, Artificial Intelligence etc.",
    },
    {
        question: "What is UX Research?",
        answer: "UX research is the process of gathering and analyzing data about user behavior and needs to inform the design of user-centered products and services.",
    },
    {
        question: "Does Bitfront Infotech create Design Systems?",
        answer: "Yes, Bitfront Infotech creates Design Systems. Our team of UI/UX designers specializes in developing custom Design Systems that ensure consistency, enhance user experience, and streamline development processes for our clients.",
    },
    {
        question: "What are the final deliverables at Bitfront Infotech?",
        answer: "We deliver wireframes, prototypes, UI kits, and complete design documentation.",
    },
    //   {
    //     question: "What are the final deliverables at Bitfront Infotech?",
    //     answer: "The final deliverables in a UI/UX design project typically include wireframes, high-fidelity mockups or prototypes, a design system or style guide, and design specifications or documentation. These deliverables provide a comprehensive overview of the design, including visual design elements, interactions, and functionality, and serve as a guide for developers during the implementation phase. In addition, a UI/UX design project may also include usability testing reports, research findings, and user personas, which help inform design decisions and ensure that the final product meets the needs of its target users.",
    //   },
];

export default function Contactusfqas() {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="max-w-[996px] mx-auto p-4 mb-10 sm:p-6 lg:p-8">
            <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6 text-[#222222]">
                Frequently Asked Questions
            </h2>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border-b border-gray-300">
                        <button
                            className="w-full flex justify-between items-center py-3 sm:py-4 text-base sm:text-lg font-medium text-left"
                            onClick={() => toggleFAQ(index)}
                        >
                            <span className="text-[#222222]">{faq.question}</span>
                            <span
                                className={`w-6 h-6 sm:w-7 sm:h-7 flex items-center justify-center border-gray-400 border-2 rounded-lg transition-transform duration-50 ${openIndex === index ? "rotate-180" : "rotate-0"
                                    }`}
                            >
                                {openIndex === index ? (
                                    <FiMinus className="text-gray-400" />
                                ) : (
                                    <FiPlus className="text-gray-400" />
                                )}
                            </span>
                        </button>
                        {openIndex === index && (
                            <p className="px-2 pb-3 sm:pb-4 text-sm sm:text-base text-[#808080] leading-relaxed">
                                {faq.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
