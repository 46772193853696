import './Home.css';
// import { FaStar } from "react-icons/fa6";
// import { FaArrowCircleRight } from "react-icons/fa";
import React, { useEffect, useRef, useState } from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import { FaAngleRight } from "react-icons/fa";
import WAD from "./Tabchangep/WAD";
import CSD from "./Tabchangep/CSD";
import MAD from "./Tabchangep/MAD";
import SAM from "./Tabchangep/SAM";
import QAT from "./Tabchangep/QAT";
import AIML from "./Tabchangep/AIML";
import Cloud from "./Tabchangep/Cloud";
import DevOps from "./Tabchangep/DevOps";
import Staff from "./Tabchangep/Staff";
import Healthcare from '../assets/images/Healthcare.png'
import FintechSolutions from '../assets/images/FintechSolutions.png';
import EdutechSolutions from '../assets/images/EdutechSolutions.png';
import EcommerceSolutions from '../assets/images/EcommerceSolutions.png';
import EntertainmentWebSolutions from '../assets/images/EntertainmentWebSolutions.png';
import elearning12 from '../assets/images/elearning12.png';
import ScrollButton from "../components/scrollb/ScrollButton";
import Header from "../components/layout/Header";
import AOS from 'aos';
import Chatbot from "../components/chatbot/Chatbot";
import homemobile from "../assets/images/homemobile.svg";
import homewebdevelopment from "../assets/images/homewebdevelopment.jpg";
import homeios from "../assets/images/homeios.svg";
import homeflutter from "../assets/images/homeflutter.svg";
import { motion } from "framer-motion";
function App() {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { label: 'Custom Software Development ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/Custom-Software-Development.svg' },
    { label: ' Web Application Development ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/Web-Application-Development.svg' },
    { label: ' Mobile App Development  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/Mobile-App-Development.svg' },
    { label: ' Support and Maintenance  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/Support-and-Maintenance.svg' },
    { label: ' QA & Testing  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/QA-and-Testing.svg' },
    { label: ' AIML  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/AIML.svg' },
    { label: ' Cloud  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/Cloud.svg' },
    { label: ' DevOps  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/09/Devops.svg' },
    { label: ' Staff Augmentation  ', src: 'https://www.narolainfotech.com/wp-content/uploads/2022/10/Staff-Augmentation.svg' },

  ];

  const handleTabChange = (index) => {
    console.log(index, "indexindexindex");
    setActiveTab(index);
  };
  const texts = [
    "Ecommerce",
    "Fintech",
    "Healthcare",
    "Real Estate"
  ];
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/about');
  };


  const handleClickContactus = () => {
    navigate('/Contact-us');
  };

  const handleClickiFlutter = () => {
    navigate('/Flutter');
  };

  const handleClickmo = () => {
    navigate('/Mobile');
  };

  const handleClickmoa = () => {
    navigate('/Android-App-Development');
  };

  const handleClickiPhone = () => {
    navigate('/Phone');
  };

  const [faqs, setFaqs] = useState([
    {
      question: "Custom-Based Solutions",
      answer:
        "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
      open: false
    },
    {
      question: "Industrial Experts",
      answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
      open: false
    },
    {
      question: "Seamless Communication",
      answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
      open: false
    },
    {
      question: "Latest Technology",
      answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
      open: false
    },

  ]);
  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  // const cardData = [
  //   {
  //     imgSrc: developmentImage,
  //     altText: "right arrow icon",
  //     value: "91%",
  //     description: "Returning client base"
  //   },
  //   {
  //     imgSrc: Launched,
  //     altText: "right arrow icon",
  //     value: "100+",
  //     description: "Project Launched"
  //   },
  //   {
  //     imgSrc: Happy,
  //     altText: "right arrow icon",
  //     value: "80+",
  //     description: "Happy Clients"
  //   },
  //   {
  //     imgSrc: countryserved,
  //     altText: "right arrow icon",
  //     value: "18+",
  //     description: "Countries Served"
  //   }
  // ];

  const data = [
    {
      src: homemobile,
      title: 'Mobile App Development',
      onClick: handleClickmo,
    },
    {
      src: homewebdevelopment,
      title: 'Web App Development',
      onClick: handleClickmoa,
    },

    {
      src: homeios,
      title: 'IOS App Development',
      onClick: handleClickiPhone,
    },
    {
      src: homeflutter,
      title: 'Flutter App Development',
      onClick: handleClickiFlutter,
    },
  ];

  const gradients = [
    'bg-gray-700',
    'bg-green-900',
    'bg-blue-800',
    'bg-pink-800',
  ];

  const technologyCard = [
    {
      src: Healthcare,
      title: 'Healthcare',
      list: [
        'Fitness App Development',
        'EHR Software Development',
        'EMR Software Development',
        'Telemedicine App Development',
        'Practice Management Systems',
        'Clinical Software Solutions',
        'Medical Device Integration Solutions',
        'HIPAA Compliant Software Development',
      ],
    },
    {
      src: FintechSolutions,
      title: 'Wealth Management App Development',
      list: [
        'Financial Software Development',
        'Banking App Development',
        'Cryptocurrency Wallet Development',
        'Stock Trading Platform Development',
        'Financial Web Portal Development',
        'Loan Lending App Development',
        'Investment App Development',
      ],
    },
    {
      src: EdutechSolutions,
      title: 'Edutech Solutions',
      list: [
        'eLearning App Development',
        'eLearning Web App Development',
        'Education App Development',
        'E2C eLearning App Development',
        'Virtual Classrooms Video Conferencing',
        'Learning Management System (LMS)',
        'Online Exam Management System',
        'School Management Software Solutions',
      ],
    },
    {
      src: EcommerceSolutions,
      title: 'Ecommerce Solutions',
      list: [
        'eCommerce App Development',
        'eCommerce Web App Development',
        'Custom eCommerce Store Development',
        'Shipping and Fulfillment Solution',
        'Grocery E-Commerce App Development',
        'End to End eCommerce Marketplace',
        'AR-based eCommerce Solutions',
      ],
    },
    {
      src: EntertainmentWebSolutions,
      title: 'Entertainment Web Solutions',
      list: [
        'Video Streaming Solutions',
        'Storyboarding & Collaboration Solutions',
        'Live Event Ticketing Platforms',
        'ML Solution for Large Data',
        'VFX Solutions',
        'Entertainment App Development',
        'Entertainment Web App Development',
      ],
    },

    {
      src: elearning12,
      title: 'E-learning',
      list: [
        // 'Interactive Learning Management Systems (LMS)',
        'Custom E-learning Content Development',
        'Virtual Classroom Platforms',
        'Gamified Learning Solutions',
        'AI-Powered Adaptive Learning Systems',
        'Mobile Learning App Development',
        'E-learning Web Platform Development',
        'Online Assessment and Certification Tools'
      ]
    }

  ];
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // }, []);

  // const letters = "BITFRONT INFOTECH".split("");
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const targetValue = 2345;
  const [count, setCount] = useState(0);
  const countRef = useRef(null);
  const hasStartedCounting = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasStartedCounting.current) {
          hasStartedCounting.current = true;
          startCounting();
          observer.disconnect();
        }
      });
    });

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  const startCounting = () => {
    const interval = setInterval(() => {
      setCount(prevCount => {
        if (prevCount < targetValue) {
          return Math.min(prevCount + 20, targetValue);
        } else {
          clearInterval(interval);
          return prevCount;
        }
      });
    }, 20);
  };
  return (
    <div>
      <Header />
      <Chatbot />
      <ScrollButton />
      <img
        src="images2/backimages.jpg"
        alt="img"
        className=" w-[100%] mx-auto h-[950px] mt-[-110px] absolute "
      />
      <div className="container relative mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <div className=" m-auto pt-0.5 pb-0 px-5 rounded-[25px] h-[500px] mt-5 flex justify-center">
            <div className="grid lg:grid-cols-2 grid-cols-1 justify-between items-center">
              <div className="border-l-[3px] border-[#f28034] pl-[10px]">
                <h1 className="text-[#f28034] text-[25px] font-bold">IT Company</h1>
                <h1 className=" xl:text-[40px] lg:text-[40px] md:text-[30px] text-[20px] relative sm:leading-[59px] font-bold border-l-[3px] border-[#f28034] pl-[10px] text-[#000000]">Software Development Company
                  <div className="text-container text-[#f28034] md:mt-[-54px] lg:ml-[196px] md:ml-[490px] lg:mt-[-59px]">
                    <div className="text-content font-extrabold">
                      {texts.map((text, index) => (
                        <div key={index} className="text-item">
                          {text}
                        </div>
                      ))}
                    </div>
                  </div>
                </h1>
                <p className="text-base border-l-[3px] border-[#f28034] pl-[10px] sm:my-[30px] relative font-normal text-[#000000] leading-[30px] pr-5">
                  We specialize in developing custom software...
                </p>
                <div>
                  <button onClick={() => navigate("/contact-us")} className="p-2.5 relative leading-[20px] text-[15px] bg-[#0652dd] text-white rounded-full border border-[#0652dd] cursor-pointer transition-all duration-100 hover:bg-white hover:text-[#0652dd] uppercase">
                    <b>CONTACT US</b>
                  </button>
                </div>
              </div>
              <div>
                <img className="md:w-[471px] md:h-[464px] md:ml-[50px]" src="/images2/bitech.png" alt="img" />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="relative mb-[20px] container mx-auto lg:mt-5 sm:mt-[30%] mt-[23%] py-10 xl:px-0 px-5">
          <h1
            className="text-center text-[22px] leading-10 mb-[50px] sm:my-0">
            Over <span className="text-[#0652dd]">10 startups,
            </span> brands and enterprises worldwide have trusted BitFront since 2020</h1>
          <div className="xl:w-[920px] h-auto m-auto ">
            <div className="flex flex-wrap gap-[3rem] justify-center xl:ml-[10px] xl:mb-[40px] items-center">
              <img className="w-[150px] h-[30px] md:w-[196px] md:h-[38px] cursor-pointer" src="/images2/9p.jpg" alt="img" />
              <img className="w-[120px] h-[30px] md:w-[146px] md:h-[38px] cursor-pointer" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" alt="img" />
              <img className="w-[150px] h-[40px] md:w-[200px] md:h-[70px] cursor-pointer" alt="media" src="images2/aurumlive.png" />
              <img className="w-[100px] h-[30px] md:w-auto cursor-pointer" alt="14p.jpg" src="/images2/14p.jpg" />
            </div>
            <div className="flex flex-wrap gap-[4rem] justify-center sm:mt-0 mt-[50px] xl:ml-[20px] items-center">
              <img className="w-[120px] h-[40px] md:w-[150px] md:h-[50px] cursor-pointer" alt="abroadworks" src="https://api.abroadworks.com/media/logo-white.png" />
              <img className="w-[80px] h-[50px] md:w-[110px] md:h-[70px] cursor-pointer" alt="tirerobot" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
              <img className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] xl:ml-[50px] cursor-pointer" alt="30mins" src="https://30mins.com/assets/logo.svg" />
              {/* <img className="w-[80px] h-[80px] md:w-[90px] md:h-[90px] cursor-pointer" alt="lifecell" src="https://www.pockethrms.com/wp-content/uploads/2024/05/cropped-Pocket-HRMS-Logo-2048x2048.png" /> */}
            </div>
          </div>
        </div>
      </motion.div>
      <div data-aos="zoom-in" className=" mb-[10px] mt-[0px] container mx-auto h-[594] xl:px-0 px-5">
        <h1
          className="xl:text-[40px] lg:text-[30px] md:text-[30px] text-[16px] font-bold text-center mb-[50px] leading-[50px]">
          Services We Offer
        </h1>
        <div className="flex flex-col sm:flex-row gap-[75px]">
          <div className="block sm:hidden">
            <select
              onChange={(e) => handleTabChange(Number(e.target.value))}
              className="w-full p-3 border rounded-md text-gray-700"
            >
              {tabs.map((tab, index) => (
                <option value={index} key={index}>
                  {tab.label}
                </option>
              ))}
            </select>
          </div>
          <div className="w-1/3 flex-[0_0_33.33%] hidden sm:block">
            {tabs.map((tab, index) => (
              <div
                onClick={() => handleTabChange(index)}
                key={index}
                className={`hover:text-[#0652dd] hover:font-semibold w-[400px] lg:w-[400px] sm:w-[291px] lg:p-[10px] lg:px-[15px] sm:p-[10px] xl:py-[11px] xl:px-[25px] cursor-pointer ${activeTab === index
                  ? 'bg-[#2490ef0d] border-l-8 border-[#0084FF] text-[#0652dd] font-semibold'
                  : 'border-l-8 border-transparent font-normal text-[#4C5A67]'
                  } flex items-center justify-between`}
              >
                <div className="flex items-center gap-3 leading-[44px]">
                  <img alt="tabsrc" className="w-[20px] h-[20px]" src={tab.src} />
                  <h1 className="xl:text-[17px] lg:text-[17px] md:text-[14px] text-[16px]">
                    {tab.label}
                  </h1>
                </div>
                <FaAngleRight className="xl:text-[17px] lg:text-[17px] md:text-[14px] text-[20px]" />
              </div>
            ))}
          </div>
          <div className="flex-1">
            {activeTab === 0 && <CSD />}
            {activeTab === 1 && <WAD />}
            {activeTab === 2 && <MAD />}
            {activeTab === 3 && <SAM />}
            {activeTab === 4 && <QAT />}
            {activeTab === 5 && <AIML />}
            {activeTab === 6 && <Cloud />}
            {activeTab === 7 && <DevOps />}
            {activeTab === 8 && <Staff />}
            <div
              className="bouncing-box bg-white w-[150px] h-fit rounded-md px-3 pb-4 pt-3 cardShadow relative border border-token-border-light ">
              <div className="flex flex-wrap justify-center items-center">
                <img src="images2/autocount.svg" alt="img" />
                <h1 ref={countRef} className="text-[20px] font-bold">
                  {count.toLocaleString()}+
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-12 px-6 py-10 items-center">
            <div>
              <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-[20px] text-[#0652dd] md:leading-[59px] leading-0 font-bold ">Software Development Company<span className="text-[#192734]"> Offering Digital Solutions</span></h1>
              <p className="text-base leading-[30px] text-[#4c5a67] font-normal mb-[30px]">
                As a Custom software development company, we offer a wide range of
                software development services to fulfill your specific business needs. With
                a wide experience in creating future-ready solutions powered by next-gen
                technologies, we have anlayzed & developed over 100+ digital products
                (Mobile App & Web App) for clients in more than 18 countries. Our well-
                versed & experienced professionals cover every aspect of the custom
                software development process to ensure customer satisfaction & efficiency.
              </p>
              <button onClick={handleClick} className="btn-01"><b>ABOUT US</b></button>
            </div>
            {/* {all cards} */}
            <div className="grid lg:grid-cols-2 xl:m-1 sm:grid-cols-2 gap-x-5 gap-y-16 justify-center">
              <div className="relative m-auto">
                <div className="absolute -bottom-4 xl:-right-4 xl:w-full w-64 h-28 border-[2px] border-[#000000] rounded-lg"></div>
                <div className="bg-gradient-to-b from-[#000000] via-[#202020] to-[#666666] text-white p-6 rounded-lg w-64 shadow-lg relative">
                  <div className="text-4xl mb-4">👤</div> {/* Replace with actual icon */}
                  <h3 className="text-xl font-bold">100% Dedicates Resources</h3>
                  <p className="text-sm mt-2">Enjoy the benefits of 100% dedicated resources!</p>
                </div>
              </div>
              <div className="relative m-auto">
                <div className="absolute -bottom-4 xl:-right-4 xl:w-full w-64 h-28 bg-black rounded-lg"></div>
                <div className="bg-[#7DFBB0] text-black p-6 rounded-lg w-64 shadow-lg relative">
                  <div className="text-4xl mb-4">⏳</div> {/* Replace with actual icon */}
                  <h3 className="text-xl font-bold">Flexible Hiring Model</h3>
                  <p className="text-sm mt-2">Choose our flexible hiring model to find the perfect remote developers!</p>
                </div>
              </div>
              <div className="relative m-auto">
                <div className="absolute -bottom-4 xl:-right-4 xl:w-full w-64 h-28 bg-black rounded-lg"></div>
                <div className="bg-yellow-200 text-black p-6 rounded-lg w-64 shadow-lg relative">
                  <div className="text-4xl mb-4">🎖️</div> {/* Replace with actual icon */}
                  <h3 className="text-xl font-bold">Experience Professionals</h3>
                  <p className="text-sm mt-2">Work with experienced professionals who bring a wealth of knowledge and skills.</p>
                </div>
              </div>
              <div className="relative m-auto">
                <div className="absolute -bottom-4 xl:-right-4 xl:w-full w-64 h-28 border-[2px] border-[#000000] rounded-lg"></div>
                <div className="bg-gradient-to-b from-[#032C77] via-[#0049d1] to-[#256FF5] text-white p-6 rounded-lg w-64 shadow-lg relative">
                  <div className="text-4xl mb-4">💼</div> {/* Replace with actual icon */}
                  <h3 className="text-xl font-bold">Fast Onboarding Process</h3>
                  <p className="text-sm mt-2">Experience a quick onboarding process to get our dedicated developers ready to work fast.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="container mx-auto px-6 pt-11 pb-16">
        <h1 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Empowering Your Business Outcomes with <span className="text-[#0652dd]">Our Software Development Services</span></h1>
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 items-center gap-8 mt-6">
          {data.map((item, index) => (
            <div
              key={index}
              className={`md:w-[500px] ${gradients[index % gradients.length]} rounded-lg m-auto shadow-lg p-6 transition-transform transform hover:scale-105 duration-300 cursor-pointer`}
            >
              <div className="flex items-center mb-4">
                <img
                  loading="lazy"
                  src={item.src}
                  alt="Profile"
                  className="h-16 w-16 rounded-full border-4 border-white shadow-md"
                  width="70"
                  height={50}
                />
                <h1 className="text-xl font-bold text-white ml-4">{item.title}</h1>
              </div>
              <p className="text-white mb-5">
                We are a trusted development company dedicated to delivering unforgettable experiences and glitch-free apps.
              </p>
              <button
                onClick={item.onClick}
                aria-label={`Read more about ${item.title}`}
                className="bg-white text-purple-600 font-semibold px-4 py-2 rounded-full shadow-md transition-colors duration-200 hover:bg-gray-200"
              >
                Read More
              </button>
            </div>
          ))}
        </div>
      </div>
      <div data-aos="zoom-in" className="text-center py-[50px]">
        <div className="container mx-auto">
          <h1 className="text-[40px] text-[#0652dd] leading-[59px] font-bold">Experts <span className="text-[#192734]">of in-trend Technologies</span></h1>
          <p className="text-base leading-[30px] text-[#4c5a67] font-normal">We power your software with a future-ready and time-tested technologystack
            - an incorporation that makes your product scalable and competitive.</p>
        </div>
      </div>
      <div className="container mx-auto py-14 px-14">
        <div className="gap-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          {technologyCard.map((item, index) => (
            <div data-aos="zoom-in" key={index}>
              <div className="hover:shadow-custom transition-all duration-200 p-5 lg:h-[350px] md:h-auto sm:h-auto rounded-[20px] bg-white border-[1px]">
                <div className="col-9 mb-2">
                  <img className=" md:w-[50px] md:h-[50px] w-[30px] h-[30px]" src={item.src} alt="img" />
                  <h3 className="font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] ">{item.title}</h3>
                </div>
                <span>
                  {item.list.map((listItem, listIndex) => (
                    <li key={listIndex} className="pl-3 text-wrap text-sm leading-[30px] text-[#4c5a67] font-normal">
                      {listItem}
                    </li>
                  ))}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div data-aos="zoom-in" className="container mx-auto bg-white px-3 rounded-[15px]">
        <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">How We Help in <span className="text-[#0652dd]">Custom Software Development?</span></h2>
        <p className="text-center mb-4 text-base leading-[30px] text-[#4c5a67] font-normal">
          We are a custom software development company that provides customized software solutions for web and mobile applications. We offer a wide range
          of end-to-end software development services specifically designed to help you meet your goals. Our software developers build custom software
          solutions and strictly follow business processes. Our team of experienced & dedicated developers will help scale your business infrastructure while
          improving the key facts of your organization at the same time.
        </p>
        <div className="pb-4 flex justify-center">
          <button className="w-[220px] h-[42px] font-bold pointer hover:bg-white hover:text-[#0652dd] rounded-full bg-[#0652dd] border border-[#0652dd] text-white px-4 py-2 text-[15px] transition-all duration-200">TALK TO OVER EXPERTS
          </button>
        </div>
      </div>
      <div className="header">
        <div data-aos="zoom-in" className="container mx-auto flex items-center mt-10 gap-[5rem]">
          <div className="w-full">
            <h1
              className=" xl:text-[40px] lg:text-[40px] md:text-[32px] text-[26px] text-[#0652dd] font-bold">
              Scalable software development<span className="text-[#192734]"> solutions that meet your business needs</span>
            </h1>
            <button onClick={handleClickContactus}
              className=" hover:bg-[#fff] font-medium hover:text-[#0652dd] w-[140px] h-[40px] bg-[#0652dd] text-white rounded-full border border-[#0652dd] cursor-pointer transition-all duration-100">
              CONTACT US
            </button>
          </div>
          <div className="w-full hidden md:block">
            <div className="faqs">
              {faqs.map((faq, index) => (
                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <h2 data-aos="zoom-in" className="container mx-auto my-[80px] lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">
        Our <span className="text-[#0652dd]">
          experience </span>with <span className="text-[#0652dd]">BitFront</span> has been truly beneficial</h2>
      <div className="for-flex container mx-auto">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 gap-[1rem] items-center justify-center">
          <img data-aos="zoom-in" className="w-full xl:max-w-[400px] max-w-[300px] h-full rounded-[15px]" alt="img" src="https://media.istockphoto.com/id/1413474071/vector/3d-isometric-flat-vector-conceptual-illustration-of-trust-level.jpg?s=612x612&w=0&k=20&c=bpPjn52gvQYZ02dFRqeWHw5-xd1J4LiVoEnOIuYcG_w=" />
          <img data-aos="zoom-in" className="w-full xl:max-w-[400px] max-w-[300px] h-full rounded-[15px]" alt="img" src="https://img.freepik.com/premium-vector/vote-measurement-customer-satisfaction-star-rating-satisfactory-rating-hand-shows-class_566886-1755.jpg" />
          <img data-aos="zoom-in" className="w-full xl:max-w-[400px] max-w-[300px] h-full rounded-[15px]" alt="img" src="https://media.istockphoto.com/id/1483280297/vector/3d-isometric-flat-vector-conceptual-illustration-of-teamwork-success.jpg?s=612x612&w=0&k=20&c=FJXcLVbQV2BzQab7y49J_ywrEj6U7-6RM33VQDZcSC8=" />
        </div>

      </div>

      <Footer />

    </div>
  );
}

export default App;
