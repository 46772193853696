import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import HybridApp from '../../assets/images/HybridApp.png';
import HybridAppDevelopmentServices from '../../assets/images/HybridAppDevelopmentServices.png';
import HybridAppConsulting from '../../assets/images/HybridAppConsulting.png';
import HybridAppUIUXDesign from '../../assets/images/HybridAppUIUXDesign.png';
import CustomHybridAppDevelopment from '../../assets/images/CustomHybridAppDevelopment.png';
// import HybridApplicationIntegration from '../../assets/images/HybridApplicationIntegration.png';
import CosteffectiveSolutions from '../../assets/images/CosteffectiveSolutions.png';
import ImprovedUserExperience from '../../assets/images/ImprovedUserExperience.png';
import LatestTechnology from '../../assets/images/LatestTechnology.png';
import OfflineAccess from '../../assets/images/OfflineAccess.png';
import EasyIntegration from '../../assets/images/EasyIntegration.png';
import FastSpeed from '../../assets/images/FastSpeed.png';
import AttractsTargetAudience from '../../assets/images/AttractsTargetAudience.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const HybridAppDevelopment = () => {


    const [faqs, setFaqs] = useState([
        {
            question: "Custom-Based Solutions",
            answer:
                "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
            open: false
        },
        {
            question: "Industrial Experts",
            answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
            open: false
        },
        {
            question: "Seamless Communication",
            answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
            open: false
        },
        {
            question: "Latest Technology",
            answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
            open: false
        },

    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Hybrid App</span> Development<br /> Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Our expert team builds scalable, strong and flexible hybrid mobile apps quickly
                            and efficiently. Deliver exceptional value with hybrid app development, blending
                            the best of both web and native apps.
                        </p>
                        <ul className="mt-4 space-y-2 text-gray-700">
                        <li>✅ Agile Hybrid app development</li>
                        <li>✅ Single codebase for all screen sizes</li>
                        <li>✅ Fast turnaround</li>
                        <li>✅ Flexible hiring model</li>
                        <li>✅ Strict NDA compliance</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                    <img alt='HybridApp' className="w-full md:w-[541px] h-auto md:h-[507px] mt-8 md:mt-0 mx-auto " src={HybridApp} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>

            <div className="w-full bg-gray-50 py-16">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center gap-10">
                    {/* Left Section */}
                    <div className="text-center lg:text-left max-w-2xl px-4">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-800">
                            <span className="text-blue-600">Hybrid App</span> Development<br /> Services We Offer
                        </h1>
                        <p className="text-lg md:text-xl mt-6 text-gray-600 leading-relaxed">
                            BitFront is a leading Hybrid App Development Company in India. We specialize in cross-platform solutions using React Native, Flutter, Ionic, and Xamarin.
                        </p>
                        <p className="text-lg md:text-xl mt-4 text-gray-600 leading-relaxed">
                            Our team of experts ensures seamless user experiences on both Android and iOS devices through efficient code reuse and rapid development. Using advanced tools and strong architectures, we create scalable and secure hybrid mobile apps tailored to your unique needs.
                        </p>
                        <img
                            alt="Hybrid App Development Services"
                            className="w-full md:w-[450px] h-auto mt-8 mx-auto lg:mx-0"
                            src={HybridAppDevelopmentServices}
                        />
                    </div>

                    {/* Right Section */}
                    <div className="flex flex-col gap-8 px-4">
                        {/* Card 1 */}
                        <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-200">
                            <div className="flex items-center gap-4">
                                <img
                                    alt="Hybrid App Consulting"
                                    className="w-12 h-12"
                                    src={HybridAppConsulting}
                                />
                                <h4 className="text-xl font-bold text-gray-800">Hybrid App Consulting</h4>
                            </div>
                            <p className="text-gray-600 mt-4 text-base leading-relaxed">
                                Our app experts work with you to create a customized hybrid mobile app strategy that matches your specific business objectives. Following that, our consultants suggest the perfect cross-platform technology stack and architectural approach.
                            </p>
                        </div>

                        {/* Card 2 */}
                        <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-200">
                            <div className="flex items-center gap-4">
                                <img
                                    alt="Hybrid App UI/UX Design"
                                    className="w-12 h-12"
                                    src={HybridAppUIUXDesign}
                                />
                                <h4 className="text-xl font-bold text-gray-800">Hybrid App UI/UX Design Services</h4>
                            </div>
                            <p className="text-gray-600 mt-4 text-base leading-relaxed">
                                Our hybrid mobile app UI/UX design services go beyond aesthetics; we prioritize creating designs that enhance user-friendliness and engagement. Our team ensures your hybrid app not only boasts visually appealing elements but also delivers a delightful user experience.
                            </p>
                        </div>

                        {/* Card 3 */}
                        <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-200">
                            <div className="flex items-center gap-4">
                                <img
                                    alt="Custom Hybrid App Development"
                                    className="w-12 h-12"
                                    src={CustomHybridAppDevelopment}
                                />
                                <h4 className="text-xl font-bold text-gray-800">Custom Hybrid App Development</h4>
                            </div>
                            <p className="text-gray-600 mt-4 text-base leading-relaxed">
                                We develop hybrid mobile apps that combine the best of native and web-based features. By using web technologies like HTML, CSS, and JavaScript, alongside native platform tools like Swift or Java, we create powerful and versatile applications.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className="text-[32px] font-bold text-center mt-[50px]"><span className="text-[#0652DD]">Benefits</span> of Hybrid App Development</h1>
            <p className="text-center text-[18px] ">
                Unlock the potential of hybrid apps. Benefit from lower costs, smooth cross-platform performance,
                and outstanding user experience with hybrid app development solutions.
            </p>
            <div className="flex flex-wrap justify-center gap-[20px]">
                <div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='CosteffectiveSolutions' className="w-[38px] h-[38px]" src={CosteffectiveSolutions} />
                            <div>
                                <h1 className="text-[20px] font-bold">Cost-Effectiveness</h1>
                                <p className="text-[18px]">
                                    Hybrid app development uses one codebase for all platforms, cutting costs and speeding up deployment.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='ImprovedUserExperience' className="w-[38px] h-[38px]" src={ImprovedUserExperience} />
                            <div>
                                <h1 className="text-[20px] font-bold">Improved User Experience</h1>
                                <p className="text-[18px]">
                                    Hybrid mobile apps provide a seamless, consistent experience across all devices, enhancing usability and maximizing user satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='LatestTechnology' className="w-[38px] h-[38px]" src={LatestTechnology} />
                            <div>
                                <h1 className="text-[20px] font-bold">Latest Technology</h1>
                                <p className="text-[18px]">
                                    Native apps work on specific platforms like Android or iOS, while hybrid mobile apps support multiple platforms and integrate APIs for added features.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='OfflineAccess' className="w-[38px] h-[38px]" src={OfflineAccess} />
                            <div>
                                <h1 className="text-[20px] font-bold">Offline Access</h1>
                                <p className="text-[18px]">
                                    Hybrid apps keep users engaged even without internet access, thanks to their online-offline switching ability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='EasyIntegration' className="w-[38px] h-[38px]" src={EasyIntegration} />
                            <div>
                                <h1 className="text-[20px] font-bold">Easy Integration</h1>
                                <p className="text-[18px]">
                                    Hybrid mobile apps seamlessly integrate with other apps, enhancing user experience and compatibility.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='FastSpeed' className="w-[38px] h-[38px]" src={FastSpeed} />
                            <div>
                                <h1 className="text-[20px] font-bold">Fast Speed</h1>
                                <p className="text-[18px]">
                                    Hybrid applications load quickly and function seamlessly, even without network support, ensuring efficient performance.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] md:w-[440px] w-full h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='AttractsTargetAudience' className="w-[38px] h-[38px]" src={AttractsTargetAudience} />
                            <div>
                                <h1 className="text-[20px] font-bold">Attracts Target Audience</h1>
                                <p className="text-[18px]">
                                    Hybrid apps leverage device operating systems for creative and attention-grabbing designs, inspiring developers to innovate and capture audience interest.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>



            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">
                <span className="text-[#0652dd]">Hybrid app</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                Discover our organized approach to hybrid app development. From ideation to launch, we handle every step to transform your ideas into digital reality.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>



            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center gap-9 mt-11 ">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                    <br />
                    <h5 className="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Global quality standards.</h5>
                    <br />
                    <h5 className="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Monthly/yearly hiring.</h5>
                    <br />
                    <h5 className="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Flexible office hours depend on the timezone.</h5>
                    <br />
                    <h5 className="text-lg flex items-center gap-2  ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Daily/weekly/monthly reporting via email.</h5>
                </div>
                <div>

                    <div className="App">

                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HybridAppDevelopment
