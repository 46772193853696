import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import EvCharging from '../../assets/images/EvCharging.png';
import CustomEV from '../../assets/images/CustomEV.png';
import Charging from '../../assets/images/Charging.png';
import EVBatteryManagement from '../../assets/images/EVBatteryManagement.png';
import EVChargingBack from '../../assets/images/EVChargingBack.png';
import EVChargingDevelopment from '../../assets/images/EVChargingDevelopment.png';
import EVehicle from '../../assets/images/EVehicle.png';
import evlook from '../../assets/images/evlook.png';
import EVParking from '../../assets/images/EVParking.png';
import EVTrip from '../../assets/images/EVTrip.png';
import eScooter from '../../assets/images/eScooter.png';
import EVSmart from '../../assets/images/EVSmart.png';
import CustomSolutions from '../../assets/images/CustomSolutions.png';
import EVChargingExpertise from '../../assets/images/EVChargingExpertise.png';
import ResultDrivenApproach from '../../assets/images/ResultDrivenApproach.png';
import PostDevelopmentSupport from '../../assets/images/PostDevelopmentSupport.png';
import premiumqualityservices from '../../assets/images/premiumqualityservices.png';
import LatestTechstack from '../../assets/images/LatestTechstack.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const AutotechEv = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "Why choose BitFront for mobile app development services?",
            answer:
                "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
            open: false
        },
        {
            question: "What is the cost of mobile app development?",
            answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
            open: false
        },
        {
            question:
                "How to choose a mobile app development platform?",
            answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
            open: false
        },
        {
            question:
                "How much time does custom mobile app development take?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "What solutions are provided by your mobile app development services?",
            answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
            open: false
        },
        {
            question:
                "What is the difference between cross-platform app development and native mobile app development?",
            answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
            open: false
        },
        {
            question:
                "Will I get post-mobile app development support?",
            answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">EV Charging App</span> Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Our dedicated team of developers will create a high-quality and flexible product designed
                            to help app users locate nearby electric vehicle charging stations, initiate charging sessions
                            for their EVs, and seamlessly make payments during the session.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-4 space-y-2 text-gray-700">
                            <li>✅ Agile Development</li>
                            <li>✅ 24/7 Technical Support</li>
                            <li>✅ Dedicated EV Charging Developers</li>
                            <li>✅ Code & Data Security</li>
                            <li>✅ Deep Domain Expertise</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='EvCharging' className="w-full md:w-[586px] h-auto md:h-[384px] mt-8 md:mt-0 mx-auto" src={EvCharging} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#43b37b] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='CustomEV' className="w-[50px] h-[50px]" src={CustomEV} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Custom EV Charging App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        If you own an EV charging station, we can build a custom application to increase your visibility among users. EV charging spot finder apps
                        allow users to locate EV charging stations nearby or in any locality quickly.
                    </p>
                </div>
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b343a6] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='Charging' className="w-[50px] h-[50px]" src={Charging} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">EV Charging Billing and Payment Solutions</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Integrate multiple payment gateways to the EV Charging station app to make billing and payments super
                        convenient. Expand your business to multiple countries and collect payments in any currency.
                    </p>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b34343] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='EVBatteryManagement' className="w-[50px] h-[50px]" src={EVBatteryManagement} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">EV Battery Management System</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        While reducing potential risks, continuously monitor and manage the performance of EV batteries. By precisely
                        estimating the available battery capacity, efficiently maximize the range of electric vehicles
                    </p>
                </div>
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b3a043] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='EVChargingBack' className="w-[50px] h-[50px]" src={EVChargingBack} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">EV Charging Back Office</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We create EV charging apps that enhance EV charging service providers' back-end operations. This
                        enables management and optimization of back-office operations for utilities, automakers, and EV owners.
                    </p>
                </div>
            </div>


            <h1 className="xl:text-[35px] lg:text-[25px] md:text-[20px] text-[16px] font-semibold text-center mt-10"><span className="text-[#0652dd]">EV Charging App</span> Development Solutions</h1>
            <p className="text-[18px] font-medium text-center">Here is a list of EV Charging app solutions that we offer:</p>



            <div className="flex flex-wrap justify-center items-center gap-[5rem] mt-[40px]">
                <img alt='EV Charging Development' className="w-[382px] h-[607px]" src={EVChargingDevelopment} />
                <div>
                    <div className="mt-[40px] mb-[40px] w-full md:w-[550px] h-auto md:h-[65px] border-[#d7e5a6] border-[2px] rounded-[10px] transition duration-200 ease-in-out hover:bg-[#f0f0f0] hover:shadow-lg">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='E-Vehicle' className="w-[40px] h-[40px]" src={EVehicle} />
                            <h1 className="md:text-[25px] font-bold">E-Vehicle Ride-Sharing</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] mb-[40px] w-full md:w-[550px] h-auto md:h-[65px] border-[#afe5a6] border-[2px] rounded-[10px] transition duration-200 ease-in-out hover:bg-[#f0f0f0] hover:shadow-lg">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='E-Vehicle Rental' className="w-[40px] h-[40px]" src={evlook} />
                            <h1 className="md:text-[25px] font-bold">E-Vehicle Rental</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] mb-[40px] w-full md:w-[550px] h-auto md:h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px] transition duration-200 ease-in-out hover:bg-[#f0f0f0] hover:shadow-lg">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='EV Parking Space Locator' className="w-[40px] h-[40px]" src={EVParking} />
                            <h1 className="md:text-[25px] font-bold">EV Parking Space Locator</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] mb-[40px] w-full md:w-[550px] h-auto md:h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px] transition duration-200 ease-in-out hover:bg-[#f0f0f0] hover:shadow-lg">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='EV Trip Planner' className="w-[40px] h-[40px]" src={EVTrip} />
                            <h1 className="md:text-[25px] font-bold">EV Trip Planner</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] mb-[40px] w-full md:w-[550px] h-auto md:h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px] transition duration-200 ease-in-out hover:bg-[#f0f0f0] hover:shadow-lg">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='eScooter App Development' className="w-[40px] h-[40px]" src={eScooter} />
                            <h1 className="md:text-[25px] font-bold">eScooter App Development</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] mb-[40px] w-full md:w-[550px] h-auto md:h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px] transition duration-200 ease-in-out hover:bg-[#f0f0f0] hover:shadow-lg">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='EV Smart Charging App' className="w-[40px] h-[40px]" src={EVSmart} />
                            <h1 className="md:text-[25px] font-bold">EV Smart Charging App</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[40px]">Electric Vehicle
                <span className="text-[#0652dd]"> Charging App</span> Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 xl:w-[1100px]">
                Our EV charging developers follow a well-planned and documented app development process to deliver exceptional EV solutions to
                our clients. Here’s an overview of our EV charging app development process.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-col md:flex-row justify-center items-start gap-[2rem] mt-[30px] p-4">
                <div className="text-center md:text-left">
                    <h1 className="text-3xl md:text-4xl font-bold mb-4">
                        <span className="text-[#0652dd]">Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>

                <div className="flex flex-col md:flex-row gap-[3rem]">
                    <div className="flex flex-col gap-[2rem]">
                        {[
                            { src: CustomSolutions, alt: 'CustomSolutions', text: 'Custom Solutions' },
                            { src: EVChargingExpertise, alt: 'EVChargingExpertise', text: 'EV Charging Expertise' },
                            { src: ResultDrivenApproach, alt: 'ResultDrivenApproach', text: 'Result-Driven Approach' }
                        ].map(({ src, alt, text }) => (
                            <div key={text} className="flex items-center">
                                <img alt={alt} className="w-[40px] h-[40px] mr-2" src={src} />
                                <h1 className="text-[18px] font-medium">{text}</h1>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col gap-[2rem]">
                        {[
                            { src: PostDevelopmentSupport, alt: 'PostDevelopmentSupport', text: 'Post-Development Support' },
                            { src: premiumqualityservices, alt: 'premiumqualityservices', text: 'Premium & Quality Services' },
                            { src: LatestTechstack, alt: 'LatestTechstack', text: 'Latest Tech-stack' }
                        ].map(({ src, alt, text }) => (
                            <div key={text} className="flex items-center">
                                <img alt={alt} className="w-[40px] h-[40px] mr-2" src={src} />
                                <h1 className="text-[18px] font-medium">{text}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">Want To Create an EV Charging Station Finder App?</h1>
                <p className="text-center text-[19px] font-medium text-[#fff]">Schedule a meeting with the leading EV Charging Station Finder App development company.</p>
            </div>

            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] text-center font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AutotechEv
