import React, { useState, useEffect, useRef } from 'react';

const Photos = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  // Ref for modal container to detect clicks outside of it
  const modalRef = useRef(null);

  const photos = [
    { src: '/images2/Frame1.png', alt: 'Beautiful scene 1', name: 'Happy New Year', type: 'image' },
    { src: '/images2/Frame2.png', alt: 'Beautiful scene 2', name: 'Gandhi Jayanti', type: 'image' },
    { src: '/images2/Frame3.png', alt: 'Beautiful scene 3', name: 'Merry Christmas', type: 'image' },
    { src: '/images2/Frame4.png', alt: 'Beautiful scene 4', name: 'Happy Independence Day', type: 'image' },
    { src: '/images2/Frame5.png', alt: 'Beautiful scene 5', name: 'Happy Janmashtami', type: 'image' },
    { src: '/images2/Frame6.jpg', alt: 'Beautiful scene 6', name: 'Happy Dhanteras', type: 'image' },
    { src: '/images2/Frame7.jpg', alt: 'Beautiful scene 7', name: 'Happy Diwali', type: 'image' },
    { src: '/images2/Frame8.jpg', alt: 'Beautiful scene 8', name: 'Happy New Year', type: 'image' },
    { src: '/images2/Frame9.jpg', alt: 'Beautiful scene 9', name: 'Happy Bhai Dooj', type: 'image' },
    { src: '/images2/Frame10.jpg', alt: 'Beautiful scene 9.2', name: 'Happy New Year', type: 'image' },
    { src: '/images2/Frame11.png', alt: 'Beautiful scene 9.3', name: 'Happy Makar Sankranti', type: 'image' },
    { src: '/images2/1000046040.jpg', alt: 'Beautiful scene 10', name: 'Image 10', type: 'image' },
    { src: '/images2/1000046043.jpg', alt: 'Beautiful scene 11', name: 'Image 11', type: 'image' },
    { src: '/images2/1000046046.jpg', alt: 'Beautiful scene 12', name: 'Image 12', type: 'image' },
    { src: '/images2/1000046052.jpg', alt: 'Beautiful scene 13', name: 'Image 13', type: 'image' },
    { src: '/images2/1000046055.jpg', alt: 'Beautiful scene 14', name: 'Image 14', type: 'image' },
    { src: '/images2/1000046058.jpg', alt: 'Beautiful scene 15', name: 'Image 15', type: 'image' },
    { src: '/images2/1000046064.jpg', alt: 'Beautiful scene 16', name: 'Image 16', type: 'image' },
    // { src: '/images2/1000046067.jpg', alt: 'Beautiful scene 17', name: 'Image 17', type: 'image' },
    { src: '/images2/1000046070.jpg', alt: 'Beautiful scene 18', name: 'Image 18', type: 'image' },
    { src: '/images2/1000046073.jpg', alt: 'Beautiful scene 19', name: 'Image 19', type: 'image' },
    // { src: '/images2/1000046076.jpg', alt: 'Beautiful scene 20', name: 'Image 20', type: 'image' },
    // { src: '/images2/1000046079.jpg', alt: 'Beautiful scene 21', name: 'Image 21', type: 'image' },
    // { src: '/images2/1000046099.jpg', alt: 'Beautiful scene 22', name: 'Image 22', type: 'image' },
    // { src: '/images2/1000046102.jpg', alt: 'Beautiful scene 23', name: 'Image 23', type: 'image' },
    // { src: '/images2/1000046105.jpg', alt: 'Beautiful scene 24', name: 'Image 24', type: 'image' },
    // { src: '/images2/1000046111.jpg', alt: 'Beautiful scene 25', name: 'Image 25', type: 'image' },
    // { src: '/images2/1000046114.jpg', alt: 'Beautiful scene 26', name: 'Image 26', type: 'image' },
    // { src: '/images2/1000046121.jpg', alt: 'Beautiful scene 27', name: 'Image 27', type: 'image' },
    // { src: '/images2/1000046124.jpg', alt: 'Beautiful scene 28', name: 'Image 28', type: 'image' },
    // { src: '/images2/1000046127.jpg', alt: 'Beautiful scene 29', name: 'Image 29', type: 'image' },
    // { src: '/images2/1000046130.jpg', alt: 'Beautiful scene 30', name: 'Image 30', type: 'image' },
    // { src: '/images2/1000046133.jpg', alt: 'Beautiful scene 31', name: 'Image 31', type: 'image' },
    // { src: '/images2/1000046136.jpg', alt: 'Beautiful scene 32', name: 'Image 32', type: 'image' },
    // { src: '/images2/1000046139.jpg', alt: 'Beautiful scene 33', name: 'Image 33', type: 'image' },
    // { src: '/images2/1000046154.jpg', alt: 'Beautiful scene 34', name: 'Image 34', type: 'image' },
    // { src: '/images2/1000046157.jpg', alt: 'Beautiful scene 35', name: 'Image 35', type: 'image' },
    // { src: '/images2/1000046160.jpg', alt: 'Beautiful scene 36', name: 'Image 36', type: 'image' },
    // { src: '/images2/1000046166.jpg', alt: 'Beautiful scene 37', name: 'Image 37', type: 'image' },
    // { src: '/images2/1000046169.jpg', alt: 'Beautiful scene 38', name: 'Image 38', type: 'image' },
    // { src: '/images2/1000046172.jpg', alt: 'Beautiful scene 39', name: 'Image 39', type: 'image' },
    // { src: '/images2/1000046175.jpg', alt: 'Beautiful scene 40', name: 'Image 40', type: 'image' },
    // { src: '/images2/1000046181.jpg', alt: 'Beautiful scene 41', name: 'Image 41', type: 'image' },
    // { src: '/images2/1000046184.jpg', alt: 'Beautiful scene 42', name: 'Image 42', type: 'image' },
    // { src: '/images2/1000046187.jpg', alt: 'Beautiful scene 43', name: 'Image 43', type: 'image' },
  ];

  const openModal = (media) => {
    setSelectedPhoto(media);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPhoto(null);
  };

  // Close modal when clicking outside
  useEffect(() => {
    if (isModalOpen) {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          closeModal();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isModalOpen]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6 text-center">Media Gallery</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {photos.map((photo, index) => (
          <div key={index} className="flex flex-col items-center">
            <img
              alt={photo.alt}
              className="w-full h-auto object-cover rounded-lg shadow-md cursor-pointer"
              src={photo.src}
              onClick={() => openModal(photo)}
            />
            <p className="mt-2 text-sm text-gray-700 font-medium">{photo.name}</p>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full"
          >
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="text-lg font-bold text-gray-700 hover:text-gray-900"
              >
                &times;
              </button>
            </div>
            <img
              alt={selectedPhoto.alt}
              src={selectedPhoto.src}
              className="w-full h-auto object-contain"
            />
            <p className="mt-2 text-center text-lg font-medium text-gray-900">{selectedPhoto.name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Photos;
