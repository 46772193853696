import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import FantasySportsAppDevelopment from '../../assets/images/FantasySportsAppDevelopment.png';
import FantasySportsSolution from '../../assets/images/FantasySportsSolution.png';
import SportsLeagueManagement from '../../assets/images/SportsLeagueManagement.png';
import LiveStreamingSolution from '../../assets/images/LiveStreamingSolution.png';
import SportsEventBookingApp from '../../assets/images/SportsEventBookingApp.png';
import FantasySportsApp from '../../assets/images/FantasySportsApp.png';
import FantasyGolfAppDevelopment from '../../assets/images/FantasyGolfAppDevelopment.png';
import FantasyCricketAppDevelopment from '../../assets/images/FantasyCricketAppDevelopment.png';
import FantasyFootballAppDevelopment from '../../assets/images/FantasyFootballAppDevelopment.png';
import FantasyMotorSportsAppDevelopment from '../../assets/images/FantasyMotorSportsAppDevelopment.png';
import FantasyHorseRidingAppDevelopment from '../../assets/images/FantasyHorseRidingAppDevelopment.png';
import FantasyBaseballAppDevelopment from '../../assets/images/FantasyBaseballAppDevelopment.png';
import UserAuthentication from '../../assets/images/UserAuthentication.png';
import UserDashboard from '../../assets/images/UserDashboard.png';
import SearchListandFilterMatches from '../../assets/images/SearchListandFilterMatches.png';
import JoinTournaments from '../../assets/images/JoinTournaments.png';
import Createcontest from '../../assets/images/Createcontest.png';
import CreateMultipleTeam from '../../assets/images/CreateMultipleTeam.png';
import ReferandEarn from '../../assets/images/ReferandEarn.png';
import EasyWithdrawEarnings from '../../assets/images/EasyWithdrawEarnings.png';
import Participationhistory from '../../assets/images/Participationhistory.png';
import Winninghistory from '../../assets/images/Winninghistory.png';
import AdminLogin from '../../assets/images/AdminLogin.png';
import AdminDashboard from '../../assets/images/AdminDashboard.png';
import UsersManagement from '../../assets/images/UsersManagement.png';
import LeagueManagement from '../../assets/images/LeagueManagement.png';
import MatchManagement from '../../assets/images/MatchManagement.png';
import RevenueManagement from '../../assets/images/RevenueManagement.png';
import RewardPoints from '../../assets/images/RewardPoints.png';
import PushNotification from '../../assets/images/PushNotification.png';
import CustomerRelationshipManagement from '../../assets/images/CustomerRelationshipManagement.png';
import BankwithdrawalrequestManagement from '../../assets/images/BankwithdrawalrequestManagement.png';
import LiveScoreUpdates from '../../assets/images/LiveScoreUpdates.png';
import RealtimeAnalytics from '../../assets/images/RealtimeAnalytics.png';
import GPStracking from '../../assets/images/GPStracking.png';
import InappChat from '../../assets/images/InappChat.png';
import Referralbonus from '../../assets/images/Referralbonus.png';
import Paymentintegration from '../../assets/images/Paymentintegration.png';
import APIintegration from '../../assets/images/APIintegration.png';
import CRMintegration from '../../assets/images/CRMintegration.png';
import RiskFreeTrial from '../../assets/images/RiskFreeTrial.png';
import SignedStrictNDAs from '../../assets/images/SignedStrictNDAs.png';
import EfficientProjectManagement from '../../assets/images/EfficientProjectManagement.png';
import GuaranteedSecurityipProtection from '../../assets/images/GuaranteedSecurityipProtection.png';
import DedicatedInHouseStaff from '../../assets/images/DedicatedInHouseStaff.png';
import CosteffectiveSolutions from '../../assets/images/CosteffectiveSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';

const EsportGameApp = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What are Fantasy Sports Apps?",
            answer:
                "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
            open: false
        },
        {
            question: "What are the benefits of Fantasy Sports App Development?",
            answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
            open: false
        },
        {
            question:
                "How much does it cost to make a fantasy app?",
            answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
            open: false
        },
        {
            question:
                "What is the future of fantasy sports?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "How many fantasy apps are there in India?",
            answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
            open: false
        },
        {
            question:
                "How long does it take to develop a fantasy sports app?",
            answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
            open: false
        },
        {
            question:
                "Do BitFront provide Support and Maintenance Service?",
            answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Fantasy Sports</span> App Development</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            As a leading fantasy sports app development company, we deliver market-leading solutions with amazing features.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-4 space-y-2 text-gray-700">
                            <li>✅ Real-Time Analytics</li>
                            <li>✅ Geographical Indication</li>
                            <li>✅ Push Notifications</li>
                            <li>✅ Payment System</li>
                            <li>✅ Third-Party API Integration</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='FantasySportsAppDevelopment' className="w-full md:w-[537px] h-auto md:h-[414px] mt-8 md:mt-0 mx-auto" src={FantasySportsAppDevelopment} />
                </div>
            </div>
            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#3a9833] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='FantasySportsSolution' className="w-[40px] h-[45px]" src={FantasySportsSolution} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Fantasy Sports Solution</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Our team of sports app designers and developers excel in creating outstanding experiences for fantasy sports platforms.
                        We make the entire process of assembling and managing virtual team members, keeping scores, and granting rewards easy
                        for the gamers and fantasy game managers.
                    </p>
                </div>
                <br />
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#7d1771] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='SportsLeagueManagement' className="w-[40px] h-[40px]" src={SportsLeagueManagement} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Sports League Management</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We help leagues, sports teams, and clubs organize teams, games, and tournaments effortlessly. We are
                        known as the sports app development company having special expertise in creating tools around game schedule,
                        organizing tournaments, easy membership fees collection, and inter-player communication.
                    </p>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#663030] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='LiveStreamingSolution' className="w-[40px] h-[40px]" src={LiveStreamingSolution} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Live Streaming Solution</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        At BitFront, we specialize in creating live streaming solutions for sports events. Our team of sports app
                        development experts use the capabilities of AI, high concurrency features to create a high performing application
                        that supports live streaming and messaging on a massive scale.
                    </p>
                </div>
                <br />
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#ffde38] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='SportsEventBookingApp' className="w-[40px] h-[40px]" src={SportsEventBookingApp} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Sports Event Booking App</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We understand the thrill of watching a sports event live from the stadium. Our team of engineers
                        make the process of booking tickets easy with an application that runs seamlessly across network
                        conditions. With multi-payment modules and strong API integrations, our app provides real-time
                        seat availability status.
                    </p>
                </div>
            </div>
            <h1 className="text-[35px] font-semibold text-center mt-10"><span className="text-[#0652dd]">Fantasy Sports</span> App Solutions</h1>
            <p className="text-[18px] xl:w-[1340px] m-auto font-medium text-center">Our skilled fantasy sports app developers offer tailored solutions that blend creativity and industry expertise to match your business goals. With cutting-edge features and advanced technology,
                we create seamless user experiences. Experience greatness in fantasy sports apps with us.</p>
            <div className="flex flex-wrap justify-center items-center gap-8 lg:gap-[5rem] mt-[40px]">
                <img alt="FantasySportsApp" className="w-full max-w-[624px] h-auto lg:w-[624px] lg:h-[505px]" src={FantasySportsApp} />

                <div className="flex flex-col">
                    <div className="mt-[40px] w-full max-w-[550px] border-[#d7e5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[12px] gap-[2rem] items-center">
                            <img alt="FantasyGolfAppDevelopment" className="w-[40px] h-[40px]" src={FantasyGolfAppDevelopment} />
                            <h1 className="text-[18px] lg:text-[20px] font-medium">Fantasy Golf App Development</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] w-full max-w-[550px] border-[#afe5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[12px] gap-[2rem] items-center">
                            <img alt="FantasyCricketAppDevelopment" className="w-[40px] h-[40px]" src={FantasyCricketAppDevelopment} />
                            <h1 className="text-[18px] lg:text-[20px] font-medium">Fantasy Cricket App Development</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] w-full max-w-[550px] border-[#a6e5e3] border-[2px] rounded-[10px] ">
                        <div className="flex p-[12px] gap-[2rem] items-center">
                            <img alt="FantasyFootballAppDevelopment" className="w-[40px] h-[40px]" src={FantasyFootballAppDevelopment} />
                            <h1 className="text-[18px] lg:text-[20px] font-medium">Fantasy Football App Development</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] w-full max-w-[550px] border-[#a6b7e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[12px] gap-[2rem] items-center">
                            <img alt="FantasyMotorSportsAppDevelopment" className="w-[40px] h-[40px]" src={FantasyMotorSportsAppDevelopment} />
                            <h1 className="text-[18px] lg:text-[20px] font-medium">Fantasy MotorSports App Development</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] w-full max-w-[550px] border-[#c0a6e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[12px] gap-[2rem] items-center">
                            <img alt="FantasyHorseRidingAppDevelopment" className="w-[40px] h-[40px]" src={FantasyHorseRidingAppDevelopment} />
                            <h1 className="text-[18px] lg:text-[20px] font-medium">Fantasy Horse Riding App Development</h1>
                        </div>
                    </div>

                    <div className="mt-[40px] w-full max-w-[550px] border-[#e5a6aa] border-[2px] rounded-[10px] ">
                        <div className="flex p-[12px] gap-[2rem] items-center">
                            <img alt="FantasyBaseballAppDevelopment" className="w-[40px] h-[40px]" src={FantasyBaseballAppDevelopment} />
                            <h1 className="text-[18px] lg:text-[20px] font-medium">Fantasy Baseball App Development</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className="text-[35px] font-semibold text-center mt-10"><span className="text-[#0652dd]">Fantasy Sports</span> App Solutions</h1>
            <p className="text-[18px] text-center m-auto mb-[50px] xl:w-[1000px]">
                We offer a wide range of features for Fantasy Sports apps from simple, to advanced, to various new features that you can choose
                from to create the ultimate Fantasy Sports app.
            </p>
            <div className="flex flex-wrap justify-center ">
                <div>
                    <div className="p-[10px] flex items-center">
                        <img alt='UserAuthentication' className="w-[40px] h-[40px]" src={UserAuthentication} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">User Authentication</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='UserDashboard' className="w-[40px] h-[40px]" src={UserDashboard} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            User Dashboard</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='SearchListandFilterMatches' className="w-[40px] h-[40px]" src={SearchListandFilterMatches} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Search, List and Filter Matches</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='JoinTournaments' className="w-[40px] h-[40px]" src={JoinTournaments} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Join Tournaments</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='Createcontest' className="w[40px] h-[40px]" src={Createcontest} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Create a contest</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='CreateMultipleTeam' className="w-[40px] h-[40px]" src={CreateMultipleTeam} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Create Multiple Team</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='ReferandEarn' className="w-[40px] h-[40px]" src={ReferandEarn} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Refer and Earn</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='EasyWithdrawEarnings' className="w-[40px] h-[40px]" src={EasyWithdrawEarnings} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Easy Withdraw Earnings</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='Participationhistory' className="w-[40px] h-[40px]" src={Participationhistory} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Participation history</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='Winninghistory' className="w-[40px] h-[40px]" src={Winninghistory} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Winning history</h1>
                    </div>
                </div>
                <div className="ml-[30px]">
                    <div className="p-[10px] flex items-center">
                        <img alt='AdminLogin' className="w-[40px] h-[40px]" src={AdminLogin} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Admin Login</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='AdminDashboard' className="w-[40px] h-[40px]" src={AdminDashboard} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Admin Dashboard</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='UsersManagement' className="w-[40px] h-[40px]" src={UsersManagement} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Users Management</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='LeagueManagement' className="w-[40px] h-[40px]" src={LeagueManagement} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">League Management</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='MatchManagement' className="w-[40px] h-[40px]" src={MatchManagement} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Match Management</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='RevenueManagement' className="w-[40px] h-[40px]" src={RevenueManagement} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Revenue Management</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='RewardPoints' className="w-[40px] h-[40px]" src={RewardPoints} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Reward Points</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='PushNotification' className="w-[40px] h-[40px]" src={PushNotification} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Push Notification</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='CustomerRelationshipManagement' className="w-[40px] h-[40px]" src={CustomerRelationshipManagement} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Customer Relationship Management</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='BankwithdrawalrequestManagement' className="w-[40px] h-[40px]" src={BankwithdrawalrequestManagement} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Bank withdrawal request Management</h1>
                    </div>
                </div>
                <div className="ml-[20px]">
                    <div className="p-[10px] flex items-center">
                        <img alt='LiveScoreUpdates' className="w-[40px] h-[40px]" src={LiveScoreUpdates} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Live Score & Updates</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='RealtimeAnalytics' className="w-[40px] h-[40px]" src={RealtimeAnalytics} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Real-time Analytics</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='GPStracking' className="w-[40px] h-[40px]" src={GPStracking} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">GPS tracking</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='InappChat' className="w-[40px] h-[40px]" src={InappChat} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            In-app Chat</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='Referralbonus' className="w-[40px] h-[40px]" src={Referralbonus} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">Referral bonus</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='Paymentintegration' className="w-[40px] h-[40px]" src={Paymentintegration} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            Payment integration</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='APIintegration' className="w-[40px] h-[40px]" src={APIintegration} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            API integration</h1>
                    </div>
                    <div className="p-[10px] flex items-center">
                        <img alt='CRMintegration' className="w-[40px] h-[40px]" src={CRMintegration} />
                        <h1 className="text-[16px] font-semibold ml-[25px]">
                            CRM integration</h1>
                    </div>
                </div>
            </div>

            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[20px] text-center mt-[20px]">
                <span className="text-[#0652dd]">Fantasy Sports</span> App Development Process</h1>
            <p className="text-[17px] text-center mt-4">
                Whether it is a web app, iOS, or Android fantasy sports app development, we follow a well-planned
                development process to ensure timely delivery and quality.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>


            <h1 className="mt-10 text-center text-3xl md:text-4xl font-semibold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?
            </h1>

            <div className="flex flex-wrap justify-center items-center gap-8 lg:gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-2xl lg:text-3xl font-bold text-center lg:text-left">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-md lg:text-lg text-center lg:text-left mt-4">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="flex flex-col gap-6">
                        <div className="flex items-center gap-4">
                            <img alt="RiskFreeTrial" className="w-[40px] h-[40px]" src={RiskFreeTrial} />
                            <h1 className="text-[16px] lg:text-[18px] font-medium">Risk Free Trial</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="SignedStrictNDAs" className="w-[40px] h-[40px]" src={SignedStrictNDAs} />
                            <h1 className="text-[16px] lg:text-[18px] font-medium">Signed Strict NDAs</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="EfficientProjectManagement" className="w-[40px] h-[40px]" src={EfficientProjectManagement} />
                            <h1 className="text-[16px] lg:text-[18px] font-medium">Efficient Project Management</h1>
                        </div>
                    </div>

                    <div className="flex flex-col gap-6">
                        <div className="flex items-center gap-4">
                            <img alt="GuaranteedSecurityipProtection" className="w-[40px] h-[40px]" src={GuaranteedSecurityipProtection} />
                            <h1 className="text-[16px] lg:text-[18px] font-medium">Guaranteed Security & IP Protection</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="DedicatedInHouseStaff" className="w-[40px] h-[40px]" src={DedicatedInHouseStaff} />
                            <h1 className="text-[16px] lg:text-[18px] font-medium">Dedicated In-House Staff</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="CosteffectiveSolutions" className="w-[40px] h-[40px]" src={CosteffectiveSolutions} />
                            <h1 className="text-[16px] lg:text-[18px] font-medium">Cost-Effective Solutions</h1>
                        </div>
                    </div>
                </div>
            </div>


            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-center text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EsportGameApp
