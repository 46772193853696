import { IoMdClose } from "react-icons/io";

const Modal = ({ onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-100 backdrop-blur-md z-50">
            <div className="bg-white rounded-2xl shadow-lg max-w-3xl w-full relative overflow-hidden">
                {/* Close Button */}
                <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                    onClick={onClose}
                >
                    <IoMdClose size={24} />
                </button>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    {/* Left Side - Text Content */}
                    <div className="p-8">
                        <h2 className="text-2xl text-black font-bold">
                            Get a <span className="text-blue-600">FREE</span> Proof of Concept (POC) for AI Development!
                        </h2>
                        <p className="text-gray-600 mt-4">
                            We’re offering a free, no-obligation POC to show exactly what’s possible with tailored AI solutions:
                        </p>

                        <ul className="mt-4 space-y-2 text-gray-700">
                            <li>✔️ <strong>Experience AI's potential risk-free</strong></li>
                            <li>✔️ <strong>Customized to your business needs</strong></li>
                            <li>✔️ <strong>Expert insights and guidance</strong></li>
                        </ul>
                        <p className="mt-4 space-y-2 text-gray-700">
                            Explore how Al can streamline operations, enhance customer experiences, and drive growth.
                        </p>
                        <p className="text-blue-600 mt-4 font-medium">
                            Limited-time offer – start your AI journey today!
                        </p>

                        {/* Email Input */}
                        <div className="mt-6">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 text-black focus:ring-blue-500"
                            />
                            <button className="mt-4 w-full bg-[#2a40cc] text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition">
                                Schedule Free Consultation
                            </button>
                        </div>
                    </div>

                    {/* Right Side - Gradient + Image */}
                    <div className="bg-gradient-to-r from-white to-yellow-200 flex items-center justify-center p-4">
                        <img
                            src="https://www.umenitx.com/wp-content/uploads/2024/11/Frame-1.png"
                            alt="Dashboard"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
