import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import eLearningSoftwareDevelopment from '../../assets/images/eLearningSoftwareDevelopment.png';
import OndemandeLearningapp from '../../assets/images/OndemandeLearningapp.svg';
import Learningmanagementsystems from '../../assets/images/Learningmanagementsystems.png';
import Exampreparationapps from '../../assets/images/Exampreparationapps.png';
import Corporatetrainingapps from '../../assets/images/Corporatetrainingapps.png';
import eLearningDevelopment from '../../assets/images/eLearningDevelopment.png';
import VirtualClassroom from '../../assets/images/VirtualClassroom.png';
import CorporateTrainingSystems from '../../assets/images/CorporateTrainingSystems.png';
import GamificationSoftware from '../../assets/images/GamificationSoftware.png';
import MicrolearningPlatform from '../../assets/images/MicrolearningPlatform.png';
import LanguageLearningSoftware from '../../assets/images/LanguageLearningSoftware.png';
import AcademicSoftware from '../../assets/images/AcademicSoftware.png';
import Videobasedcommunication from '../../assets/images/Videobasedcommunication.png';
import Multiplecourseformat from '../../assets/images/Multiplecourseformat.png';
import Realtimeperformancetracking from '../../assets/images/Realtimeperformancetracking.png';
import Livesessions from '../../assets/images/Livesessions.png';
import bannerscreen from '../../assets/images/bannerscreen.png';
import Multiplepaymentmethods from '../../assets/images/Multiplepaymentmethods.png';
import Certificationsmanagement from '../../assets/images/Certificationsmanagement.png';
import Contentmanagementsystem from '../../assets/images/Contentmanagementsystem.png';
import Forumsandcommunities from '../../assets/images/Forumsandcommunities.png';
import transparency from '../../assets/images/transparency.png';
import depthexpertise from '../../assets/images/depthexpertise.png';
import OntimeDelivery from '../../assets/images/OntimeDelivery.png';
import HighCustomerSatisfaction from '../../assets/images/HighCustomerSatisfaction.png';
import AgileDevelopmentApproach from '../../assets/images/AgileDevelopmentApproach.png';
import growthcompletionrate from '../../assets/images/growthcompletionrate.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const EdtechElearning = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "Why choose BitFront for mobile app development services?",
            answer:
                "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
            open: false
        },
        {
            question: "What is the cost of mobile app development?",
            answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
            open: false
        },
        {
            question:
                "How to choose a mobile app development platform?",
            answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
            open: false
        },
        {
            question:
                "How much time does custom mobile app development take?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "What solutions are provided by your mobile app development services?",
            answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
            open: false
        },
        {
            question:
                "What is the difference between cross-platform app development and native mobile app development?",
            answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
            open: false
        },
        {
            question:
                "Will I get post-mobile app development support?",
            answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">E-Learning Software</span> Development</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            We create easy-to-use and engaging learning programs that help people all around the world
                            learn easily and affordably at a low cost.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-4 space-y-2 text-gray-700">
                            <li className="mt-2">Learning Management Systems</li>
                            <li className="mt-2">On-Demand E-Learning App</li>
                            <li className="mt-2">Application Integration With CRM</li>
                            <li className="mt-2">Language learning Apps</li>
                            <li className="mt-2">Apps for Exam Preparation</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='eLearning' className="w-full md:w-[602px] h-auto md:h-[344px] mt-8 md:mt-0 mx-auto" src={eLearningSoftwareDevelopment} />
                </div>
            </div>
            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#3a9833] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OndemandeLearningapp' className="w-[30px] h-[30px]" src={OndemandeLearningapp} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">On-demand eLearning app</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We excel in building video and audio learning platforms, offering features such as
                        class scheduling, appointment management, lecture download, and more with an immersive UI.
                        BitFront is trusted by leading eLearning organizations for building platforms for time-sensitive,
                        bite-sized courses that prioritize convenience and flexibility.
                    </p>
                </div>
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#7d1771] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Learningmanagementsystems' className="w-[30px] h-[30px]" src={Learningmanagementsystems} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Learning management systems</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We help companies and universities with online training and organizing study materials, as well
                        as keeping students engaged using our personalized learning management system development services.
                        Our team of e-learning app developers makes the learning process highly customized and enjoyable.
                    </p>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#663030] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Exampreparationapps' className="w-[30px] h-[30px]" src={Exampreparationapps} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Exam preparation apps</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We focus on creating education apps that make exams less stressful. The platforms we build are a replica of
                        the exam environment with timers, quizzes, and even virtual simulation through AR/VR features. We help students
                        get in the exam mood through interactive elements.
                    </p>
                </div>
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#ffde38] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Corporatetrainingapps' className="w-[30px] h-[30px]" src={Corporatetrainingapps} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Corporate training apps</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We develop corporate training apps that ensure smooth processes from induction to on-the-job training,
                        without any hiccups. Our office training apps are designed for seamless integration with CRM and ERP
                        solutions, making accessibility and progress tracking easier.
                    </p>
                </div>
            </div>
            <h1 className="text-[16px] md:text-[20px] lg:text-[25px] xl:text-[35px] font-semibold text-center mt-10">
                <span className="text-[#0652dd]">eLearning App</span> Development Solutions
            </h1>
            <p className="font-medium text-center max-w-3xl mx-auto mt-2">
                Explore the top next-gen eLearning software solutions that our EdTech experts deliver with 2x more effectiveness, engagement, and personalized digital learning.
            </p>

            <div className="flex flex-wrap justify-center items-center gap-8 lg:gap-[5rem] mt-[40px]">
                <img alt="eLearningDevelopment" className="w-full lg:w-[624px] h-[auto] lg:h-[490px]" src={eLearningDevelopment} />
                <div>
                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#d7e5a6] border-[2px] rounded-[10px]">
                        <div className="flex p-[15px] gap-[1.5rem] lg:gap-[2rem] items-center">
                            <img alt="VirtualClassroom" className="w-[30px] h-[30px]" src={VirtualClassroom} />
                            <h1 className="text-[20px] lg:text-[25px] font-bold">Virtual Classroom</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#afe5a6] border-[2px] rounded-[10px]">
                        <div className="flex p-[15px] gap-[1.5rem] lg:gap-[2rem] items-center">
                            <img alt="CorporateTrainingSystems" className="w-[30px] h-[30px]" src={CorporateTrainingSystems} />
                            <h1 className="text-[20px] lg:text-[25px] font-bold">Corporate Training Systems</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px]">
                        <div className="flex p-[15px] gap-[1.5rem] lg:gap-[2rem] items-center">
                            <img alt="GamificationSoftware" className="w-[30px] h-[30px]" src={GamificationSoftware} />
                            <h1 className="text-[20px] lg:text-[25px] font-bold">Gamification Software</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px]">
                        <div className="flex p-[15px] gap-[1.5rem] lg:gap-[2rem] items-center">
                            <img alt="MicrolearningPlatform" className="w-[30px] h-[30px]" src={MicrolearningPlatform} />
                            <h1 className="text-[20px] lg:text-[25px] font-bold">Microlearning Platform</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px]">
                        <div className="flex p-[15px] gap-[1.5rem] lg:gap-[2rem] items-center">
                            <img alt="LanguageLearningSoftware" className="w-[30px] h-[30px]" src={LanguageLearningSoftware} />
                            <h1 className="text-[20px] lg:text-[25px] font-bold">Language Learning Software</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px]">
                        <div className="flex p-[15px] gap-[1.5rem] lg:gap-[2rem] items-center">
                            <img alt="AcademicSoftware" className="w-[30px] h-[30px]" src={AcademicSoftware} />
                            <h1 className="text-[20px] lg:text-[25px] font-bold">Academic Software</h1>
                        </div>
                    </div>
                </div>
            </div>


            <h1 className="mt-8 text-[28px] md:text-[32px] lg:text-[38px] font-semibold text-center">
                Key Features for Your <span className="text-[#0652dd]">eLearning App</span>
            </h1>
            <p className="text-[16px] md:text-[18px] font-medium text-center max-w-3xl mx-auto mt-2 px-4">
                The features you choose for your eLearning app will determine its success in the digital space. As an e-learning app
                development company, we ensure your platform stands out and thrives.
            </p>

            <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-[5rem] mt-[40px]">
                <div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Videobasedcommunication" className="w-[30px] h-[30px]" src={Videobasedcommunication} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Video-based communication</h1>
                    </div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Multiplecourseformat" className="w-[30px] h-[30px]" src={Multiplecourseformat} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Multiple course format</h1>
                    </div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Realtimeperformancetracking" className="w-[30px] h-[30px]" src={Realtimeperformancetracking} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Real-time performance tracking</h1>
                    </div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Livesessions" className="w-[30px] h-[30px]" src={Livesessions} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Live sessions</h1>
                    </div>
                </div>

                <img alt="bannerscreen" className="w-full lg:w-[416px] h-auto lg:h-[460px]" src={bannerscreen} />

                <div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Multiplepaymentmethods" className="w-[30px] h-[30px]" src={Multiplepaymentmethods} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Multiple payment methods</h1>
                    </div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Certificationsmanagement" className="w-[30px] h-[30px]" src={Certificationsmanagement} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Certifications management</h1>
                    </div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Contentmanagementsystem" className="w-[30px] h-[30px]" src={Contentmanagementsystem} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Content management system</h1>
                    </div>
                    <div className="mt-6 gap-[1.5rem] lg:gap-[2rem] flex items-center">
                        <img alt="Forumsandcommunities" className="w-[30px] h-[30px]" src={Forumsandcommunities} />
                        <h1 className="text-[16px] lg:text-[18px] font-medium">Forums and communities</h1>
                    </div>
                </div>
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> eLearning App</span> Development Process</h1>
            <p className=" text-center mt-4">
                We integrate uniqueness and usability in every stage of your education software development services process.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center xl:text-[38px] lg:text-[28px] md:text-[19px] text-[20px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-center md:text-left leading-snug">
                        <span className="text-[#0652dd]">Hire the Team</span> That Understands How <br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-sm md:text-base lg:text-lg text-center md:text-left mt-4 leading-relaxed">
                        With a proven track record in React Native app development, <br className="hidden md:block" />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>

                <div>
                    <div className="flex flex-col lg:flex-row gap-8 lg:gap-[5rem] mt-6">
                        <div>
                            <div className="mt-6 flex items-center gap-[1.5rem] lg:gap-[2rem]">
                                <img alt="transparency" className="w-[35px] h-[35px]" src={transparency} />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">100% transparency</h1>
                            </div>
                            <div className="mt-6 flex items-center gap-[1.5rem] lg:gap-[2rem]">
                                <img alt="depthexpertise" className="w-[35px] h-[35px]" src={depthexpertise} />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">In-depth expertise</h1>
                            </div>
                            <div className="mt-6 flex items-center gap-[1.5rem] lg:gap-[2rem]">
                                <img alt="OntimeDelivery" className="w-[35px] h-[35px]" src={OntimeDelivery} />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">On-time Delivery</h1>
                            </div>
                        </div>

                        <div>
                            <div className="mt-6 flex items-center gap-[1.5rem] lg:gap-[2rem]">
                                <img alt="HighCustomerSatisfaction" className="w-[35px] h-[35px]" src={HighCustomerSatisfaction} />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">High Customer Satisfaction</h1>
                            </div>
                            <div className="mt-6 flex items-center gap-[1.5rem] lg:gap-[2rem]">
                                <img alt="AgileDevelopmentApproach" className="w-[35px] h-[35px]" src={AgileDevelopmentApproach} />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">Agile Development Approach</h1>
                            </div>
                            <div className="mt-6 flex items-center gap-[1.5rem] lg:gap-[2rem]">
                                <img alt="growthcompletionrate" className="w-[35px] h-[35px]" src={growthcompletionrate} />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">89% completion rate</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">Ready for Next-Gen eLearning?</h1>
                <p className="text-center text-[19px] font-medium text-[#fff]">Access our advanced software solutions and stay ahead in the digital learning curve.</p>
            </div>


            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px] text-center ">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EdtechElearning
