import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Footer from "../Footer";
import FAQ from "../../components/FAQ/FAQ";
import BlockchainDevelopment from '../../assets/images/BlockchainDevelopment.png';
import LeadingBlockchainDevelopment from '../../assets/images/LeadingBlockchainDevelopment.png';
import BlockchainTechnologyConsulting from '../../assets/images/BlockchainTechnologyConsulting.png';
import dAppsDevelopment from '../../assets/images/dAppsDevelopment.png';
// import NFTMarketplaceDevelopment from '../../assets/images/NFTMarketplaceDevelopment.png';
// import CryptoWallet from '../../assets/images/CryptoWallet.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const BlockchainDdevelopment = () => {


    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };

    const [faqs, setFaqs] = useState([
        {
            question: "Quality Assurance",
            answer:
                "Most prominent app development solutions are used by our team of experts providing quality and effectiveness to our clients.",
            open: false
        },
        {
            question: "Real-Time Support",
            answer: "Being professional, we offer a wide range of support to users in real-time. This can be via phone, e-mail, online chat etc.",
            open: false
        },
        {
            question: "Cost Effectiveness",
            answer: "We provide an affordable and superb quality service that sharply reflects your business and even fits your budget.",
            open: false
        },
        {
            question: "High Level Of Expertise",
            answer: "Our skilful developers create outstanding and truly mesmerizing Blockchain solutions and are responsible for ensuring a high level of Expertise.",
            open: false
        },

    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];

    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-12 p-8">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Blockchain Development</span> Company
                        </h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Elevate your business success with our custom blockchain development
                            services. As a blockchain app development company, we specialize in
                            NFTs and Web 3.0 development.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-4 space-y-2 text-gray-700">
                            <li>✅ Decentralized Structure</li>
                            <li>✅ Security & Privacy</li>
                            <li>✅ Network Distribution</li>
                            <li>✅ Visibility And Traceability</li>
                            <li>✅ Reduced Costs</li>
                        </ul>
                        <button
                            className="transition-all duration-300 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-8 h-[40px]"
                            aria-label="Talk to our expert about blockchain development"
                        >
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                    <div className="flex justify-center">
                        <img
                            alt="Illustration representing blockchain development"
                            className="w-full md:w-[559px] h-auto md:h-[480px] object-cover rounded-xl"
                            src={BlockchainDevelopment}
                        />
                    </div>
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>


            <div className="w-full max-w-7xl h-auto bg-white mx-auto p-5">
                <div className="grid xl:grid-cols-2 sm:grid-cols-1 justify-center items-center gap-8 md:gap-24 pt-6">
                    <div className="text-center md:text-left">
                        <h1 className="text-2xl md:text-3xl font-bold">
                            Leading <span className="text-[#0652dd]">Blockchain Development</span> Company in India
                        </h1>
                        <p className="text-gray-700 text-base md:text-lg pt-4 leading-relaxed">
                            Have you been running your business for a while but haven't gone online yet?
                            Want to take your business to the next level of success? Need to create a website
                            from scratch or update your current one? No matter what industry you're in, our
                            skilled team at BitFront can build a website that goes beyond your expectations
                            in terms of performance and usability.
                        </p>
                        <p className="text-gray-700 text-base md:text-lg pt-4 leading-relaxed">
                            We're a top <strong><u>web design and development company in India</u></strong>.
                            We can help define your brand and increase demand for your products or services
                            by focusing on your customers and using data. We include <strong>SEO, PPC,</strong>
                            and <strong>content marketing</strong> strategies to achieve outstanding results.
                            As your website development partner, we guide you toward the right platform selection.
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <img
                            alt="Illustration of leading blockchain development in India"
                            className="w-full md:w-[626px] h-auto md:h-[573px] object-cover"
                            src={LeadingBlockchainDevelopment}
                        />
                    </div>
                </div>
            </div>

            <div className="w-full bg-white mt-10">
                <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-16">
                    <div className="text-center lg:text-left max-w-2xl">
                        <h1 className="text-3xl md:text-4xl lg:text-[45px] font-bold">
                            Our <span className="text-[#0652dd]">Blockchain Development</span> Services
                        </h1>
                        <p className="text-gray-700 text-base md:text-lg mt-4">
                            We help startups and enterprises integrate reliable and secure decentralized blockchain solutions
                            for their business automation. Our blockchain solutions are tailored to specific business use cases
                            via feature integration, system modifications, and implementations.
                        </p>
                        <button className="w-full md:w-[250px] h-[48px] mt-5 bg-[#0652dd] text-white font-semibold rounded-full border border-blue-800 hover:bg-white hover:text-[#0652dd] transition duration-200">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <div className="flex flex-col gap-6 mt-10 lg:mt-0">
                        <div className="w-full max-w-lg bg-white shadow-md rounded-tl-[30px] rounded-br-[30px] p-5 border">
                            <div className="flex items-center gap-3">
                                <img
                                    alt="Blockchain Technology Consulting Icon"
                                    className="w-8 h-8 object-contain"
                                    src={BlockchainTechnologyConsulting}
                                />
                                <h4 className="text-lg md:text-xl font-bold">Blockchain Technology Consulting</h4>
                            </div>
                            <p className="text-gray-700 text-base mt-2">
                                Our approach to blockchain consulting begins with what, why, and how blockchain technology
                                can benefit your enterprise solution and bring trust and transparency to the system.
                            </p>
                        </div>

                        <div className="w-full max-w-lg bg-white shadow-md rounded-tl-[30px] rounded-br-[30px] p-5 border">
                            <div className="flex items-center gap-3">
                                <img
                                    alt="dApps Development Icon"
                                    className="w-8 h-8 object-contain"
                                    src={dAppsDevelopment}
                                />
                                <h4 className="text-lg md:text-xl font-bold">dApps Development</h4>
                            </div>
                            <p className="text-gray-700 text-base mt-2">
                                From planning to design and development, our blockchain developers build enterprise-grade
                                decentralized applications to help clients accelerate time to market and maximize ROI.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-12 px-4 md:px-[30px] py-8 md:py-[30px] w-full max-w-screen-xl bg-white mx-auto">
                <div className="w-full bg-[#fafafa] p-5 rounded-lg">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start font-semibold">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center text-xl">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 bg-white border border-gray-200 rounded-lg p-6 shadow-sm hover:shadow-lg transform hover:scale-105 transition-transform duration-200">
                                <p className="text-lg flex gap-2 items-center font-medium">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center text-xl">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-gray-700 text-base pt-4 leading-relaxed">
                                    "{review.reviewText}"
                                </p>
                                <p className="text-gray-500 text-sm font-medium mt-3">
                                    — {review.author}
                                </p>
                                <p className="text-green-600 text-sm font-semibold">{review.verified}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>




            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] mb-5 text-[16px] text-center mt-15 font-bold mt-9">Transforming Industries with Cutting-Edge
                <span className="text-[#0652dd]"> Blockchain Solutions</span></h1>
            <p className="text-lg text-center">
                Our cutting-edge blockchain solutions lead innovation, transforming industries for
                greater efficiency and impact.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Blockchain Application</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                We turn your ideas into scalable, reliable applications. Our blockchain developers prioritize fast testing for early product maturity.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>


            <div className="bg-blue-700 w-3/4 h-auto mx-auto text-white text-center p-5 rounded-lg mt-10">
                <h3 className="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p className="text-lg pt-4">
                    On average, website development costs can vary from $10,000 to $150,000 based on the type of
                    website, design, and web developer costs.</p>
                <div>
                    <button onClick={handleClick} className="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>



            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center gap-9 mt-11 px-4">
                {/* Left Section */}
                <div className="max-w-lg">
                    <h1 className="text-3xl font-bold text-center md:text-left">
                        <span className="text-[#0652dd]">Hire the Team</span> That Understands How
                        <span className="text-[#0652dd]"> Digital Success Works</span>
                    </h1>
                    <p className="text-lg mt-4 text-gray-700 text-center md:text-left">
                        With a proven track record in React Native app development,
                        we're the perfect fit for your project. Here's why:
                    </p>

                    {/* Feature List */}
                    <div className="mt-6 space-y-4">
                        {[
                            "Global quality standards.",
                            "Monthly/yearly hiring.",
                            "Flexible office hours depend on the timezone.",
                            "Daily/weekly/monthly reporting via email.",
                        ].map((text, index) => (
                            <div key={index} className="flex items-center gap-3 text-lg text-gray-800">
                                <span className="bg-blue-300 rounded-full w-9 h-8 flex items-center justify-center text-blue-600 p-2">
                                    ✔
                                </span>
                                <span>{text}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Right Section (FAQ) */}
                <div className="max-w-lg w-full">
                    <div className="faqs">
                        {faqs.map((faq, index) => (
                            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default BlockchainDdevelopment
