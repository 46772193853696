import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import NativeDevelopment from '../../assets/images/NativeDevelopment.png';
import iOSNativeAppDevelopment from '../../assets/images/iOSNativeAppDevelopment.png';
import AndroidNativeAppDevelopment from '../../assets/images/AndroidNativeAppDevelopment.png';
import TabletAppDevelopment from '../../assets/images/TabletAppDevelopment.png';
import SmoothPerformance from '../../assets/images/SmoothPerformance.png';
import HardwareIntegration from '../../assets/images/HardwareIntegration.png';
import EnhancedSecurity from '../../assets/images/EnhancedSecurity.png';
import IntuitiveUI from '../../assets/images/IntuitiveUI.png';
import ImprovedScalability from '../../assets/images/ImprovedScalability.png';
import OfflineFunctionality from '../../assets/images/OfflineFunctionality.png';
import ExcellentPerformance from '../../assets/images/ExcellentPerformance.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const NativeAppDevelopment = () => {


    const [faqs, setFaqs] = useState([
        {
            question: "Custom-Based Solutions",
            answer:
                "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
            open: false
        },
        {
            question: "Industrial Experts",
            answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
            open: false
        },
        {
            question: "Seamless Communication",
            answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
            open: false
        },
        {
            question: "Latest Technology",
            answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
            open: false
        },

    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Native App</span> Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Experience the unbeatable performance and reliability of native apps,
                            paired with a user-friendly interface that looks great on any screen
                            size. BitFront is a leading native app development company in India,
                            creating scalable apps for iOS and Android.
                        </p>
                        <ul className="mt-4 space-y-2 text-gray-700">
                            <li>✅ Agile team</li>
                            <li>✅ Quality service</li>
                            <li>✅ Transparent pricing</li>
                            <li>✅ Client-centric approach</li>
                            <li>✅ Dedicated and experienced team</li>
                            <li>✅ Fast and on-time project delivery</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                    <img alt='NativeDevelopment' className="w-full md:w-[540px] h-auto md:h-[553px] mt-8 md:mt-0 mx-auto" src={NativeDevelopment} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>

            <div className="w-full bg-gray-50 py-16 px-6">
                <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-16">
                    {/* Left Content */}
                    <div className="text-center lg:text-left max-w-2xl">
                        <h1 className="text-4xl md:text-5xl font-extrabold leading-snug text-gray-900">
                            <span className="text-blue-600">Native App</span> Development Services We Offer
                        </h1>
                        <p className="text-gray-700 text-lg md:text-xl mt-6 leading-relaxed">
                            We bring extensive experience to every part of native app development. Our specialty is crafting custom native applications
                            that align perfectly with your business needs and goals, ensuring your success.
                        </p>
                        <p className="text-gray-700 text-lg md:text-xl mt-4 leading-relaxed">
                            Guarantee a flawless user experience with Ailoitte's native app development services. We specialize in creating native
                            apps tailored to the specific needs of businesses of all sizes. Here’s a look at the top native mobile app development
                            services we offer.
                        </p>
                        <button className="mt-8 px-8 py-3 bg-blue-600 text-white font-semibold rounded-full shadow hover:bg-white hover:text-blue-600 hover:shadow-lg border border-blue-600 transition duration-300">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>

                    {/* Right Content - Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8">
                        {/* Card 1 */}
                        <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition duration-300">
                            <div className="flex items-center gap-4 mb-4">
                                <img alt="iOS Native" className="w-10 h-10" src={iOSNativeAppDevelopment} />
                                <h4 className="text-lg font-semibold text-gray-900">iOS Native App Development</h4>
                            </div>
                            <p className="text-gray-600 text-sm leading-relaxed">
                                Our team of iOS experts creates impactful and engaging apps for Apple devices
                                using the latest native iOS development frameworks. We use Swift for iOS native
                                apps and also use Objective-C for legacy code when needed.
                            </p>
                        </div>

                        {/* Card 2 */}
                        <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition duration-300">
                            <div className="flex items-center gap-4 mb-4">
                                <img alt="Android Native" className="w-10 h-10" src={AndroidNativeAppDevelopment} />
                                <h4 className="text-lg font-semibold text-gray-900">Android Native App Development</h4>
                            </div>
                            <p className="text-gray-600 text-sm leading-relaxed">
                                Develop an Android app that shines on Google Play and gives your business a
                                competitive edge by using the power of Android native app development. We develop
                                top Android apps using Kotlin and Java.
                            </p>
                        </div>

                        {/* Card 3 */}
                        <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition duration-300">
                            <div className="flex items-center gap-4 mb-4">
                                <img alt="Tablet App" className="w-10 h-10" src={TabletAppDevelopment} />
                                <h4 className="text-lg font-semibold text-gray-900">Tablet App Development</h4>
                            </div>
                            <p className="text-gray-600 text-sm leading-relaxed">
                                Impress your users with stunning tablet apps. Our custom solutions ensure quick
                                engagement and high conversion rates. We build tablet apps for all, from startups
                                to big businesses.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <h1 className="text-[32px] font-bold text-center mt-[50px]"><span className="text-[#0652DD]">Benefits</span> of Native App Development</h1>
            <p className="text-center text-[18px] ">
                Unlock the potential of hybrid apps. Benefit from lower costs, smooth cross-platform performance,
                and outstanding user experience with hybrid app development solutions.
            </p>
            <div className="flex flex-wrap justify-center gap-[20px]">
                <div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='SmoothPerformance' className="w-[38px] h-[38px]" src={SmoothPerformance} />
                            <div>
                                <h1 className="text-[20px] font-bold">Smooth Performance</h1>
                                <p className="text-[18px]">
                                    Both Android native development & iOS native development directly use device hardware and software, leading to faster processing and smoother user experiences.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='HardwareIntegration' className="w-[38px] h-[38px]" src={HardwareIntegration} />
                            <div>
                                <h1 className="text-[20px] font-bold">Hardware Integration</h1>
                                <p className="text-[18px]">
                                    Android native development & iOS native development offer full access to device features like GPS, camera, and sensors.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='EnhancedSecurity' className="w-[38px] h-[38px]" src={EnhancedSecurity} />
                            <div>
                                <h1 className="text-[20px] font-bold">Enhanced Security</h1>
                                <p className="text-[18px]">
                                    Native apps (both Android & iOS) excel in data protection and security. They handle sensitive data more securely, offering improved control over data and hardware integration.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='IntuitiveUI' className="w-[38px] h-[38px]" src={IntuitiveUI} />
                            <div>
                                <h1 className="text-[20px] font-bold">Intuitive UI</h1>
                                <p className="text-[18px]">
                                    Native apps follow platform design guidelines for both native android app development & native iOS app development, making them feel intuitive and natural for users.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='ImprovedScalability' className="w-[38px] h-[38px]" src={ImprovedScalability} />
                            <div>
                                <h1 className="text-[20px] font-bold">Improved Scalability</h1>
                                <p className="text-[18px]">
                                    Native mobile apps handle high traffic well, ensuring scalability and future growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='OfflineFunctionality' className="w-[38px] h-[38px]" src={OfflineFunctionality} />
                            <div>
                                <h1 className="text-[20px] font-bold">Offline Functionality</h1>
                                <p className="text-[18px]">
                                    Native apps work offline since they're installed directly on the device, a major advantage for apps needing offline access.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hover:shadow-custom transition-all duration-200 mt-5 p-[20px] w-full md:w-[440px] h-auto md:h-[240px] border-[1px] rounded-[10px] bg-[#F9F9F9]">
                        <div className="flex gap-[1rem]">
                            <img alt='ExcellentPerformance' className="w-[38px] h-[38px]" src={ExcellentPerformance} />
                            <div>
                                <h1 className="text-[20px] font-bold">Excellent Performance</h1>
                                <p className="text-[18px]">
                                    Native apps are faster and more responsive than web apps because they directly interact with native APIs and components.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[20px] md:text-[25px] lg:text-[30px] xl:text-[40px] text-center mt-5 leading-tight">
                <span className="text-[#0652dd]">Native App</span> Development Process
            </h1>

            <p className="text-[16px] md:text-[18px] text-center mt-4 leading-relaxed text-gray-700">
                As a top native mobile application development company, we focus on meeting your
                business needs and enhancing your market presence, from development to optimization.
            </p>

            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>


            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center gap-9 mt-11 ">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg mb-[20px]">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>

                    <h5 className="text-lg flex items-center gap-2 mb-[20px] ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Global quality standards.</h5>

                    <h5 className="text-lg flex items-center gap-2 mb-[20px] ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Monthly/yearly hiring.</h5>

                    <h5 className="text-lg flex items-center gap-2 mb-[20px] ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Flexible office hours depend on the timezone.</h5>

                    <h5 className="text-lg flex items-center gap-2  ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Daily/weekly/monthly reporting via email.</h5>
                </div>
                <div>

                    <div className="App">

                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NativeAppDevelopment
