import React from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
import shopifyvid from '../../assets/images/shopifyvid.mp4';
import LeadingShopifyAppDevelopment from '../../assets/images/LeadingShopifyAppDevelopment.png';
import CustomShopifyAppDevelopment from '../../assets/images/CustomShopifyAppDevelopment.png';
import ShopifyIntegrations from '../../assets/images/ShopifyIntegrations.png';
import ShopifyMigration from '../../assets/images/ShopifyMigration.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Shopifyappdevelopment = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto xl:mt-[20px] p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]"> Shopify Development</span> Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Turning your Shopify Store into a Mobile App to increase profitability for your e-commerce or D2C business with higher conversions, reduced acquisition costs, and increased delivery rates.
                        </p>
                        <ul className="mt-4 space-y-2 text-base md:text-lg text-gray-700">
                            <li>✅ Shopify Store Development</li>
                            <li>✅ Shopify Theme Development</li>
                            <li>✅ Shopify Store Migration Services</li>
                            <li>✅ Shopify App Integrations</li>
                            <li>✅ Shopify Support & Maintenance</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                    <video className="w-full md:w-[90%] h-auto z-99 object-cover" autoPlay loop muted playsInline>
                        <source src={shopifyvid} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
                </div>
            </div>

            <div className="max-w-7xl bg-white m-auto p-6 lg:p-10">
                <div className="flex flex-col lg:flex-row justify-center items-center gap-8 md:gap-16 pt-8">
                    <div className="text-center lg:text-left">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight">
                            Leading <span className="text-[#0652dd]">Shopify App Development</span> Company in India
                        </h1>
                        <p className="text-[#242424] text-base sm:text-lg pt-4 leading-relaxed">
                            BitFront stands as an expert <b><u>Shopify app development company</u></b>, proficient
                            in crafting customized solutions for <b>Shopify online store</b> development and
                            maintenance services. With a deep understanding of Shopify's mechanics,
                            we excel in crafting stunning Shopify stores, from theme selection to product
                            integration and <b>configuring payment and shipping</b> methods essential for
                            store launch. Whether designing a new store from scratch or maintaining an
                            already existing store, we deliver advanced solutions with rich features and
                            seamless functionality, helping you achieve your business goals.
                        </p>
                        <p className="text-[#242424] text-base sm:text-lg pt-4 leading-relaxed">
                            <b>Our designers excel at creating Shopify themes</b> that work well on all
                            devices and browsers. We understand every online business is unique, so we
                            craft a <b>custom Shopify store tailored for you</b>. This store will be optimized
                            for your business and will attract the right customers. Beyond website development,
                            we also specialize in building advanced features and apps for your Shopify store.
                        </p>
                    </div>
                    <img
                        alt="Leading Shopify App Development"
                        className="w-full max-w-[400px] md:max-w-[450px] h-auto"
                        src={LeadingShopifyAppDevelopment}
                    />
                </div>
            </div>

            <div className="w-full bg-white mt-10">
                <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-16 ">
                    <div className="text-center lg:text-left max-w-2xl">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Our
                            <span className="text-[#0652dd]"> Shopify App </span>Development Services</h1>
                        <p className="text-base md:text-lg mt-4">
                            With our expertise in eCommerce and latest trends, we can create apps for
                            various business needs, whether B2B, B2C, C2B, or other customer sectors,
                            at any scale. We use Shopify’s powerful themes and extensive functionality
                            to create a competitive online store for your business.
                        </p>
                        <p className="text-base md:text-lg mt-4">
                            Transforming your online store with Shopify is effortless—from setup to
                            sales. Elevate your store's performance with our tailored Shopify web
                            design services and stand out from the competition. We always involve our
                            partners in eliminating issues and finding the most effective solutions. This
                            is how we ensure the expected results for every project we work on.
                        </p>
                        <button className=" w-full mt-[20px] md:w-[210px] h-[48px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                            <b>CONTACT NOW</b>
                        </button>
                    </div>
                    <div className="flex flex-col gap-6 mt-10 lg:mt-0">
                        {[
                            {
                                imgSrc: CustomShopifyAppDevelopment,
                                title: "Custom Shopify App Development",
                                description:
                                    "Our experts can make a special Shopify app that works smoothly with your online store, making shopping easier for customers. We create a mobile shopping solution that adds more options to your store and makes shopping more personal.",
                            },
                            {
                                imgSrc: ShopifyIntegrations,
                                title: "Shopify Integrations",
                                description:
                                    "We customize Shopify integrations to improve your eCommerce store's functions like order, vendor, payment, and inventory management, boosting its efficiency.",
                            },
                            {
                                imgSrc: ShopifyMigration,
                                title: "Shopify Migration",
                                description:
                                    "Our experienced developers can smoothly move your online store to Shopify, using its powerful features and app store to create a user-friendly interface and dynamic Shopify app.",
                            },
                        ].map((card, index) => (
                            <div
                                key={index}
                                className="w-full max-w-lg bg-white rounded-2xl p-6 border shadow-lg hover:shadow-2xl transition-shadow duration-300"
                            >
                                <div className="flex items-center gap-4">
                                    <img
                                        alt={card.title}
                                        className="w-12 h-12 p-2"
                                        src={card.imgSrc}
                                    />
                                    <h4 className="text-lg md:text-xl font-bold text-gray-800">{card.title}</h4>
                                </div>
                                <p className="text-base text-gray-600 mt-3">{card.description}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Shopify App</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                Proven development methods focused on creating top-notch Shopify mobile apps with successful outcomes.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>


            <h1 className="text-3xl sm:text-4xl md:text-5xl text-center font-bold mt-9 mb-5">
                Our <span className="text-[#0652dd]">Multi-industry</span> Experience
            </h1>
            <p className="text-lg text-center">
                At BitFront, we offer Shopify mobile app development services to a wide range of businesses across the globe.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>

            <div className="max-w-7xl w-full bg-white mx-auto mt-20 p-6 lg:p-10">
                <h1 className="text-[#0652dd] text-2xl sm:text-3xl md:text-4xl font-bold text-center lg:text-left">
                    Why Choose Us?
                </h1>
                <p className="text-[#292929] text-base sm:text-lg md:text-xl mt-4 text-center lg:text-left">
                    Our certified and experienced specialists build revenue-driven and customer-centric e-commerce solutions.
                </p>
                <ul className="list-none text-base sm:text-lg mt-6 space-y-3">
                    <li className="text-[18px]">Affordable Solutions for Improved Budgeting, Risk Control, and Project Planning.</li>
                    <li className="text-[18px]">70+ Experienced e-commerce specialists for Building a Successful Product.</li>
                    <li className="text-[18px]">E-commerce Development Experience That Ensures Quality-centric Deliveries.</li>
                    <li className="text-[18px]">Custom Shopify Development Services to Meet Specific Needs.</li>
                    <li className="text-[18px]">Personalized Shopify Development to Improve Operational Efficiency and Customer Experience.</li>
                    <li className="text-[18px]">Future-proof Technology Utilized for Shopify App Development.</li>
                    <li className="text-[18px]">Agile Development Team to Meet Project Deadlines Faster.</li>
                </ul>
                <div className="flex justify-center">
                    <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                        TALK TO OUR EXPERT
                    </button>
                </div>
            </div>



            <div className="bg-blue-700 w-3/4 h-auto mx-auto text-white text-center p-5 rounded-lg mt-10">
                <h3 className="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p className="text-lg pt-4">
                    The total cost of the Shopify Mobile App Development can be as low as $15,000 or as high as $50,000 depending on the complexity, and features
                    of the store & more.</p>
                <div>
                    <button onClick={handleClick} className="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>
            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Shopifyappdevelopment
