import React from 'react'
// import navarrow from '../assets/images/navarrow.png';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from "react-router-dom";
const Contactusfooter = () => {

  const services = [
    { name: 'Custom Software Development', link: '#' },
    { name: 'Web Application Development', link: '#' },
    { name: 'Mobile Application Development', link: '#' },
    { name: 'QA & Testing', link: '#' },
    { name: 'Support & Maintenance', link: '#' },
    { name: 'Staff Augmentation', link: '#' },
    { name: 'Cloud', link: '#' },
    { name: 'DevOps', link: '#' },
    { name: 'Artificial Intelligence & Machine Learning', link: '#' }
  ];

  const technologies = [
    { name: 'ReactJS', link: '#' },
    { name: 'AngularJS', link: '#' },
    { name: 'Python', link: '#' },
    { name: 'NodeJS', link: '#' },
    { name: 'PHP', link: '#' },
    { name: '.NET', link: '#' },
    { name: 'ROR', link: '#' },
    { name: 'JAVA', link: '#' },
    { name: 'WordPress', link: '#' },
    { name: 'CodeIgniter', link: '#' },
    { name: 'Laravel', link: '#' },
    { name: 'Android', link: '#' },
    { name: 'iOS', link: '#' },
    { name: 'React Native', link: '#' },
    { name: 'Flutter', link: '#' }
  ];

  const socialMediaLinks = [
    { href: 'https://www.facebook.com/p/BitFront-Infotech-100085942965698/', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiXN9xSEe8unzPBEQOeAKXd9Q55efGHGB9BA&s', alt: 'Facebook' },
    { href: 'https://www.instagram.com/bitfront_infotech/', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png', alt: 'Instagram' },
    { href: 'https://www.threads.net/@bitfront_infotech', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Threads_%28app%29.svg/1200px-Threads_%28app%29.svg.png', alt: 'Threads' },
    { href: 'https://www.linkedin.com/company/bit-front-tech/?originalSubdomain=in', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRokEYt0yyh6uNDKL8uksVLlhZ35laKNQgZ9g&s', alt: 'LinkedIn' },
    // { href: 'https://www.youtube.com', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_4N37TIgWC_QLpspNwGddZH8DhzljeYMFnA&s', alt: 'YouTube' },
    // { href: 'https://www.whatsapp.com', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png', alt: 'WhatsApp' },
    // { href: 'https://in.pinterest.com', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJHH6aoXJfdAScGlfLHNln2tjqUwkCDbxt8Q&s', alt: 'Pinterest' },
  ];
  const location = useLocation();
  const handleClick = (e) => {
    if (location.pathname === "/") {
      e.preventDefault();
      window.location.reload();
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="container mx-auto py-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="px-3">
            <Link to="/" onClick={handleClick}>
              <img alt="Logo" className="h-[50px] w-[150px]" src="/images2/Grouplogo.svg" />
            </Link>
            <h3 className="font-medium text-lg leading-[30px] tracking-[-0.01em] text-[#192734] mt-14 mb-5">
              Want to talk about your project?
            </h3>
            <button
              onClick={() => navigate("/contact-us")}
              className="transition-all duration-200 hover:bg-white hover:text-[#0652dd] w-full md:w-[160px] h-[42px] bg-[#0652dd] border-[1px] border-[#0652dd] text-white rounded-full">
              Schedule A Call
            </button>
          </div>
          <div className="px-3">
            <h3 className="font-medium text-[18px] leading-[22px] tracking-[-.02em] text-[#192734] mb-[30px]">Services</h3>
            <div className="grid gap-1">
              {services.map(service => (
                <a
                  key={service.name}
                  href={service.link}
                  className="font-normal text-[13px] leading-[32px] tracking-[-0.01em] text-[#192734cc] cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out"
                >
                  {service.name}
                </a>
              ))}
            </div>
          </div>
          <div className="px-3">
            <h3 className="font-medium text-[18px] leading-[22px] tracking-[-.02em] text-[#192734] mb-[30px]">Technologies</h3>
            <div className="grid grid-cols-2 gap-1">
              {technologies.map(tech => (
                <a
                  key={tech.name}
                  href={tech.link}
                  className="font-normal text-[13px] leading-[32px] tracking-[-0.01em] text-[#192734cc] cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out"
                >
                  {tech.name}
                </a>
              ))}
            </div>
          </div>
          <div className="px-3">
            <h3 className="font-medium text-[18px] leading-[22px] tracking-[-.02em] text-[#192734] mb-[30px]">Connect with us</h3>
            <div className="flex gap-[1rem] items-start mb-[30px]">
              <img alt="Flag" className="h-auto w-[35px]" src="https://www.narolainfotech.com/wp-content/uploads/2022/09/Flag_of_India.svg" />
              <div>
                <p className="font-normal text-[13px] leading-[23px] tracking-[-0.01em] text-[#4C5A67] mb-2.5">
                  301, Pragati IT Park, Mota Varachha, Surat, Gujarat, 394101 - INDIA
                </p>
                <h1 className="font-semibold text-[13px] leading-[23px] tracking-[-0.01em] text-[#4C5A67] cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out">
                  info@bitfrontinfotech.com
                </h1>
              </div>
            </div>
            <div className="flex items-center justify-center gap-3">
              {socialMediaLinks.map((link, index) => (
                <a className='f-all-app duration-200' key={index} href={link.href}>
                  <img alt={link.alt} className="h-6 w-6" src={link.src} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-[#E7EAF2]"></div>
      <div className="container mx-auto py-10">
        <div className="lg:flex lg:justify-between md:justify-center items-center">
          <div>
            <h1 className="text-[24px] font-bold leading-[29px] tracking-[0.02em] uppercase text-[#192734] mb-[5px]">WE’RE HIRING!</h1>
            <h4 className="text-[20px] text-[#4C5A67] font-normal leading-[35px]">Learn more about career opportunities with BitFront Infotech</h4>
          </div>
          <h2 className="flex lg:ml-0 md:ml-[370px] items-center text-[#0084FF] gap-[5px] font-semibold text-xl underline">
            Explore Careers
            <svg width="55" height="14" viewBox="0 0 55 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 8V6H50.7692L46.5385 2L47.5962 0L55 7L47.5962 14L46.5385 12L50.7692 8H0Z" fill="#0084FF"></path>
            </svg>
          </h2>
        </div>
      </div>
      <div className="border-b border-[#E7EAF2]"></div>
      <div className="container mx-auto py-[30px]">
        <p className="text-center font-normal text-xs leading-[25px] tracking-[-0.01em] text-[#4c5a6799]">
          © 2024 All Rights Reserved - bitfrontinfotech.com
        </p>
      </div>
    </div>
  )
}

export default Contactusfooter
