import React, { useState } from 'react';
import Header from "../../components/layout/Header";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import ReactNativeApp from '../../assets/images/ReactNativeApp.png';
import TopReactNativeApp from '../../assets/images/TopReactNativeApp.png';
import ReactNativemobileappdevelopmentconsultation from '../../assets/images/ReactNativemobileappdevelopmentconsultation.png';
import ReactNativeappUIUXdesign from '../../assets/images/ReactNativeappUIUXdesign.png';
import ReactNativeapplicationdevelopment from '../../assets/images/ReactNativeapplicationdevelopment.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const ReactDevelopment = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Contact-us');
  };
  const [faqs, setFaqs] = useState([
    {
      question: "Custom-Based Solutions",
      answer:
        "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
      open: false
    },
    {
      question: "Industrial Experts",
      answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
      open: false
    },
    {
      question: "Seamless Communication",
      answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
      open: false
    },
    {
      question: "Latest Technology",
      answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
      open: false
    },

  ]);
  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const categories = [
    { alt: "Healthcare", src: hhelth, title: "Healthcare" },
    { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
    { alt: "Social networking", src: networking, title: "Social networking" },
    { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
    { alt: "Travel", src: Travel, title: "Travel" },
    { alt: "Media", src: Media, title: "Media" },
    { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
    { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
  ];
  const reviews = [
    {
      rating: 5.0,
      reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
      author: "Founder & CEO, Larrea Wealth Management",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
      author: "Co-Founder & CEO, FLOHOM",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
      author: "Founder & CEO, FilmUp",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
      author: "Founder, Level7 Legal",
      verified: "Verified Review"
    }
  ];
  return (
    <div>
      <Header />
      <ScrollButton />
      <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto xl:mt-[20px] p-5">
        <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
          <div className="text-center md:text-left">
            <h1 className="text-3xl md:text-4xl font-bold">
              <span className="text-[#0652dd]">React Native App</span> Development
            </h1>
            <p className="text-base md:text-lg lg:text-[19px] mt-4 text-gray-700">
              Accelerate your business growth with our top-tier React Native app development
              services. Our skilled team builds high-performance, cross-platform applications
              for both iPhone and Android, tailored to your business needs.
            </p>
            <ul className="text-base md:text-lg mt-5 space-y-2 text-gray-700">
              <li>✅ React Native Consulting</li>
              <li>✅ React Native Development</li>
              <li>✅ React Native Migration</li>
              <li>✅ React Native Team Augmentation</li>
            </ul>
            <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
              TALK TO OUR EXPERT
            </button>
          </div>
          <img alt="React Native App" className="w-full md:w-[455px] h-auto md:h-[457px] mt-8 md:mt-0 mx-auto" src={ReactNativeApp} />
        </div>
      </div>
      <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-auto mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
        <div className="flex items-center gap-[0.5rem]">
          <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
          <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="/images2/tirerobot.svg" />
          {/* <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" /> */}
          <img alt='30mins' className="w-[50px] h-[50px]" src="/images2/30mins.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="/images2/familylawassist.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="/images2/openhousesdirect.png" />
        </div>
      </div>
      <div className="w-full max-w-7xl h-auto bg-white mx-auto p-5">
        <div className="grid xl:grid-cols-2 sm:grid-cols-1 justify-center items-center gap-8 md:gap-[10rem">
          <div className="text-center md:text-left">
            <h1 className="text-[24px] md:text-[30px] font-bold">
              Top <span className="text-[#0652dd]">React Native App
                Development</span> Company in India
            </h1>
            <p className="text-[#242424] text-[16px] md:text-[18px] pt-[15px] leading-[26px] md:leading-[29px]">
              At BitFront, our dedicated team of seasoned <b>React Native developers</b> is
              committed to crafting robust and reliable <b>React Native applications</b>
              tailored to your needs. As the premier <b>React native</b> <span className="text-[#0652dd] font-bold">mobile app
                development company in India</span>, we pride ourselves on our ability to handle
              projects of any scale, transforming conceptual app ideas into fully functional
              and efficient React Native applications. By leveraging the versatility of React
              Native, we deliver top-quality, cross-platform applications that not only
              streamline your operations but also elevate your user engagement to new heights.
            </p>
            <p className="text-[#242424] text-[16px] md:text-[18px] pt-[15px] leading-[26px] md:leading-[29px]">
              With <b>BitFront as your trusted partner</b>, you can expect meticulous attention
              to detail, a collaborative approach to development, and a relentless pursuit
              of excellence. Let us empower your business with innovative <b>React Native
                solutions</b> that drive growth and set you apart in today's competitive landscape.
            </p>
            <p className="text-[#242424] text-[16px] md:text-[18px] pt-[15px] leading-[26px] md:leading-[29px]">
              Choose a <b>trusted React Native development company</b> for your next project.
              We deliver quality and efficiency. <b>Let's build together</b>.
            </p>
          </div>
          <img alt="Top React Native App" className="w-full md:w-[450px] h-auto md:h-[350px] mt-8 md:mt-0" src={TopReactNativeApp} />
        </div>
      </div>

      <div className="w-full bg-white mt-10">
        <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-16 ">
          <div className="text-center lg:text-left max-w-2xl">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">
              Our <span className="text-[#0652dd]">React Native App </span>
              Development Services
            </h1>
            <p className="text-base md:text-lg mt-4">
              With our experience in React Native app development, our agile developers can
              create strong and scalable apps with great user experiences for all digital
              platforms. Our React Native developers are recognized as top-notch mobile app
              experts, ensuring exceptional quality and performance in every project.
            </p>
            <p className="text-base md:text-lg mt-4">
              Being a reliable React Native app development company, we excel in creating
              React Native apps from the ground up or seamlessly integrating the framework
              into your existing applications. Our focus is on speeding up your app delivery
              process without expanding costs. We offer personalized and competitive React
              Native application development services tailored to meet your specific business
              requirements.
            </p>
            <button className=" w-full mt-[20px] md:w-[250px] h-[48px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
              <b>TALK TO OUR DEVELOPERS</b>
            </button>
          </div>
          <div className="flex flex-col gap-6 mt-10 lg:mt-0">
            {[
              {
                img: ReactNativemobileappdevelopmentconsultation,
                title: "React Native mobile app development consultation",
                desc: "Businesses of all sizes are adopting React Native for digital transformation. Our consultants closely collaborate with you to understand your goals and integrate React Native effectively, ensuring your app is market-ready from day one."
              },
              {
                img: ReactNativeappUIUXdesign,
                title: "React Native app UI/UX design",
                desc: "A seamless cross-platform app experience demands expert design. At BitFront, we leverage modern design tools and methodologies to craft compelling user interfaces that convert visitors into customers efficiently."
              },
              {
                img: ReactNativeapplicationdevelopment,
                title: "React Native application development",
                desc: "Recognized globally, our team specializes in building secure, scalable, and high-performing React Native applications for smartphones, desktops, wearables, and smart home devices."
              }
            ].map((service, index) => (
              <div key={index} className="w-full max-w-lg bg-white rounded-tl-3xl rounded-br-3xl p-5 border shadow-md">
                <div className="flex items-center gap-3">
                  <img alt={service.title} className="w-9 h-9" src={service.img} />
                  <h4 className="text-lg md:text-xl font-bold">{service.title}</h4>
                </div>
                <p className="text-base text-gray-700 mt-2 leading-relaxed">{service.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
        <div className="w-full h-auto bg-[#fafafa] p-5">
          <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
            BitFront Reviews 5.0
            <span className="text-[#e62415] flex items-center">
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
            </span>
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-7">
            {reviews.map((review, index) => (
              <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                <p className="text-lg flex gap-2 items-center">
                  {review.rating}
                  <span className="text-[#e62415] flex items-center">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </span>
                </p>
                <p className="text-base pt-4">
                  "{review.reviewText}"
                  <br /><br />
                  {review.author}
                  <br /><br />
                  {review.verified}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
        <span className="text-[#0652dd]"> React Native App Development </span>
        Process</h1>
      <p className="text-[18px] text-center mt-4">
        We're the go-to React Native app development company. Our thorough development process ensures market-ready success from the start. With up-to-date<br />
        features and room for growth, we guarantee future-proof software.
      </p>
      <div className="flex flex-wrap gap-2 items-center mb-5 justify-center mt-8">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
        </div>
      </div>
      <h1 className="text-3xl text-center xl:mt-[10px] mt-0 md:text-4xl lg:text-5xl font-bold">
        Our <span className="text-[#0652dd]">Multi-industry</span> Experience
      </h1>
      <p className="text-lg text-center">
        Our React Native experts build apps that feel just like native ones. Businesses trust us to bring their ideas to life across multiple platforms.
      </p>
      <div className="flex flex-wrap justify-center items-center gap-7">
        {categories.map((category, index) => (
          <div
            key={index}
            className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
          >
            <div className="py-10 flex justify-center">
              <img alt={category.alt} src={category.src} width="60" height="60" />
            </div>
            <h4 className="text-center">{category.title}</h4>
          </div>
        ))}
      </div>
      <h1 className="text-center text-4xl mt-12 font-bold">
        <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
      <div className="flex flex-wrap justify-center gap-9 mt-11 ">
        <div>
          <h1 className="text-3xl font-bold">
            <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
            <span className="text-[#0652dd]">Digital Success Works</span>
          </h1>
          <p className="text-lg">
            With a proven track record in React Native app development,<br />
            we're the perfect fit for your project. Here's why:
          </p>

          <h5 className="text-lg mb-[20px] flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Global quality standards.</h5>

          <h5 className="text-lg mb-[20px] flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Monthly/yearly hiring.</h5>

          <h5 className="text-lg mb-[20px] flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Flexible office hours depend on the timezone.</h5>

          <h5 className="text-lg flex items-center gap-2  ">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Daily/weekly/monthly reporting via email.</h5>
        </div>
        <div>

          <div className="App">

            <div className="faqs">
              {faqs.map((faq, index) => (
                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-700 w-3/4 h-auto mx-auto text-white text-center p-5 rounded-lg mt-10">
        <h3 className="text-center text-3xl font-bold">How much does it cost to make a Flutter App?</h3>
        <p className="text-lg">
          On average, the React Native app development cost ranges from $10,000 to $300,000, depending
          on app complexity, advanced features, and third-party integrations.</p>
        <div>
          <button onClick={handleClick} className="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[10px]"><b>Contact Us</b></button>
        </div>
      </div>

      <div className="container mx-auto px-6 py-[30px]">
        <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
        <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
        <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
          <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
          <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
            <p className="text-lg">
              Re-building Banksathi - A finance app making it easy for financial experts to make money.
            </p>
            <div className="inline-row">
              <div>
                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                  4.4
                  <p className="text-[#DE5B48] text-[10px] flex gap-1">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </p>
                </span>
                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
              </div>
              <div>
                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
              </div>
            </div>
            <h1 className="my-3">1 Million+ downloads</h1>
            <div className="flex gap-4">
              <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                <p><b>View Case Study</b></p>
              </button>
              <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
            </div>
          </div>
          <div>
            <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
            <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
              in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
              scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
          </div>
          <div className="flex items-center gap-4">
            <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
            <div>
              <p>Corey Smith </p>
              <p>CEO, Emerge</p>
            </div>
          </div>
        </div>
        <div className="justify-center flex pt-5">
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ReactDevelopment;
